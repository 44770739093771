.dkg-jobfaq-mainCon{
    margin-top: 140px;
    &.dkg-jobseeker-faq-maincon{
        .dkg-task-paymentpage12{
            padding-top: 15px;
            height: calc(100vh - 140px);
            overflow: hidden;
            .dkg-faq-candprescreen-tableCon-12 {
                &.dkg-client-faq-tableCon{
                    height: calc(100vh - 220px) !important;
                }
            }
        }
    }
    .dkg-cand-faq-question-tabCon{
        .dkg-cand-faq-question-rightCon{
            padding: 0 !important;
        }
    }
}
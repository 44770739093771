.dkg-mainprinciple-mainCon {
    margin-top: 160px;

    .dkg-mainprinciple-Row {
        .dkg-mainprinciple-Col {

            .dkg-principale-header {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: 14px;

                .dkg-principale-title {
                    font-size: 27px;
                    color: #333;
                    font-weight: 700;

                    .dkg-editIcon {
                        svg {
                            width: 20px;
                            height: 20px;
                            position: relative;
                            top: -4px;
                            left: 10px;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.9;
                            }
                        }
                    }
                }
            }

            .dkg-principale-tableCon {
                height: calc(100vh - 255px);
                overflow-y: auto;

                .dkg-principale-table {
                    tbody {
                        tr {
                            td {
                                font-size: 16px;
                                text-transform: uppercase;
                                font-weight: 700;
                                text-align: center;
                                padding: 8px 10px;
                                height: 85px;
                                line-height: 75px;
                                background-color: #f2f2f2;
                                border: 1px solid #ddd;

                                input.form-control {
                                    border: none;
                                    background-color: #f2f2f2;

                                    &::placeholder {
                                        color: #343a40;
                                    }

                                    &:focus {
                                        box-shadow: none;
                                        outline: 0;
                                    }
                                }
                                .txta {
                                    width: 100%;
                                    min-height: 100px;
                                    font-family: Arial, sans-serif;
                                    font-size: 14px;
                                    overflow: hidden;
                                    line-height: 1.4;
                                    background-color: transparent;
                                    border: none;
                                  }
                                &:nth-child(01),
                                &:nth-child(02) {
                                    width: 12%;
                                }

                                &:nth-child(03) {
                                    text-align: left;
                                }

                                &:nth-child(04) {

                                    width: 45%;
                                    vertical-align: top;

                                    input {
                                        display: flex;
                                        flex-wrap: wrap;
                                        align-items: flex-start;
                                        font-size: 13px;
                                        margin-bottom: 0;
                                        font-weight: normal;
                                        text-transform: math-auto;
                                        font-family: 'Roboto';
                                        font-weight: normal;
                                        color: #333;

                                    }

                                }

                                .dkg-uplaod-imageCon {
                                    align-items: center;
                                    display: flex;
                                    margin-bottom: 0;

                                    .d-flex {
                                        .dk-edit-userImgCon {
                                            height: 95px;
                                            position: relative;

                                            input {
                                                cursor: pointer;
                                                height: 95px;
                                                opacity: 0;
                                                position: relative;
                                                width: 100%;
                                                z-index: 9;
                                            }

                                            .dk-myprofile-userImgCon {
                                                border-radius: 50%;
                                                cursor: pointer;
                                                left: 14px;
                                                max-width: 85px;
                                                position: absolute;
                                                top: 25px;
                                                width: 100%;
                                                object-fit: scale-down;

                                                figure {
                                                    margin: 0;

                                                    .dkg-changIcon {
                                                        width: 55px;
                                                        height: 55px;
                                                        // object-fit: cover;
                                                        // object-fit: contain;
                                                        object-fit: scale-down;

                                                    }
                                                }
                                            }

                                            .dk-myprofile-editicon {
                                                background-color: #234158;
                                                border-radius: 50%;
                                                bottom: 25px;
                                                width: 27px;
                                                height: 27px;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                position: absolute;
                                                right: 15px;
                                                top: 37px;

                                                svg {
                                                    width: 14px;
                                                    height: 14px;
                                                    color: #fff;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.f {
    color: #333;
}
.dk-clientsModulemain {
    display: flex;
    align-items: center;
    margin-top: 100px;

    .title {
        font-weight: 700;
        font-size: 22px;
        text-transform: uppercase;
        color: #646060;
        text-align: center;
        display: block;
        margin-bottom: 20px;
    }

    .dk-clientsModule-container {
        width: 100%;
        max-width: 765px;
        margin: auto;
        margin-top: 60px;

        .dk-clientsModule-box {
            width: 100%;
            margin: 15px 15px;

            a {
                display: inline-block;
                width: 100%;
                min-height: 125px;
                padding: 23px 0;
                color: #fff;
                font-size: 15px;
                font-size: 17px;
                font-weight: 400;
                text-align: center;
                text-transform: capitalize;
                border-radius: 5px;

                img {
                    width: 100%;
                    max-width: 43px;
                    display: inline-block;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.dk-clientsModulemain.dk-hrDashboard-main {
    .dk-clientsModule-container {
        max-width: 765px;
    }
}
.dk-navBarwrapper {
    // background: #3e9eba;
    // padding: 35px 20px 0;
    width: 100%;
    position: fixed;
    top: 50px;
    z-index: 99;

    .dk-navBarMain.dk-adminNavbar {
        background: #5c737d;
        width: 100%;
        border-radius: 0;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 1600px;
            margin: auto;

            li {
                display: inline-block;
                width: 100%;
                max-width: 9.09%;

                // &:first-child {
                //     a {
                //         border-radius: 5px 0 0 0;
                //     }
                // }

                &:last-child {
                    a {
                        // border-radius: 0 5px 0 0;
                        border-right: 1px solid #afafaf;
                    }
                }

                a {
                    width: 100%;
                    display: flex;
                    color: hsla(0, 0%, 100%, .9);
                    font-size: 14px;
                    padding: 13px 0 !important;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    min-height: 46px;
                    max-height: 46px;
                    font-family: Verdana, sans-serif;
                    transition: all 0.5s;
                    border: 1px solid #afafaf;
                    border-right: none;
                    background: #5c737d;
                    border-bottom: none;


                    span.dk-badge {
                        background-color: #ec407a;
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        color: #fff;
                        text-align: center;
                        line-height: 18px;
                        font-size: 11px;
                        margin: 0 5px;
                    }

                    &:hover {
                        background: #eaeaea;
                        color: #333;
                        transition: all 0.5s;
                    }

                    span.dk-maninmenu-badgeCon {
                        background-color: #ec407a;
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        color: #fff;
                        text-align: center;
                        line-height: 18px;
                        font-size: 11px;
                        margin: 0 5px;

                        &.dk-today-badgeCon {
                            background-color: #2E97B6;
                        }

                        &.dk-mailbox-badgeCon {
                            background-color: #ec407a;
                        }

                        &.dk-tasks-badgeCon {
                            background-color: #D58B5D;
                        }

                        &.dk-notice-badgeCon {
                            background-color: #396A95;
                        }

                        &.dk-jobs-badgeCon {
                            background-color: #26597d;
                        }
                    }

                    i {
                        font-size: 11px;
                        display: inline-block;
                        margin: 0 2px;
                    }
                }

                a.active {
                    background: #eaeaea;
                    color: #333;
                }
                a.white-active{
                    background-color: #fff;
                    color: #333;
                }
                // &:first-child {
                //     a {

                //         &:hover,
                //         &.active {
                //             background-color: #fff;
                //         }
                //     }
                // }
            }

            li.dropdown-toggle {
                &::after {
                    display: none;
                }

                a {
                    &::after {
                        content: '';
                        border-top: .4em solid;
                        border-right: 0.4em solid transparent;
                        border-left: .4em solid transparent;
                        margin-top: 4px;
                        margin-left: 7px;
                        display: none;
                    }
                }
            }
        }
    }
}
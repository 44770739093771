.dkg-pr-addurl-modal-dialog{
    min-width: 50%;
    .modal-content{
        border-radius: 0;
        background-color: transparent;
        .dkg-addurl-header{
            background: #FECA3E;
            border-radius: 2px 2px 0 0;
            color: #333;
            padding: 8px 15px;
            border-bottom: none;
            .modal-title {
                display: block;
                font-family: Segoe UI, Arial, sans-serif;
                font-size: 1rem;
                letter-spacing: .5px;
                margin: 0;
                text-align: center;
                width: 100%;
            }
            .close {
                color: #333;
                font-weight: 400;
                line-height: 15px;
                margin: 0;
                opacity: 1;
                outline: none;
                padding: 0;
                text-shadow: none;
            }
        }
        .dkg-addurl-body{
            background-color: #fff;
            .dkg-pr-addurl-input{
                border: 1px solid #ddd;
                height: 37px;
                padding: 7px 10px;
                text-align: left;
                border-radius: 0;
            }
            .dkg-addurl-btnCon{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                div{
                    div{
                        .dkg-addurl-btn{
                            background-color: #FECA3E;
                            color: #333;
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
}
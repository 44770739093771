.dkg-pagetype-uploadModal{
    .modal-dialog{
        .modal-content{
            border-radius: 5px;
            .dkg-video-upload-Header{
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: start;
                align-items: flex-start;
                -ms-flex-pack: justify;
                justify-content: space-between;
                padding: 0.5rem 1rem;
                border-bottom: 1px solid #dee2e6;
                border-top-left-radius: 0.3rem;
                border-top-right-radius: 0.3rem;
                position: relative;
                .modal-title{
                    font-size: 1.5rem;
                }
                .close{
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 0;
                    border-top-right-radius: 0.3rem;
                    &:hover{
                        background-color: #eaeaea;
                        transition: ease-in-out all 0.5sec;
                    }
                }
            }
            .dkg-video-upload-Body{
                form{
                    .dkg-upload-frmgrp{
                        margin-bottom: 1rem;
                        label{
                            font-size: 0.95rem;
                        }
                        input{
                            border-radius: 0;
                            text-align: left;
                        }
                        .dkg-submit-BtnCon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // div{
                            //     div{
                            //         .dkg-submit-Btn{
                            //             box-shadow: none;
                            //             color: #fff;
                            //             background-color: #525a8e;
                            //             border-color: #525a8e;
                            //             margin: 0;
                            //             padding: 9px 12px;
                            //             margin-right: 0.5rem;
                            //             width: 80px;
                            //         }
                                    
                            //     }
                            // }
                            .dkg-submit-Btn{
                                box-shadow: none;
                                color: #fff;
                                background-color: #525a8e;
                                border-color: #525a8e;
                                margin: 0;
                                padding: 9px 12px;
                                margin-right: 0.5rem;
                                width: 80px;
                            }
                            .dkg-close-btn{
                                color: #fff;
                                background-color: #6c757d;
                                border-color: #6c757d;
                                margin: 0;
                                padding: 9px 12px;
                                margin-right: 0.5rem;
                                width: 80px;
                                box-shadow: none;
                            
                            }
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
.dkg-applyjob-mainCon{
    .dkg-applyjob-mainRow{
        .dkg-applyjob-leftSide{
            .dkg-applyjob-navpills{
                background-color: #244158;
                flex: 0 0 14.666667%;
                height: calc(100vh - 160px);
                .dkg-applyjob-navitem{
                    .dkg-applyjob-navlink{
                       background-color: #244158;
                        border-bottom: 1px solid #3e5b73;
                        color: #ddd;
                        display: block;
                        font-family: Roboto, sans-serif !important;
                        font-size: 15px;
                        font-weight: 400;
                        padding: 11px 15px;
                        text-transform: inherit;
                        width: 100%;
                        border-radius: 0;
                        &.active{
                            background-color: #eaeaea;
                            color: #333;
                        }
                    }
                }
            }
        }
        .dkg-applyjob-mainCon{
            .dkg-jobapply-tabcontent{
                .dkg-jobapply-tabpane{
                    height: calc(100vh - 160px);
                    background-color: #eaeaeaea;
                    padding: 7px 15px;
                    border: 1px solid #ddd;
                    border-left: none;
                    width: 100%;
                    .dkg-jobapply-header{
                        color: #333;
                        font-size: 20px;
                        font-weight: 400;
                        width: 100%;
                        text-align: center;
                    }
                    .dkg-jobapply-body{
                        .dkg-apply-jobeditor-Main{
                            max-width: 100% !important;
                            margin: inherit !important;
                            .jodit-react-container{
                                margin-top: 5px;
                                .jodit-container{
                                    height: calc(100vh - 245px) !important;
                                    width: 100% !important;
                                    max-width: inherit !important;
                                    .jodit-workplace{
                                        height: inherit !important;
                                    }
                                    .jodit-status-bar{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
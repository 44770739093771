.dk-candprescreenModal {
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: 0;
        min-width: 90% !important;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            // overflow-y: auto;
            border-radius: 0px;

            .dk-candprescreenModalHeader {
                background-color: #234158;
                border-radius: 0;
                padding: 0 15px !important;
                height: 44px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: none;
                position: relative;

                .modal-title {
                    color: #fff;
                    text-transform: uppercase;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    position: absolute;
                    left: 40%;
                }

                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: 1;
                    color: #fff;
                    font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 40px;
                    position: absolute;
                    right: 2px;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dk-candprescreenModalBody {
                background-color: #fff;
                padding: 0;
                // height: calc(100vh - 45px);
                // overflow-y: auto;

                .dkg-canpresrcreen-tab-mainCon {
                    .dkg-canpresrcreen-tabCon {
                        .dkg-canpresrcreen-tab-navpills {
                            margin-bottom: 0;
                            width: 100%;

                            .dkg-canpresrcreen-tab-nav-item {
                                max-width: 14.28%;
                                // max-width: 12.5%;
                                width: 100%;

                                .dkg-canpresrcreen-tab-navlink {
                                    background-color: #eaeaea;
                                    border-radius: 0;
                                    color: #5a5858;
                                    font-size: .85rem;
                                    padding: 0.65rem 1rem;
                                    text-align: center;
                                    text-transform: uppercase;

                                    &.active,
                                    &.show>.nav-link {
                                        background-color: #fff;
                                        color: #333;
                                    }
                                }
                            }
                        }

                        .dkg-canpresrcreen-tabcontent {
                            .dkg-canpresrcreen-tabpane {
                                background-color: #fff;
                                //height: calc(100vh - 85px);
                                padding: 15px 0 !important;

                                // padding-left: 0;
                                &.dkg-script-tabpane {
                                    padding: 7px 0 !important;
                                }

                                .container-fluid {
                                    .row {
                                        .dk-newprescrenformTableCon {
                                            overflow-x: auto;
                                            overflow-y: auto;

                                            .dk-newprescrenformTable {
                                                margin-bottom: 0;

                                                thead tr th {
                                                    border: 1px solid #ddd !important;
                                                    background-color: #3c97b6;
                                                    color: #fff;
                                                    padding: 9px 10px;
                                                    text-align: center;
                                                    vertical-align: middle;
                                                    white-space: nowrap;

                                                }

                                                tbody tr td,
                                                tfoot tr td {
                                                    background-color: #f2f2f2;
                                                    color: #333;
                                                    padding: 6px 10px;
                                                    text-align: center;
                                                    vertical-align: middle;
                                                    border: 1px solid #ddd;
                                                    white-space: nowrap;

                                                    &:nth-child(05),
                                                    &:nth-child(06),
                                                    &:nth-child(07),
                                                    &:nth-child(08),
                                                    &:nth-child(09),
                                                    &:nth-child(10),
                                                    &:nth-child(11) {
                                                        text-align: left;
                                                    }

                                                    .dkg-action-cols {
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                    }

                                                    .dk-checkinputIconCon {
                                                        align-items: center;
                                                        display: flex;
                                                        justify-content: center;

                                                        .dk-checkinputIcon {
                                                            left: 10px;
                                                            position: relative;
                                                            top: 2px;

                                                            input[type=checkbox] {
                                                                background-color: #f1f1f1;
                                                            }

                                                            input[type=checkbox]:checked {
                                                                background-color: #34976e;
                                                            }
                                                        }
                                                    }

                                                    .newactionCols {
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                    }

                                                    .dk-prescreen-recuiter-con {
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;

                                                        .dk-prescreen-recuiter-img {
                                                            width: 28px;
                                                            height: 28px;
                                                            border-radius: 50%;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .dkg-levels-tabCon {
                                    .dkg-level-row {
                                        .dkg-level-col {
                                            .dkg-level-TableCon {
                                                height: calc(100vh - 135px);
                                                overflow-x: auto;
                                                overflow-y: auto;
                                                padding-bottom: 0;
                                                position: relative;

                                                .dkg-level-Table {
                                                    margin-bottom: 0;

                                                    thead {
                                                        tr {
                                                            th {
                                                                border: 1px solid #ddd !important;
                                                                background-color: #3c97b6;
                                                                color: #fff;
                                                                padding: 9px 10px;
                                                                text-align: center;
                                                                vertical-align: middle;
                                                                white-space: nowrap;
                                                                text-transform: uppercase;
                                                                position: sticky;
                                                                top: 0;
                                                                z-index: 9;

                                                            }
                                                        }
                                                    }

                                                    tbody {
                                                        tr {
                                                            td {
                                                                background-color: #f2f2f2;
                                                                color: #333;
                                                                padding: 0;
                                                                text-align: center;
                                                                vertical-align: middle;
                                                                border: 1px solid #ddd;
                                                                white-space: nowrap;

                                                                .dkg-maintitle {
                                                                    input {
                                                                        height: 130px;
                                                                        border: none;
                                                                        border-radius: 0;
                                                                        background-color: #f2f2f2;

                                                                        &:focus {
                                                                            outline: 0;
                                                                            box-shadow: none;
                                                                        }
                                                                    }
                                                                }

                                                                .dkg-level-subtitleBlock,
                                                                .dkg-level-messageBlock {

                                                                    .dkg-level-1stsubtitleBlock,
                                                                    .dkg-level-2ndsubtitleBlock,
                                                                    .dkg-level-3rdsubtitleBlock,
                                                                    .dkg-level-1stmessageBlock,
                                                                    .dkg-level-2ndmessageBlock,
                                                                    .dkg-level-3rdmessageBlock {
                                                                        input {
                                                                            border: none;
                                                                            border-radius: 0;
                                                                            background-color: #f2f2f2;

                                                                            &:focus {
                                                                                outline: 0;
                                                                                box-shadow: none;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &:nth-child(02) {
                                                                    width: 300px;
                                                                }

                                                                &:nth-child(03) {
                                                                    padding: 0;
                                                                    width: 350px;
                                                                }

                                                                &:nth-child(04) {
                                                                    padding: 0;
                                                                    width: 620px;
                                                                }

                                                                .dkg-level-subtitleBlock {
                                                                    .dkg-level-1stsubtitleBlock {
                                                                        border-bottom: 1px solid #ddd;
                                                                        padding: 5px 9px;
                                                                    }

                                                                    .dkg-level-2ndsubtitleBlock {
                                                                        border-bottom: 1px solid #ddd;
                                                                        padding: 5px 9px;
                                                                    }

                                                                    .dkg-level-3rdsubtitleBlock {
                                                                        padding: 5px 9px;
                                                                        border-bottom: 1px solid #ddd;

                                                                        &:last-child {
                                                                            border-bottom: none;
                                                                        }
                                                                    }
                                                                }

                                                                .dkg-level-messageBlock {
                                                                    .dkg-level-1stmessageBlock {
                                                                        border-bottom: 1px solid #ddd;
                                                                        padding: 5px 9px;
                                                                    }

                                                                    .dkg-level-2ndmessageBlock {
                                                                        border-bottom: 1px solid #ddd;
                                                                        padding: 5px 9px;
                                                                    }

                                                                    .dkg-level-3rdmessageBlock {
                                                                        padding: 5px 9px;
                                                                        border-bottom: 1px solid #ddd;

                                                                        &:last-child {
                                                                            border-bottom: none;
                                                                        }
                                                                    }

                                                                }

                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .dkg-cand-faq-question-tabCon {
                                    .dkg-cand-faq-sidemenu-question {
                                        .dk-faq-addcategory-btn.dkg-faq-back-catbtn {
                                            display: inline-block;
                                            font-weight: 400;
                                            background-color: #fff;
                                            color: #212529;
                                            border: 1px solid #ddd;
                                            text-align: center;
                                            vertical-align: middle;
                                            -webkit-user-select: none;
                                            user-select: none;
                                            padding: 0.375rem 0.75rem;
                                            font-size: 1rem;
                                            line-height: 1.5;
                                            transition: all .25s ease-in-out;
                                        }

                                        .dkg-cand-faq-question-navpills {
                                            margin-top: .81rem;

                                            .dkg-cand-faq-question-navitem {
                                                width: 100%;

                                                .dkg-cand-faq-question-navlink {
                                                    border-radius: 0;
                                                    background-color: #325f7d;
                                                    color: #fff;
                                                    border-bottom: 1px solid #8a8a8a;
                                                    width: 100%;
                                                    display: inline-block;
                                                    padding: 7px 9px;

                                                    &.addDefaultClasss0,
                                                    &.activeTabClass {
                                                        background-color: #234158;
                                                        position: relative;

                                                        &:before {
                                                            content: "";
                                                            width: 0;
                                                            height: 0;
                                                            border-bottom: 10px solid transparent;
                                                            border-top: 10px solid transparent;
                                                            border-left: 12px solid #234158;
                                                            position: absolute;
                                                            top: 9px;
                                                            right: -11px;
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dk-candprescreenModal.fade .modal-dialog {
    right: -90%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dk-candprescreenModal.show .modal-dialog {
    right: 0;
}

.modal.dk-candprescreenModal {
    z-index: 9999 !important;
}
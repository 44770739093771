.dk-tabs-right-tabpane-12.addnewClass.dkg-hidewithoutselct {
    display: none;
}

.dk-tabs-right-tabpane-12.addnewClass.dkg-hidewithoutselct.activesSubTab {
    display: inherit;
}

.dkg-payment-qt-pageCon {
    .dkg-maincategory-header {
        .headerlist {
            padding-inline-start: 0;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: left;
            margin-left: 8px;
            margin-bottom: 6px;

            .headeritem {
                list-style: none;

                .dk-resCrcleIconCon {
                    align-items: center;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    display: flex;
                    height: 24px;
                    justify-content: center;
                    width: 24px;
                    cursor: pointer;

                    svg {
                        width: 11px;
                        height: 11px;
                        cursor: pointer;
                        color: #7c7171;
                    }

                    &:hover {
                        outline: 0;
                        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                    }
                }

            }
        }
    }

    .dkg-payment-qt-tab-mainCon {
        background-color: #eaeaea;
        // margin-top: 15px !important;
        border-top: 1px solid #ddd;

        .col-md-2 {
            @media only screen and (max-width: 600px) {
                &.dkg-payment-qt-tabCon {
                    flex: 0 0 13.666667%;
                    max-width: 13.666667%;

                    &.dkg-prescreen-question-sidemenu-234 {
                        flex: 0 0 16.666667%;
                        max-width: 16.666667%;
                    }
                }
            }

            @media only screen and (min-width: 600px) {
                &.dkg-payment-qt-tabCon {
                    flex: 0 0 13.666667%;
                    max-width: 13.666667%;

                    &.dkg-prescreen-question-sidemenu-234 {
                        flex: 0 0 16.666667%;
                        max-width: 16.666667%;
                    }
                }
            }

            @media only screen and (min-width: 768px) {
                &.dkg-payment-qt-tabCon {
                    flex: 0 0 15.666667%;
                    max-width: 15.666667%;



                    &.dkg-prescreen-question-sidemenu-234 {
                        flex: 0 0 16.666667%;
                        max-width: 16.666667%;
                    }
                }
            }

            .dkg-payment-qt-tab-navpills {
                margin-bottom: 0;
                width: 100%;
                background-color: #244158;
                height: 100%;
                // height: calc(100vh - 180px);

                .dkg-payment-qt-tab-nav-item {
                    .dkg-payment-qt-tab-navlink {
                        // background-color: #eaeaea;
                        // border-radius: 0;
                        // color: #333;
                        // font-size: .95rem;
                        // padding: 0.65rem 1rem;
                        // text-align: center;
                        background: #244158;
                        border-bottom: 1px solid #3e5b73;
                        color: #ddd;
                        display: block;
                        font-family: Roboto, sans-serif !important;
                        font-size: 15px;
                        font-weight: 400;
                        padding: 11px 15px;
                        text-transform: inherit;
                        width: 100%;
                        border-radius: 0;

                        &.active,
                        &.show>.nav-link {
                            background-color: #eaeaea;
                            color: #333;
                        }
                    }
                }
            }
        }

        .col-md-10 {
            @media only screen and (max-width: 600px) {
                &.dkg-payment-rt-tabCon-10 {
                    flex: 0 0 86.333333%;
                    max-width: 86.333333%;

                    &.dkg-question-rightsidecon-234 {
                        flex: 0 0 83.333333%;
                        max-width: 83.333333%;
                    }
                }
            }

            @media only screen and (min-width: 600px) {
                &.dkg-payment-rt-tabCon-10 {
                    flex: 0 0 86.333333%;
                    max-width: 86.333333%;

                    &.dkg-question-rightsidecon-234 {
                        flex: 0 0 83.333333%;
                        max-width: 83.333333%;
                    }
                }
            }

            @media only screen and (min-width: 768px) {
                &.dkg-payment-rt-tabCon-10 {
                    flex: 0 0 86.333333%;
                    max-width: 86.333333%;
                    height: calc(100vh - 127px);

                    &.dkg-question-rightsidecon-234 {
                        flex: 0 0 83.333333%;
                        max-width: 83.333333%;
                    }
                }
            }
        }

        .dkg-payment-qt-tabcontent {
            .dkg-payment-qt-tabpane {
                .dkg-payment-qt-rightpanel {
                    position: relative;

                    .dkg-subcategory-header {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        position: relative;
                        border-bottom: 1px solid #ddd;

                        .headerlist {
                            margin-bottom: 0;
                            padding-inline-start: 0;
                            display: flex;
                            align-items: center;
                            // width: 100%;
                            justify-content: flex-end;
                            margin: 0.625rem 0;
                            width: 100%;
                            // position: absolute;
                            // right: 18px;
                            // top: 10px;
                            // margin-left: 3rem;

                            .headeritem {
                                list-style: none;

                                .dk-resCrcleIconCon {
                                    align-items: center;
                                    background-color: #fff;
                                    border: 1px solid #ddd;
                                    border-radius: 50%;
                                    display: flex;
                                    height: 24px;
                                    justify-content: center;
                                    width: 24px;
                                    cursor: pointer;

                                    svg {
                                        width: 11px;
                                        height: 11px;
                                        cursor: pointer;
                                        color: #7c7171;
                                    }

                                    &:hover {
                                        outline: 0;
                                        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                    }
                                }

                            }

                            .title {
                                font-size: 15px;
                                font-weight: 500;
                                text-align: center;
                                text-transform: uppercase;
                                width: 100%;
                            }
                        }

                        .dkg-script-overview-title {
                            color: #eaeaea;
                            margin-bottom: 23px;
                        }
                    }

                    .title {
                        font-size: 15px;
                        font-weight: 500;
                        padding: 11px 0;
                        text-align: center;
                        text-transform: uppercase;
                        width: 100%;

                        &.dkg-page-title {
                            padding: inherit;
                            width: 300px;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }

                    .dk-moduleTraining-rightCols {
                        background: #fff;
                        border: 1px solid #ddd;
                        height: calc(100vh - 144px);
                        width: 100%;

                        .wrapper-class.rdw-editor-wrapper {
                            .rdw-editor-toolbar.toolbar-class {
                                margin-bottom: 0;

                                .rdw-inline-wrapper {
                                    .rdw-option-wrapper {
                                        max-width: 20px;
                                        height: 20px;

                                        &:nth-child(04),
                                        &:nth-child(05),
                                        &:nth-child(06),
                                        &:nth-child(07) {
                                            display: none;
                                        }
                                    }
                                }

                                .rdw-colorpicker-wrapper,
                                .rdw-link-wrapper {
                                    display: none;
                                }
                            }
                        }
                    }

                    .dk-tabs-rightCols-12 {

                        .dk-tabs-right-detail-12 {
                            .dk-tabs-right-tabcontent-13 {

                                .wrapper-class.rdw-editor-wrapper {
                                    .rdw-editor-toolbar.toolbar-class {
                                        margin-bottom: 0;

                                        div.rdw-image-wrapper {
                                            position: relative;

                                            .rdw-image-modal {
                                                position: absolute;
                                                left: -340px;
                                            }
                                        }
                                    }

                                    .editor-class.rdw-editor-main {
                                        background-color: #fff;
                                        height: calc(100vh - 268px);
                                        overflow-y: hidden;
                                        padding: 0 10px;
                                    }
                                }
                            }
                        }
                    }

                    // .dk-tabs-rightCols-12 {
                    //     .dk-tabs-right-detail-12 {
                    //         .dk-tabs-right-tabpane-12.addnewClass {
                    //             display: none;
                    //         }
                    //     }
                    // }

                    .dk-tabs-rightCols-12 {
                        .dk-tabs-right-detail-12 {
                            .dk-tabs-right-nav-12 {
                                position: absolute;
                                left: 0;
                                top: 10px;

                                .dk-tabs-right-navitem-12 {
                                    margin-right: 18px;

                                    .dk-tabs-right-navlink-12 {
                                        border-bottom: 3px solid transparent;
                                        box-shadow: none;
                                        color: #737373;
                                        font-family: Roboto, sans-serif !important;
                                        font-size: 16px;
                                        font-weight: 400;
                                        padding: 0 0 2px;
                                        text-transform: capitalize;

                                        &.active,
                                        &.activeSubTab {
                                            border-bottom: 3px solid #3c97b6;
                                            color: #3c97b6;
                                        }
                                    }
                                }
                            }

                            .dk-tabs-right-tabcontent-12 {
                                .dk-tabs-right-tabpane-12 {
                                    background: #fff;
                                    border: 1px solid #ddd;
                                    height: calc(100vh - 165px);
                                    width: 100%;

                                    .dk-rightDetail-panel-13 {
                                        .dk-getStartedRight-detail-13 {
                                            display: flex;

                                            .dk-tabs-right-nav-13 {
                                                width: 100%;
                                                max-width: 195px;
                                                min-width: 195px;
                                                background: #eaeaea;
                                                display: inline-block;
                                                height: calc(100vh - 168px);
                                                margin-right: 0;


                                                .dk-tabs-right-navitem-13 {
                                                    .dk-tabs-right-navlink-13 {
                                                        align-items: flex-start;
                                                        border-bottom: 1px solid #ddd;
                                                        color: #757575;
                                                        display: block;
                                                        flex-shrink: inherit;
                                                        font-size: 15px;
                                                        font-weight: 400;
                                                        max-width: 100%;
                                                        min-height: auto;
                                                        min-width: inherit !important;
                                                        opacity: 1;
                                                        outline: none;
                                                        overflow: visible;
                                                        padding: 11px 15px;
                                                        position: relative;
                                                        text-align: left;
                                                        text-transform: capitalize;
                                                        width: 100%;

                                                        &.active {
                                                            background: #fff;
                                                            color: #606060;
                                                        }
                                                    }
                                                }

                                                .dkg-script-subcategory {
                                                    align-items: flex-start;
                                                    border-bottom: 1px solid #ddd;
                                                    color: #757575;
                                                    display: block;
                                                    flex-shrink: inherit;
                                                    font-size: 15px;
                                                    font-weight: 400;
                                                    max-width: 100%;
                                                    min-height: auto;
                                                    min-width: inherit !important;
                                                    opacity: 1;
                                                    outline: none;
                                                    overflow: visible;
                                                    padding: 11px 15px;
                                                    position: relative;
                                                    text-align: left;
                                                    text-transform: capitalize;
                                                    width: 100%;

                                                    &.subcategory-active {
                                                        background: #fff;
                                                        color: #606060;
                                                    }
                                                }
                                            }

                                            .dk-tabs-right-tabcontent-13 {
                                                .dk-tabs-right-tabpane-13 {
                                                    padding: 24px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .dk-tabs-right-tabpane-12 {
                                &.addnewClass {

                                    &.activesSubTab {
                                        .dk-rightDetail-panel-13 {
                                            .dk-getStartedRight-detail-13 {
                                                display: flex;

                                                .dk-tabs-right-nav-13 {
                                                    background: #eaeaea;
                                                    display: inline-block;
                                                    height: calc(100vh - 168px);
                                                    margin-right: 0;
                                                    max-width: 195px;
                                                    min-width: 195px;
                                                    width: 100%;

                                                    .dk-tabs-right-navitem-13.nav-item {
                                                        .dk-tabs-right-navlink-13 {
                                                            align-items: flex-start;
                                                            justify-content: flex-start;
                                                            border-bottom: 1px solid #ddd;
                                                            border-left: 1px solid #ddd;
                                                            color: #757575;
                                                            display: block;
                                                            flex-shrink: inherit;
                                                            font-size: 15px;
                                                            font-weight: 400;
                                                            max-width: 100%;
                                                            min-height: auto;
                                                            min-width: inherit !important;
                                                            opacity: 1;
                                                            outline: none;
                                                            overflow: visible;
                                                            padding: 12px 9px;
                                                            position: relative;
                                                            text-align: left;
                                                            text-transform: inherit !important;
                                                            width: 100%;

                                                            &.addActiveClass {
                                                                background: #fff;
                                                                color: #606060;
                                                            }
                                                        }

                                                        // &:first-child {
                                                        //     .dk-tabs-right-navlink-13 {
                                                        //         border-top: 1px solid #ddd;
                                                        //     }
                                                        // }
                                                    }
                                                }

                                                .dk-tabs-right-tabcontent-13 {
                                                    width: 100%;

                                                    .wrapper-class.rdw-editor-wrapper {
                                                        .rdw-editor-toolbar.toolbar-class {
                                                            margin-bottom: 0;

                                                            .rdw-inline-wrapper {
                                                                .rdw-option-wrapper {
                                                                    max-width: 20px;
                                                                    height: 20px;

                                                                    &:nth-child(04),
                                                                    &:nth-child(05),
                                                                    &:nth-child(06),
                                                                    &:nth-child(07) {
                                                                        display: none;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .editor-class.rdw-editor-main {
                                                            background-color: #fff;
                                                            height: calc(100vh - 275px);
                                                            overflow-y: hidden;
                                                            padding: 5px 15px;
                                                            padding-top: 0;
                                                            position: relative;
                                                            top: -5px;
                                                            // .DraftEditor-root{
                                                            //     .DraftEditor-editorContainer{
                                                            //         .public-DraftEditor-content{
                                                            //             div{
                                                            //                 div{
                                                            //                     .public-DraftStyleDefault-block{

                                                            //                     }
                                                            //                 }
                                                            //             }
                                                            //         }
                                                            //     }
                                                            // }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }
    }
}
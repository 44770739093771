.dk-itTeamSummaryMain {
    padding: 0 20px;
    font-family: "Roboto", sans-serif !important;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    margin-top: 130px;

    .dk-itTeamSummaryCols {
        background: #f2f2f2;
        border-radius: 5px;
        border: 1px solid #ccc;
        margin-top: 30px;

        .title {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-weight: 500;
            padding: 6px 10px;
            background: #e2e2e2;
            border-radius: 5px 5px 0 0;
            font-size: 15px;

            span.dk-itTeamImg {
                display: inline-block;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 10px;

                img {
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }
            }
        }

        .dk-itTeamBox {
            min-height: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;

            .dk-itTeamBox-cols {
                margin: 0 15px;
                text-align: center;
                font-weight: 400;

                span.dk-itteamSummary-no {
                    display: inline-block;
                    margin-bottom: 3px;
                }

                span.text-blue {
                    display: block;
                    font-weight: 500;
                    color: #3c97b6;
                }

                span.text-yellow {
                    display: block;
                    font-weight: 500;
                    color: #d58b5d;
                }

                span.text-green {
                    display: block;
                    font-weight: 500;
                    color: #3a9469;
                }
            }
        }
    }
}

.dk-itteamModulemain {
    display: flex;
    align-items: center;
    margin-top: 100px;

    .title {
        font-weight: 700;
        font-size: 22px;
        text-transform: uppercase;
        color: #646060;
        text-align: center;
        display: block;
        margin-bottom: 20px;
    }

    .dk-it-teamemModule-container {
        width: 100%;
        max-width: 765px;
        margin: auto;
        margin-top: 60px;

        .dk-it-team-Module-box {
            width: 100%;
            margin: 15px 15px;

            a {
                display: inline-block;
                width: 100%;
                min-height: 125px;
                padding: 23px 0;
                color: #fff;
                font-size: 15px;
                font-size: 17px;
                font-weight: 400;
                text-align: center;
                text-transform: capitalize;
                border-radius: 5px;

                img {
                    width: 100%;
                    max-width: 43px;
                    display: inline-block;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 17px;
                }
            }
        }
    }
}
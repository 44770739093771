.dkg-loginfaq-mainCon{
    margin-top: 96px;
    .dkg-task-paymentpage12{
        padding-top: 15px;
        height: calc(100vh - 140px);
    }
    .dkg-cand-faq-question-tabCon{
        .dkg-cand-faq-question-rightCon{
            padding: 0 !important;
            .dkg-cand-faq-question-tabContent{
                .dkg-cand-faq-question-tabpane{
                    .dkg-task-paymentpage12{
                        .dkg-faq-candprescreen-tableCon-12 {
                            height: calc(100vh - 210px);
                            .dkg-faq-category-table{
                                &.dkg-faq-category-table-234{
                                    thead{
                                        tr{
                                            th{
                                                &:nth-child(04){
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                    tbody{
                                        tr{
                                            td{
                                                &:nth-child(04){
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
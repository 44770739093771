.dkg-company-video-mainCon{
    width: 100%;
    padding: 0;
    .dkg-jopspec-pageheader{
        width: 100%;
        .dkg-jopspec-page-title{
            color: #636363;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            padding: 4px 10px;
            text-transform: uppercase;
            position: relative;
            top: 8px;
        }
    }
    .dkg-company-videoCon{
        width: 100%;
        height: calc(100vh - 250px);
        overflow-y: auto;
        overflow-x: hidden;
        .dkg-company-mainRow{
          .dkg-company-video-cols{
            padding: 15px;
            .dkg-company-video-box{
                text-align: center;
                background: #f5f5f5;
                margin: 0;
                border-radius: 5px;
                padding-bottom: 1rem;
                .dkg-company-album-box-header{
                    padding: 8px 15px;
                    border-bottom: 1px solid #ccc;
                    border-radius: 5px 5px 0 0;
                    display: block;
                    text-align: center;
                    background: #ddd;
                    .dkg-album-box-header-title{
                        font-size: 1.10rem;
                        font-weight: bold;
                        color: #6c6b6b;
                        text-transform: uppercase;
                        text-align: center;
                        width: 100%;
                    }
                }
                .dkg-company-video-figure{
                    margin: 0 0 1rem ;
                    .dkg-company-video-imgCon{
                        width: 100%;
                        height: 210px;
                        border-radius: 0;
                        overflow: hidden;                 
                        .dkg-company-video-img{
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 0 0 5px 5px;       
                        }
                    }
                }
                .dkg-company-bottomCon{
                    .dkg-upload-mediabtn{
                        box-shadow: none;
                        color: #fff;
                        background-color: #6c757d;
                        border-color: #6c757d;
                        margin: 0;
                        padding: 9px 15px;
                        border-radius: 5px;
                        margin-right: 0.5rem;
                        width: 80px;
                    }
                    .dkg-edit-mediabtn{
                        box-shadow: none;
                        color: #212529;
                        background-color: #d39e00;
                        border-color: #c69500;
                        margin: 0;
                        padding: 9px 15px;
                        border-radius: 5px;
                        width: 80px;

                    }
                }
            }
          }  
        }
    }
}
.modal-dialog.dk-pipenwqrightFliterModal {
    position: fixed;
    top: 0;
    transform: inherit !important;
    right: 0;
    margin: 0 !important;
    min-width: 520px;
    max-width: 520px;
    height: 100vh;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    z-index: 9999;

    .modal-content {
        border-radius: 0;
        border: none;
        height: 100vh;

        .modal-header.dkmb-Fliterheader {
            padding: 8px 0;
            text-align: center;
            background: #2e97b6;
            color: #fff;
            border-bottom: none;
            width: 100%;
            position: relative;

            .modal-title.dkmb-FliterTitle {
                margin: 0;
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
            }

            .close {
                padding: 0;
                margin: 0;
                position: absolute;
                right: 3px;
                top: 5px;
                width: 30px;
                height: 30px;
                text-shadow: none;
                opacity: 1;
                color: #fff;
                opacity: 0.9;

                &:hover {
                    background-color: #eaeaea;
                    color: #333;
                }
            }
        }

        .modal-body.dkmb-Fliterbody.dkg-pipelinesRightFilterBody-13 {
            padding: 0;
            background-color: #234158;
            height: calc(100vh - 235px);
            overflow-y: auto;

            .dk-leftSideMenu.dkg-right-sideFilter-13 {
                background-color: #eaeaea;
                min-width: 170px;
                max-width: 170px;
                height: calc(100vh - 40px);
                overflow-y: auto;
                .nav-item {
                    background-color: #eaeaea;
                    border-bottom: 1px solid #ddd;
                    max-width: 170px;

                    .nav-link {
                        border-radius: 0;
                        color: #333;
                        text-transform: uppercase;
                        font-size: 0.95rem;
                        padding: 10px 12px;

                        i {
                            display: inline-block;
                            width: 22px;
                            text-align: left;
                            font-size: 14px;
                        }

                        svg {
                            display: inline-block;
                            font-size: 15px;
                            margin-right: 0.45rem;
                        }
                    }

                    .nav-link.active,
                    & .show>.nav-link {
                        color: #ddd;
                        background-color: #234158 !important;
                    }
                }
            }

            .tab-content {
                .tab-pane {
                    .dk-rightMainContent {
                        padding: 1.25rem;

                        .dk-Recuriter {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            ul {
                                list-style: none;
                                margin-bottom: 0;

                                li {
                                    margin-top: 15px;
                                    margin-bottom: 10px;

                                    a {
                                        img {
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 50%;
                                        }

                                        span.recuriterNo {
                                            color: #ff9800;
                                            margin-left: 8px !important;
                                            margin-left: 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal {
    z-index: 9999 !important;
}
.modal.fade .dk-pipenwqrightFliterModal.modal-dialog {
    right: -520px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.show .dk-pipenwqrightFliterModal.modal-dialog {
    right: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
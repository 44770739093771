.dk-adminSubmenuMain {
    width: 100%;
    position: fixed;
    top: 96px;
    left: 0;
    display: block;
    border: none;
    margin: 0;
    z-index: 9;

    ul {
        list-style: none;
        max-width: 1600px;
        margin: 0 auto;
        text-align: center;
        padding: 0;
        background: #fff;
        border-radius: 0 0 5px 5px;
        display: flex;

        li {
            max-width: 9.09% !important;
            display: flex;
            width: 100%;
            border-left: 1px solid #ccc;

            &:first-child {
                border-left: none;
            }

            a {
                color: #333;
                padding: 11px 5px;
                width: 100%;
                text-decoration: none;
                font-size: 14px;
                height: 44px;

                &:hover {
                    background: #fff;
                    transition: all 0.5s;
                }

                span.dk-notiBadge {
                    align-items: center;
                    border-radius: 3px;
                    color: #2c71b2;
                    display: inline-flex;
                    font-size: 14px;
                    font-weight: 400;
                    justify-content: center;
                    margin: 0;
                }
            }

            a.sub-active {
                background: #eaeaea;
            }
            a.grey-active{
                background-color: #eaeaea;
            }
        }
        &.dkg-jobspec-submenu-ul{
            background-color: #eaeaea !important;
            li{
                a{
                    &.sub-active{
                        background-color: #fff !important;
                    }
                }
            }
        }
    }
    &.dkg-admin-home-submenuMain{
        background-color: #eaeaea;
        ul{
            background-color: #eaeaea;
            li{
                a{
                    &.white-active{
                        background-color: #fff;
                    }
                }
            }
        }
    }
}
.dkg-sm-mainCon{
    margin-top: 140px;
    height: calc(100vh - 140px);
    background-color: #eaeaea;
    width: 100%;
    padding: 15px;
    overflow-y: auto;
    .dkg-sm-Con{
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        .dkg-pagetype-mainRow{
          .dkg-pagetype-mainCol{
            padding: 15px;
            .dkg-social-box-cols{
                padding: 0;
                height: 100%;
                .dkg-social-media-box{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    border: 1px solid #ddd;
                    background-color: #fff;
                    margin-bottom: 1rem;
                    height: 100%;
                     max-height: 19%;
                    .dkg-socail-media-icon{
                        width: 120px;
                        height: 85px;
                        border-right: 1px solid #ddd;
                        .dkg-sm-imgcon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            width: 100%;
                            .dkg-sm-img{
                                width: 55px;
                                height: 55px;
                            }
                        }
                    }
                    .dkg-send-urlbtn-con{
                        width: 140px;
                        height: 85px;
                        border-left: 1px solid #ddd;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        div{
                            div{
                                .dkg-send-urlbtn{
                                    color: #fff;
                                    background-color: #1f394c;
                                    padding: 7px 10px;
                                    border-radius: 5px;
                                    font-size: 14px;
                                    width: 105px;
                                    &:hover{
                                        background-color: #3C97B6;
                                    }
                                
                                } 
                            }
                        }
                    }
                    .dkg-sm-nameurl-con{
                        width: 100%;
                        height: 85px;
                        .dkg-timeflex{
                            width: 100%;
                            .dkg-social-media-name{
                                display: block;
                                height: 85px;
                                line-height: 85px;
                                font-size: 13px;
                                text-align: center;
                                width: 100%;
                                border-radius: 0;
                                .dkg-social-name-iput{
                                    height: 85px;
                                    border: none;
                                }
                                
                            }
                            .dkg-social-media-url{
                                display: block;
                                height: 42px;
                                line-height: 42px;
                                font-size: 13px;
                                text-align: center;
                                width: 100%;
                                border-radius: 0;
                                .dkg-social-name-url{
                                    height: 40px;
                                    border: none;
                                    
                                }
                            }
                        }
                    }
                    // &:first-child{
                    //     margin-top: 1.75rem;
                    // }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
          }
        }
    }
}
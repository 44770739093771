.dkg-faq-questionModal {
    .modal-dialog {
        min-width: 60%;
        max-width: 60%;

        .modal-content {
            border-radius: 5px;

            .dkg-faq-questionModalHeader {
                align-items: center;
                background-color: #396a95;
                border-bottom: none;
                border-radius: 5px 5px 0 0;
                display: flex;
                justify-content: space-between;
                padding: 7px 9px;
                width: 100%;

                .modal-title {
                    color: #fff;
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    margin: 0;
                    opacity: .9;
                    padding-top: 1px;
                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                }

                .close {
                    align-items: center;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    opacity: .9;
                    padding: 0;
                    position: absolute;
                    right: 2px;
                    text-shadow: none;
                    top: 9px;
                    width: 30px;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dkg-faq-questionModalBody {
                padding: 0;
                background-color: #eaeaea;
                border-radius: 0 0 5px 5px;
                text-align: center;
                width: 100%;

                .dkg-faq-viewtextArea {
                    border: none;
                    box-shadow: none;
                    outline: 0;
                    text-align: left;
                    background-color: initial;
                    padding: 15px;
                    caret-color: #eaeaea;
                    height: calc(100vh - 540px);
                    overflow-y: auto;

                    &:focus {
                        box-shadow: none;
                        outline: 0;
                    }
                }
            }
        }
    }
}
.dk-visitWebsiteMain {
    // padding: 15px;
    // background: #26596d;
    // width: 100%;
    // height: 100vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    background: url('../../../../assets//images/login_bg.jpg') no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .dkg-visitwebaite-cardCon {
        width: 100%;
        margin-top: 50px;

        .dk-visitWebsite-cols {
            width: 100%;
            max-width: 1100px;
            margin: auto;
            display: flex;
            justify-content: center;
            margin: 30px auto;

            &.dkg-top-level-boxCon {
                max-width: 366px;
                margin-bottom: 30px;
            }

            .dk-visitWebsite-box {
                display: inline-block;
                margin: 0 15px;
                width: 100%;

                a {
                    padding: 30px 20px;
                    background: #fefefe;
                    border-radius: 8px;
                    min-height: 145px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .dk-visitLogo {
                        text-align: center;
                        display: block;

                        img {
                            width: 100%;
                            max-width: 150px;
                            margin: auto;
                            transform: scale(1);
                            transition: all 0.5s;
                            object-fit: cover;
                        }

                        .dkg-adminboard-title {
                            color: #333;
                            transition: all 0.5s;
                            font-weight: 700;
                            font-size: 20px;
                        }
                    }

                    &:hover {
                        img {
                            transform: scale(1.1);
                            transition: all 0.5s;
                        }
                    }

                    .title {
                        font-size: 14px;
                        text-transform: uppercase;
                        color: #26596d;
                        font-weight: 600;
                        text-align: center;
                        display: block;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
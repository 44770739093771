.dkg-getstarted-mainCon {
    margin-top: 180px;

    .dkg-getstarted-mainRow {
        .dkg-getstarted-mainCol {
            .dkg-getstarted-tableCon {
                .dkg-getstarted-table {
                    thead {
                        tr {
                            th {
                                background: #485569;
                                border: 1px solid gray;
                                border-bottom: none;
                                color: #fff;
                                font-size: 13px;
                                padding: 9px 7px;
                                position: -webkit-sticky;
                                position: sticky;
                                text-align: center;
                                top: 0;
                                vertical-align: middle;
                                white-space: nowrap;
                                z-index: 9;

                                &:nth-child(04) {
                                    width: 50%;
                                }

                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                background-color: #eaeaea;
                                border: 1px solid #ddd;
                                color: #4d4a4a;
                                font-size: 13px;
                                font-weight: 400;
                                padding: 0 !important;
                                text-align: center;
                                text-transform: capitalize;
                                vertical-align: middle;
                                white-space: nowrap;

                                input.form-control.getstarted-formcontrol {
                                    border: none;
                                    outline: 0;
                                    box-shadow: none;
                                    background-color: #eaeaea;
                                }

                                &:nth-child(04) {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
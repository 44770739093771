.dkg-clientlist-pageCon{
    margin-top: 140px;
    height: calc(100vh - 140px);
    background-color: #fff;
    .dkg-clientlist-pageHeader{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
        padding-right: 10px;
        .dkg-client-header-leftCon{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .headerlist {
                padding-inline-start: 0;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: left;
                margin-left: 8px;
                margin-bottom: 0;
                max-width: 240px;
                .headeritem {
                    list-style: none;
    
                    .dk-resCrcleIconCon {
                        align-items: center;
                        background-color: #fff;
                        border: 1px solid #ddd;
                        border-radius: 50%;
                        display: flex;
                        height: 24px;
                        justify-content: center;
                        width: 24px;
                        cursor: pointer;
    
                        svg {
                            width: 11px;
                            height: 11px;
                            cursor: pointer;
                            color: #7c7171;
                        }
    
                        &:hover {
                            outline: 0;
                            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                        }
                    }
    
                }
            }
            .dkg-addcompany-icon{
                align-items: center;
                background-color: #fff;
                border: none;
                display: inline-block;
                cursor: pointer;
                font-size: 13px;
                padding: 7px 9px;
                text-transform: capitalize;
                color: #629bd3;
                font-weight: 700;
                white-space: nowrap;
                svg{
                    width: 17px;
                    height: 17px;
                    cursor: pointer;
                    color: #7c7171;
                    display: none;
                }
                &:hover{
                    color: #333;
                }
            }
        }
        .dkg-client-header-centerCon{
            display: flex;
            justify-content: center;
            align-items: center;
            .dkg-cl-DropdownCon{
                .dkg-cl-Dropdown{
                    margin: 0;
                    .dkg-cl-Dropdown-toggle{
                        background-color: transparent;
                        border: none;
                        box-shadow: none;
                        font-size: 1rem;
                        margin: 0;
                        min-width: 110px;
                        text-transform: capitalize;
                        min-width: 240px;
                        position: relative;
                        color: #2e97b6;
                        border: none !important;
                        padding: 0;
                        margin: 0;
                        &:after{
                            position: absolute;
                            right: 40px;
                            top: 11px;
                            color: #2e97b6;
                            margin-left: 0.355em;
                            vertical-align: 0.355em;
                            content: "";
                            border-top: 0.3em solid;
                            border-right: 0.3em solid transparent;
                            border-bottom: 0;
                            border-left: 0.3em solid transparent;
                        }
                    }
                    .dkg-cl-Dropdown-Menu{
                        border-radius: 0;
                        min-width: 180px;
                        padding: 0;
                        height: calc(100vh - 440px);
                        overflow-y: auto;
                        .dkg-cl-Dropdown-Item{
                            border-bottom: 1px solid #ddd;
                            font-size: 13px;
                            padding: 7px 10px;
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
        .dkg-client-header-rightCon{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dkg-headerlist{
                display: flex;
                align-items: center;
                margin-bottom: 0;
                list-style: none;
                li{
                    list-style: none;
                    .dk-resCrcleIconCon {
                        align-items: center;
                        background-color: #fff;
                        border: 1px solid #ddd;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                        svg {
                            width: 14px;
                            height: 14px;
                            cursor: pointer;
                            color: #7c7171;
                        }
                    }
                }
            }
        }
    }
    .dkg-clientlist-mainsec-Con{
        margin-top: 6px;
        width: 100%;
        padding: 0;
        background-color: #fff;
        height: calc(100vh - 188px);
        .dkg-clientlist-main-ulCon{
            .dkg-clientlist-main-ul-tabsCon{
                .dkg-clientlist-main-ul-navpills{
                    list-style: none;
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding-inline-start: 0;
                    background-color: #eaeaea;
                    padding: 0;
                    border-top: 1px solid #ddd;
                    background-color: #fff;
                    .dkg-clientlist-main-navitem-2{
                        width: 100%;
                        max-width: 8.333%;
                        background-color: #fff;
                        .dkg-clientlist-main-navlink-2{
                            color: #333;
                            padding: 11px 5px;
                            width: 100%;
                            text-decoration: none;
                            font-size: 14px;
                            display: inline-block;
                            text-align: center;
                            &.active{
                                position: relative;
                                background-color: #eaeaea;
                                border-radius: 0;
                                // &::before {
                                //     content: "";
                                //     color: #244158;
                                //     width: 100%;
                                //     left: 5px;
                                //     bottom: 2px;
                                //     position: absolute;
                                //     border: 2px solid #2e97b6;
                                //     width: 80%;
                                //     left: 10px;
    
                                // }
                            }
                        }
                    }
                }
                .dkg-cl-info-main-tabContent{
                    .dkg-cl-info-main-tabpane{
                            background-color: #eaeaea;
                            height: calc(100vh - 223px);
                            .dkg-company-list-mainCon{
                                width: 100%;
                                max-width: 50%;
                                padding: 0 15px;
                                .dkg-company-list-tableCon{
                                    margin-top: 6px;
                                    overflow-y: auto;
                                    overflow-x: auto;
                                    height: calc(100vh - 280px);
                                    padding-bottom: 0;
                                    position: relative;
                                    top: 30px;
                                    .dkg-company-list-table{
                                        thead{
                                            tr{
                                                th{
                                                    background: #485569;
                                                    color: #fff;
                                                    font-size: 13px;
                                                    text-align: center;
                                                    white-space: nowrap;
                                                    padding: 9px 7px;
                                                    border: 1px solid #808080;
                                                    border-bottom: none;
                                                    vertical-align: middle;
                                                    position: sticky;
                                                    top: 0;
                                                    z-index: 9;
                                                
                                                }
                                            }
                                        }
                                        tbody{
                                            tr{
                                                td{
                                                    background: #fff;
                                                    color: #4d4a4a;
                                                    font-size: 13px;
                                                    font-weight: 400;
                                                    text-align: center;
                                                    white-space: nowrap;
                                                    padding: 5px 7px;
                                                    border: 1px solid #ddd;
                                                    vertical-align: middle;
                                                    text-transform: capitalize;
                                                    
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        .dkg-clientlist-mainRow{
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                        }
                        .dkg-clientlist-mainContent{
                            flex: 0 0 100%;
                            max-width: 100%;
                            position: relative;
                            .dkg-cl-main-sideMenu{
                                flex: 0 0 14.666667%;
                                max-width: 14.666667%;
                                .dkg-clientlist-tab-navpills {
                                    margin-bottom: 0;
                                    width: 100%;
                                    background-color: #244158;
                                    height: 100%;
                                    flex-direction: column !important;
                                    height: calc(100vh - 230px);
                                    .dkg-clientlist-tab-nav-item{
                                        .dkg-clientlist-tab-nav-link{
                                            background-color: #244158;
                                            border-bottom: 1px solid #3e5b73;
                                            color: #ddd;
                                            display: block;
                                            font-family: Roboto, sans-serif !important;
                                            font-size: 15px;
                                            font-weight: 400;
                                            padding: 11px 15px;
                                            text-transform: inherit;
                                            width: 100%;
                                            border-radius: 0;
                                            &.active,
                                            &:hover {
                                                background-color: #eaeaea;
                                                color: #333;
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-cl-main-mainContent{
                                flex: 0 0 85.333333%;
                                max-width: 85.333333%;
                            }
                            .dkg-payment-qt-tab-mainCon {
                                background-color: #eaeaea;
                                // margin-top: 15px !important;
                                border-top: 1px solid #ddd;
                                height: calc(100vh - 230px);
                        
                                .col-md-2 {
                                    @media only screen and (min-width: 768px) {
                                        &.dkg-payment-qt-tabCon {
                                            flex: 0 0 15.666667%;
                                            max-width: 15.666667%;
                                        }
                                    }
                                    .dkg-payment-qt-tab-navpills {
                                        margin-bottom: 0;
                                        width: 100%;
                                        background-color: #244158;
                                        height: calc(100vh - 250px);            
                                        .dkg-payment-qt-tab-nav-item {
                                            .dkg-payment-qt-tab-navlink {
                                                // background-color: #eaeaea;
                                                // border-radius: 0;
                                                // color: #333;
                                                // font-size: .95rem;
                                                // padding: 0.65rem 1rem;
                                                // text-align: center;
                                                background: #244158;
                                                border-bottom: 1px solid #3e5b73;
                                                color: #ddd;
                                                display: block;
                                                font-family: Roboto, sans-serif !important;
                                                font-size: 15px;
                                                font-weight: 400;
                                                padding: 11px 15px;
                                                text-transform: inherit;
                                                width: 100%;
                                                border-radius: 0;
                        
                                                &.active,
                                                &.show>.nav-link {
                                                    background-color: #eaeaea;
                                                    color: #333;
                                                }
                                            }
                                        }
                                    }
                                }
                        
                                .col-md-10 {
                                    @media only screen and (min-width: 768px) {
                                        &.dkg-payment-rt-tabCon-10 {
                                            flex: 0 0 84.333333%;
                                            max-width: 84.333333%;
                                            height: calc(100vh - 178px);
                                        }
                                    }
                                }
                        
                                .dkg-payment-qt-tabcontent {
                                    .dkg-payment-qt-tabpane {
                                        .dkg-payment-qt-rightpanel {
                                            position: relative;
                        
                                            .dkg-subcategory-header {
                                                display: flex;
                                                align-items: center;
                                                width: 100%;
                                                position: relative;
                                                border-bottom: 1px solid #ddd;
                                                min-height: 40px;
                        
                                                .headerlist {
                                                    margin-bottom: 0;
                                                    padding-inline-start: 0;
                                                    display: flex;
                                                    align-items: center;
                                                    // width: 100%;
                                                    justify-content: flex-end;
                                                    margin: 0.625rem 0;
                                                    width: 100%;
                                                    // position: absolute;
                                                    // right: 18px;
                                                    // top: 10px;
                                                    // margin-left: 3rem;
                        
                                                    .headeritem {
                                                        list-style: none;
                        
                                                        .dk-resCrcleIconCon {
                                                            align-items: center;
                                                            background-color: #fff;
                                                            border: 1px solid #ddd;
                                                            border-radius: 50%;
                                                            display: flex;
                                                            height: 24px;
                                                            justify-content: center;
                                                            width: 24px;
                                                            cursor: pointer;
                        
                                                            svg {
                                                                width: 11px;
                                                                height: 11px;
                                                                cursor: pointer;
                                                                color: #7c7171;
                                                            }
                        
                                                            &:hover {
                                                                outline: 0;
                                                                box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                                            }
                                                        }
                        
                                                    }
                        
                                                    .title {
                                                        font-size: 15px;
                                                        font-weight: 500;
                                                        text-align: center;
                                                        text-transform: uppercase;
                                                        width: 100%;
                                                    }
                                                }
                        
                                                .dkg-script-overview-title {
                                                    color: #eaeaea;
                                                    margin-bottom: 23px;
                                                }
                                            }
                        
                                            .title {
                                                font-size: 15px;
                                                font-weight: 500;
                                                padding: 11px 0;
                                                text-align: center;
                                                text-transform: uppercase;
                                                width: 100%;
                        
                                                &.dkg-page-title {
                                                    padding: inherit;
                                                    width: 300px;
                                                    position: absolute;
                                                    left: 50%;
                                                    transform: translateX(-50%);
                                                }
                                            }
                        
                                            .dk-moduleTraining-rightCols {
                                                background: #fff;
                                                border: 1px solid #ddd;
                                                height: calc(100vh - 144px);
                                                width: 100%;
                        
                                                .wrapper-class.rdw-editor-wrapper {
                                                    .rdw-editor-toolbar.toolbar-class {
                                                        margin-bottom: 0;
                        
                                                        .rdw-inline-wrapper {
                                                            .rdw-option-wrapper {
                                                                max-width: 20px;
                                                                height: 20px;
                        
                                                                &:nth-child(04),
                                                                &:nth-child(05),
                                                                &:nth-child(06),
                                                                &:nth-child(07) {
                                                                    display: none;
                                                                }
                                                            }
                                                        }
                        
                                                        .rdw-colorpicker-wrapper,
                                                        .rdw-link-wrapper {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                            }
                        
                                            .dk-tabs-rightCols-12 {
                                                .dk-tabs-right-detail-12 {
                                                    .dk-tabs-right-tabcontent-13 {
                                                        .wrapper-class.rdw-editor-wrapper {
                                                            .rdw-editor-toolbar.toolbar-class {
                                                                div.rdw-image-wrapper {
                                                                    position: relative;
                        
                                                                    .rdw-image-modal {
                                                                        position: absolute;
                                                                        left: -340px;
                                                                    }
                                                                }
                                                            }
                        
                                                            .editor-class.rdw-editor-main {
                                                                background-color: #fff;
                                                                height: calc(100vh - 345px);
                                                                overflow-y: hidden;
                                                                padding: 0 10px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                        
                                            // .dk-tabs-rightCols-12 {
                                            //     .dk-tabs-right-detail-12 {
                                            //         .dk-tabs-right-tabpane-12.addnewClass {
                                            //             display: none;
                                            //         }
                                            //     }
                                            // }
                        
                                            .dk-tabs-rightCols-12 {
                                                .dk-tabs-right-detail-12 {
                                                    .dk-tabs-right-nav-12 {
                                                        position: absolute;
                                                        left: 0;
                                                        top: 10px;
                        
                                                        .dk-tabs-right-navitem-12 {
                                                            margin-right: 18px;
                        
                                                            .dk-tabs-right-navlink-12 {
                                                                border-bottom: 3px solid transparent;
                                                                box-shadow: none;
                                                                color: #737373;
                                                                font-family: Roboto, sans-serif !important;
                                                                font-size: 16px;
                                                                font-weight: 400;
                                                                padding: 0 0 2px;
                                                                text-transform: capitalize;
                        
                                                                &.active,
                                                                &.activeSubTab {
                                                                    border-bottom: 3px solid #3c97b6;
                                                                    color: #3c97b6;
                                                                }
                                                            }
                                                        }
                                                    }
                        
                                                    .dk-tabs-right-tabcontent-12 {
                                                        .dk-tabs-right-tabpane-12 {
                                                            background: #fff;
                                                            border: 1px solid #ddd;
                                                            height: calc(100vh - 165px);
                                                            width: 100%;
                        
                                                            .dk-rightDetail-panel-13 {
                                                                .dk-getStartedRight-detail-13 {
                                                                    display: flex;
                        
                                                                    .dk-tabs-right-nav-13 {
                                                                        width: 100%;
                                                                        max-width: 195px;
                                                                        min-width: 195px;
                                                                        background: #eaeaea;
                                                                        display: inline-block;
                                                                        height: calc(100vh - 168px);
                                                                        margin-right: 0;
                        
                        
                                                                        .dk-tabs-right-navitem-13 {
                                                                            .dk-tabs-right-navlink-13 {
                                                                                align-items: flex-start;
                                                                                border-bottom: 1px solid #ddd;
                                                                                color: #757575;
                                                                                display: block;
                                                                                flex-shrink: inherit;
                                                                                font-size: 15px;
                                                                                font-weight: 400;
                                                                                max-width: 100%;
                                                                                min-height: auto;
                                                                                min-width: inherit !important;
                                                                                opacity: 1;
                                                                                outline: none;
                                                                                overflow: visible;
                                                                                padding: 11px 15px;
                                                                                position: relative;
                                                                                text-align: left;
                                                                                text-transform: capitalize;
                                                                                width: 100%;
                        
                                                                                &.active {
                                                                                    background: #fff;
                                                                                    color: #606060;
                                                                                }
                                                                            }
                                                                        }
                        
                                                                        .dkg-script-subcategory {
                                                                            align-items: flex-start;
                                                                            border-bottom: 1px solid #ddd;
                                                                            color: #757575;
                                                                            display: block;
                                                                            flex-shrink: inherit;
                                                                            font-size: 15px;
                                                                            font-weight: 400;
                                                                            max-width: 100%;
                                                                            min-height: auto;
                                                                            min-width: inherit !important;
                                                                            opacity: 1;
                                                                            outline: none;
                                                                            overflow: visible;
                                                                            padding: 11px 15px;
                                                                            position: relative;
                                                                            text-align: left;
                                                                            text-transform: capitalize;
                                                                            width: 100%;
                        
                                                                            &.subcategory-active {
                                                                                background: #fff;
                                                                                color: #606060;
                                                                            }
                                                                        }
                                                                    }
                        
                                                                    .dk-tabs-right-tabcontent-13 {
                                                                        .dk-tabs-right-tabpane-13 {
                                                                            padding: 24px;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                        
                                                    .dk-tabs-right-tabpane-12 {
                                                        &.addnewClass {
                        
                                                            &.activesSubTab {
                                                                .dk-rightDetail-panel-13 {
                                                                    .dk-getStartedRight-detail-13 {
                                                                        display: flex;
                        
                                                                        .dk-tabs-right-nav-13 {
                                                                            background: #eaeaea;
                                                                            display: inline-block;
                                                                            height: calc(100vh - 221px);
                                                                            margin-right: 0;
                                                                            max-width: 195px;
                                                                            min-width: 195px;
                                                                            width: 100%;
                        
                                                                            .dk-tabs-right-navitem-13.nav-item {
                                                                                .dk-tabs-right-navlink-13 {
                                                                                    align-items: flex-start;
                                                                                    justify-content: flex-start;
                                                                                    border-bottom: 1px solid #ddd;
                                                                                    border-left: 1px solid #ddd;
                                                                                    color: #757575;
                                                                                    display: block;
                                                                                    flex-shrink: inherit;
                                                                                    font-size: 15px;
                                                                                    font-weight: 400;
                                                                                    max-width: 100%;
                                                                                    min-height: auto;
                                                                                    min-width: inherit !important;
                                                                                    opacity: 1;
                                                                                    outline: none;
                                                                                    overflow: visible;
                                                                                    padding: 12px 9px;
                                                                                    position: relative;
                                                                                    text-align: left;
                                                                                    text-transform: inherit !important;
                                                                                    width: 100%;
                        
                                                                                    &.addActiveClass {
                                                                                        background: #fff;
                                                                                        color: #606060;
                                                                                    }
                                                                                }
                        
                                                                                // &:first-child {
                                                                                //     .dk-tabs-right-navlink-13 {
                                                                                //         border-top: 1px solid #ddd;
                                                                                //     }
                                                                                // }
                                                                            }
                                                                        }
                        
                                                                        .dk-tabs-right-tabcontent-13 {
                                                                            width: 100%;
                        
                                                                            .wrapper-class.rdw-editor-wrapper {
                                                                                .rdw-editor-toolbar.toolbar-class {
                                                                                    margin-bottom: 0;
                        
                                                                                    .rdw-inline-wrapper {
                                                                                        .rdw-option-wrapper {
                                                                                            max-width: 20px;
                                                                                            height: 20px;
                        
                                                                                            &:nth-child(04),
                                                                                            &:nth-child(05),
                                                                                            &:nth-child(06),
                                                                                            &:nth-child(07) {
                                                                                                display: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                        
                                                                                .editor-class.rdw-editor-main {
                                                                                    background-color: #fff;
                                                                                    height: calc(100vh - 335px);
                                                                                    overflow-y: auto;
                                                                                    padding: 5px 15px;
                                                                                    padding-top: 0;
                                                                                    position: relative;
                                                                                    top: -5px;
                                                                                    // .DraftEditor-root{
                                                                                    //     .DraftEditor-editorContainer{
                                                                                    //         .public-DraftEditor-content{
                                                                                    //             div{
                                                                                    //                 div{
                                                                                    //                     .public-DraftStyleDefault-block{
                        
                                                                                    //                     }
                                                                                    //                 }
                                                                                    //             }
                                                                                    //         }
                                                                                    //     }
                                                                                    // }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .dk-company-new-panel-13{
                                                .dk-company-new-detail-13{
                                                    display: flex;
                                                    width: 100%;
                                                    .dkg-company-new-navpills{
                                                        background-color: #eaeaea;
                                                        display: inline-block;
                                                        height: calc(100vh - 221px);
                                                        margin-right: 0;
                                                        width: 100%;
                                                        max-width: 195px;
                                                        min-width: 195px;
                                                        .dkg-company-new-navitem{
                                                            .dkg-company-new-navlink{
                                                                border-bottom: 1px solid #ddd;
                                                                border-left: 1px solid #ddd;
                                                                color: #757575;
                                                                font-size: 15px;
                                                                font-weight: 400;
                                                                max-width: 100%;
                                                                padding: 12px 9px;
                                                                text-align: left;
                                                                width: 100%;
                                                                border-radius: 0;
                                                                &.active{
                                                                    background-color: #fff;
                                                                    color: #606060;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dk-company-new-tabcontent-13{
                                                        width: 100%;
                                                        .dkg-company-new-tabpane{
                                                            background-color: #fff;
                                                            height: calc(100vh - 240px);
                                                            border: 1px solid #ddd;
                                                            border-top: none;
                                                            padding: 15px;
                                                            overflow-y: auto;
                                                            .dkg-companynew-info-mainCon{
                                                                width: 100%;
                                                                .dkg-companynew-info-con{
                                                                    width: 100%;
                                                                   .dkg-comany-banner-mainCon{
                                                                        background-color: transparent;
                                                                        border-radius: 5px;
                                                                        padding: 0;
                                                                        width: 100%;
                                                                        max-width: 100%;
                                                                        // margin-right: auto;
                                                                        .dkg-comany-banner-cols{
                                                                            margin: 0 ;
                                                                            .dkg-comany-banner-box{
                                                                                padding: 0 0 0px;
                                                                                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
                                                                                transition: box-shadow 83ms;
                                                                                background: #fff;
                                                                                position: relative;
                                                                                .dkg-comany-banner{
                                                                                    width: 100%;
                                                                                    overflow: hidden;
                                                                                    height: 360px;
                                                                                    background-position: center;
                                                                                    background-repeat: no-repeat;
                                                                                    background-size: cover;
                                                                                }
                                                                                .dkg-companynew-banner-edit{
                                                                                    position: absolute;
                                                                                    right: 10px;
                                                                                    top: 0;
                                                                                    z-index: 9;
                                                                                    cursor: pointer;
                                                                                    .dkg-banner-input{
                                                                                        cursor: pointer;
                                                                                        width: 24px;
                                                                                        height: 24px;
                                                                                        position: relative;
                                                                                        left: 24px;
                                                                                        z-index: 99;
                                                                                        opacity: 0;
                                                                                    }
                                                                                    svg{
                                                                                        width: 30px;
                                                                                        height: 30px;
                                                                                        cursor: pointer;
                                                                                    }
                                                                                }
                                                                            }
                                                                            .dkg-cp-info-bottomcon{
                                                                                margin: 0;
                                                                                padding: 15px;
                                                                                position: relative;
                                                                                z-index: 5;
                                                                                display: flex;
                                                                                align-items: center;
                                                                                justify-content: space-between;
                                                                                width: 100%;
                                                                                border-left: 1px solid #ddd;
                                                                                border-right: 1px solid #ddd;
                                                                                background-color: #f2f2f2;
                                                                                .dkg-cp-info-leftCon{
                                                                                    display: flex;
                                                                                    align-items: center;
                                                                                    .dkg-cp-info-box{
                                                                                        margin: -90px 0 0;
                                                                                        height: 130px;
                                                                                        width: 170px;
                                                                                        display: flex;
                                                                                        align-items: center;
                                                                                        justify-content: center;
                                                                                        background: #fff;
                                                                                        box-shadow: 0 0 3px #000;
                                                                                        border-radius: 5px;
                                                                                        position: relative;
                                                                                        .dkg-cp-info-logo{
                                                                                            width: 100%;
                                                                                            height: 100%;
                                                                                            background-size: cover;
                                                                                            overflow: hidden;
                                                                                            border-radius: 5px; 
                                                                                        }
                                                                                        .dkg-cp-info-edit-icon{
                                                                                            position: absolute;
                                                                                            right: 10px;
                                                                                            top: 5px;
                                                                                            cursor: pointer;
                                                                                            .dkg-cp-info-logo-input{
                                                                                                cursor: pointer;
                                                                                                width: 24px;
                                                                                                height: 24px;
                                                                                                position: relative;
                                                                                                left: 24px;
                                                                                                z-index: 99;
                                                                                                opacity: 0;
                                                                                            }
                                                                                            svg{
                                                                                                width: 22px;
                                                                                                height: 22px;
                                                                                                cursor: pointer;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    .dkg-cp-info-nameCon{
                                                                                        position: relative;
                                                                                        .dkg-cp-info-name{
                                                                                            margin-left: 20px;
                                                                                            border-radius: 0;
                                                                                            text-align: left;
                                                                                            color: #000000;
                                                                                            font-weight: 600;
                                                                                            padding: 9px 10px;
                                                                                            font-size: 1.35rem;
                                                                                            position: relative;
                                                                                            top: -10px;
                                                                                            border: 1px solid #ddd;
                                                                                           background-color: #f2f2f2;
                                                                                        }
                                                                                        .dkg-cp-info-title-edit{
                                                                                            position: absolute;
                                                                                            right: -25px;
                                                                                            top: -17px;
                                                                                            z-index: 9;
                                                                                            svg{
                                                                                                width: 18px;
                                                                                                height: 18px;
                                                                                                cursor: pointer;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                .dkg-cp-info-rightCon{
                                                                                    .dkg-socailmedia-box{
                                                                                        .dkg-socailmedia-tab{
                                                                                            .dkg-socailmedia-img{
                                                                                                width: 34px;
                                                                                                height: 34px;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    .dkg-companynew-banner-tableCon{
                                                                        background-color: transparent;
                                                                        border-radius: 5px;
                                                                        padding: 0;
                                                                        width: 100%;
                                                                        max-width: 100%;
                                                                        .dkg-companynew-info-tablecon{
                                                                            background-color: #fff;
                                                                            border-radius: 5px;
                                                                            padding: 0;
                                                                            // padding-top: 0;
                                                                            // padding-left: 0;
                                                                            width: 100%;
                                                                            max-width: 100%;
                                                                            .dkg-companynew-info-table{
                                                                                margin-bottom: 0;
                                                                                // max-width: 65%;
                                                                                tbody{
                                                                                    tr{
                                                                                        td{
                                                                                            color: #333;
                                                                                            background-color: #fff;
                                                                                            text-align: left;
                                                                                            padding: 5px 10px;
                                                                                            font-size: 1rem;
                                                                                            vertical-align: middle;
                                                                                            &:nth-child(01){
                                                                                                width: 230px;
                                                                                                background-color: #244158;
                                                                                                color: #fff;
                                                                                                border-bottom: 1px solid #a7a2a2;
                                                                                            }
                                                                                            // &:nth-child(02){
                                                                                            //     width: 340px;
                                                                                            // }
                                                                                            .dkg-sm-input{
                                                                                                border: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            .dkg-companynew-aboutus-Con{
                                                                width: 100%;
                                                                .dkg-instructions-editorCon{
                                                                    width: 100%;
                                                                    max-width: 1600px;
                                                                    margin: 0 auto;
                                                                    .wrapper-class.rdw-editor-wrapper {
                                                                        .rdw-editor-toolbar.toolbar-class {
                                                                            margin-bottom: 0;
                                                                            div.rdw-image-wrapper {
                                                                                position: relative;
                                                            
                                                                                .rdw-image-modal {
                                                                                    position: absolute;
                                                                                    left: -340px;
                                                                                }
                                                                            }
                                                                        }
                                                            
                                                                        .editor-class.rdw-editor-main {
                                                                            background-color: #fff;
                                                                            height: calc(100vh - 285px);
                                                                            overflow-y: hidden;
                                                                            padding: 0 10px;
                                                                            border: 1px solid #ddd;
                                                                            border-top: none;
                                                                        }
                                                                    }
                                                                    .dkg-mailbox-editor-mainCon{
                                                                        .jodit-react-container{
                                                                            .jodit-container{
                                                                                height: calc(100vh - 330px) !important;
                                                                                .jodit-toolbar__box{
                                                                                    .jodit-toolbar-editor-collection{
                                                                                        .jodit-ui-group.jodit-ui-group_size_middle{
                                                                                            height: 35px;
                                                                                        }
                                                                                    }
                                                                                }
                                                                                .jodit-workplace{
                                                                                    height: calc(100vh - 330px) !important;
                                                                                    // .jodit-wysiwyg

                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    .dkg-submit-btnCon{
                                                                        display: flex;
                                                                        align-items: center;
                                                                        justify-content: center;
                                                                        width: 100%;
                                                                        margin-top: 20px;
                                                                        div{
                                                                            div{
                                                                                .dkg-cand-submit-btn{
                                                                                    text-align: center;
                                                                                    background-color: #2D96B6;
                                                                                    color: #fff;
                                                                                    &:hover{
                                                                                        color: #fff;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    
                                                                }
                                                            }
                                                            &.dkg-company-abouts-tabpane{
                                                                overflow-y: hidden;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .dkg-edit-jobfiles-mainCon {
                            padding: 0 15px;
                            padding-top: 6px;
                            background-color: #eaeaea;
                            .dkg-jobfiles-header {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 5px;
                                .dkg-leftStaffnHead {
                                    align-items: center;
                                    display: flex;
                                    .dk-searchCols {
                                        margin-right: 8px;
                                        max-width: 160px;
                                        position: relative;
                                        width: 100%;
                                        input {
                                            background-color: #fff;
                                            border: 1px solid #ddd;
                                            color: #818181;
                                            height: 32px;
                                            padding: 0 12px;
                                            text-align: left;
                                       }
                                        button {
                                            background-color: #037DB2;
                                            border: 1px solid #818181;
                                            color: #818181;
                                            display: inline-block;
                                            font-size: 13px;
                                            height: 32px;
                                            outline: none;
                                            position: absolute;
                                            right: 0;
                                            text-align: center;
                                            top: 0;
                                            width: 32px;
                                            svg {
                                                color: #fff;
                                           }
                                       }
                                   }
                               }
                               .dkg-add-new-btnCon {
                                margin-bottom: 5px;
        
                                .dkg-jobfiles-header {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
        
                                    .dkg-leftStaffnHead {
                                        align-items: center;
                                        display: flex;
        
                                        .dk-searchCols {
                                            margin-right: 8px;
                                            max-width: 160px;
                                            position: relative;
                                            width: 100%;
        
                                            input {
                                                background-color: #374252;
                                                border: 1px solid #818181;
                                                color: #818181;
                                                height: 32px;
                                                padding: 0 12px;
                                                text-align: left;
                                            }
        
                                            button {
                                                background-color: #374252;
                                                border: 1px solid #818181;
                                                color: #818181;
                                                display: inline-block;
                                                font-size: 13px;
                                                height: 32px;
                                                outline: none;
                                                position: absolute;
                                                right: 0;
                                                text-align: center;
                                                top: 0;
                                                width: 32px;
                                            }
                                        }
        
                                    }
                                }
        
                                .dkg-addnew-btn {
                                    box-shadow: none;
                                    border: 1px solid #ccc;
                                    background-color: #fff;
                                    color: #333;
                                    font-size: 13px;
                                    text-transform: capitalize;
                                    padding: 7px 9px;
                                    // min-width: 110px;
                                    margin: 0;
                                }
                            }
                           }
                           .dkg-edit-jobfiles-tableCon {
                            height: calc(100vh - 150px);
                            overflow-y: auto;
                            padding-bottom: 0;
                            position: relative;
                            height: calc(100vh - 295px);
                            .dkg-edit-jobfiles-table {
                                thead {
                                    tr {
                                        th {
                                            background: #037DB2;
                                            border: 1px solid gray;
                                            border-bottom: none;
                                            color: #fff;
                                            font-size: 13px;
                                            padding: 9px 7px;
                                            position: -webkit-sticky;
                                            position: sticky;
                                            text-align: center;
                                            top: 0;
                                            vertical-align: middle;
                                            white-space: nowrap;
                                            z-index: 9;
                                        }
                                    }
                                }
    
                                tbody {
    
                                    tr {
                                        td {
                                            background: #fff;
                                            border: 1px solid #ddd;
                                            color: #4d4a4a;
                                            font-size: 13px;
                                            font-weight: 400;
                                            padding: 9px 7px;
                                            position: relative;
                                            text-align: center;
                                            text-transform: capitalize;
                                            vertical-align: middle;
                                            white-space: nowrap;
    
                                            .dkg-viewfile-iconCon {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
    
                                                .dkg-viewfile-icon {
                                                    border-radius: 3px;
                                                    font-size: 14px;
                                                    height: 30px !important;
                                                    line-height: 30px;
                                                    width: 30px !important;
                                                    background-color: #2196f3;
                                                    color: #fff;
                                                }
                                            }
    
                                            .dkg-file-actions {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
    
                                                .dkg-viewfile-icon {
                                                    border-radius: 3px;
                                                    font-size: 14px;
                                                    height: 30px !important;
                                                    line-height: 30px;
                                                    width: 30px !important;
                                                    background-color: #2196f3;
                                                    color: #fff;
                                                }
    
                                                .dkg-download-icon {
                                                    border-radius: 3px;
                                                    font-size: 14px;
                                                    height: 30px !important;
                                                    line-height: 30px;
                                                    width: 30px !important;
                                                    background-color: #31a886;
                                                    color: #fff;
                                                }
    
                                                .dkg-editfile-icon {
                                                    border-radius: 3px;
                                                    font-size: 14px;
                                                    height: 30px;
                                                    line-height: 30px;
                                                    width: 30px;
                                                    background-color: #fee69c;
                                                    color: #333;
                                                }
    
                                                .dkg-deletefile-icon {
                                                    border-radius: 3px;
                                                    font-size: 14px;
                                                    height: 30px;
                                                    line-height: 30px;
                                                    width: 30px;
                                                    background-color: #dc3545;
                                                    color: #fff;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
    
                            &::-webkit-scrollbar {
                                width: 10px;
                                cursor: pointer;
                            }
    
                        }
    
                        .dkg-edit-jobfiles-tableCon::-webkit-scrollbar {
                            cursor: pointer;
                        }
                       }
                    }
                }
            }          
            .dkg-clientlist-main-ul{
                list-style: none;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                width: 100%;
                padding-inline-start: 0;
                background-color: #eaeaea;
                padding: 0;
                border-top: 1px solid #ddd;
               li{
                    width: 100%;
                    max-width: 9.0909%;
                    background-color: #fff;
                    a{
                        color: #333;
                        padding: 11px 5px;
                        width: 100%;
                        text-decoration: none;
                        font-size: 14px;
                        display: inline-block;
                        text-align: center;
                        // border-right: 1px solid #ddd;
                        &.active{
                            position: relative;
                            &::before {
                                content: "";
                                color: #244158;
                                width: 100%;
                                left: 5px;
                                bottom: 2px;
                                position: absolute;
                                border: 2px solid #2e97b6;
                                width: 80%;
                                left: 10px;

                            }
                        }
                    }
                }
            }
        }
        &.dkg-clientlist-mainsec-Con{
            height: calc(100vh - 140px);
            .dkg-clientlist-main-ulCon {
                &.dkg-adminclients-main-ulCon{
                    .dkg-clientlist-main-ul-tabsCon {
                        &.dkg-adminclients-main-ul-tabsCon{
                            .dkg-cl-info-main-tabContent{
                                .dkg-cl-info-main-tabpane{
                                    height: calc(100vh - 175px) !important;
                                    .dkg-clientlist-mainRow{
                                        .dkg-clientlist-mainContent{
                                            .dkg-payment-qt-tab-mainCon{
                                                .dkg-payment-qt-tabCon{
                                                    .dkg-payment-qt-tab-navpills{
                                                        height: calc(100vh - 176px) !important;
                                                    }
                                                }
                                                .dkg-payment-rt-tabCon-10{
                                                    .dkg-payment-qt-tabcontent{
                                                        .dkg-payment-qt-tabpane{
                                                            .dkg-payment-qt-rightpanel{
                                                                .dkg-tabs-rightPanel-12{
                                                                    .dk-tabs-rightCols-12{
                                                                        .dk-tabs-right-detail-12{
                                                                            // .dk-tabs-right-tabpane-12{
                                                                            //     .wrapper-class.rdw-editor-wrapper
                                                                                
                                                                            // }
                                                                            .dk-tabs-right-tabcontent-13{
                                                                                    div {
                                                                                        justify-content: center;
                                                                                        // margin-top: 15px;
                                                                                        div{
                                                                                            .dkg-create-payment-taskbtn{
                                                                                                background-color: #244158;
                                                                                                color: #fff;
                                                                                                margin-top: 20px;
                                                                                                &:hover{
                                                                                                    background-color: #037DB2;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.dkg-task-paymentpage12 {
    .dk-faq-candprescreen-TopHead {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;

        .dk-faq-candprescreenLeftTopHead {
            .dk-SrchInputBox {
                .input-group {
                    max-width: 230px;

                    input {
                        background-color: #fff !important;
                        border: 1px solid #ddd !important;
                        border-radius: 0;
                        box-shadow: none;
                        height: 35px;
                        line-height: 1.3rem;
                        margin: 0;
                        padding: 0.5rem 0.55rem;
                    }

                    .input-group-append {
                        margin-left: -2px;

                        button {
                            background-color: #fff !important;
                            border: 1px solid #ddd;
                            border-radius: 0;
                            box-shadow: none;
                            height: 35px;
                            line-height: 1.3rem;
                            margin: 0;
                            padding: 0.5rem 0.55rem;

                            i {
                                color: #333;
                            }
                        }
                    }
                }

                .dk-faq-addcategory-btn {
                    display: inline-block;
                    font-weight: 400;
                    background-color: #fff;
                    color: #212529;
                    border: 1px solid #ddd;
                    text-align: center;
                    vertical-align: middle;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    transition: all .25s ease-in-out;

                    &:hover {
                        background-color: #f1f1f1;
                    }
                }
            }
        }

        .dk-faq-candprescreen-titleCon {
            display: inline-block;
            left: 50%;
            position: absolute;
            text-transform: uppercase;
            top: 7px;
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            white-space: nowrap;

            .dk-faq-candprescreen-title {
                color: #26597d;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .dk-faq-right-candprescreenCon {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .dk-faq-addcategory-btn {
                display: inline-block;
                font-weight: 400;
                background-color: #fff;
                color: #212529;
                border: 1px solid #ddd;
                text-align: center;
                vertical-align: middle;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                line-height: 1.5;
                transition: all .25s ease-in-out;

                &:hover {
                    background-color: #f1f1f1;
                }
            }

        }
    }

    .dkg-faq-candprescreen-tableCon-12 {
        height: calc(100vh - 225px);
        margin-top: 6px;
        overflow-x: auto;
        overflow-y: auto;
        padding-bottom: 0;
        position: relative;
        &.dkg-client-faq-tableCon{
            height: calc(100vh - 206px);
            .dkg-faq-category-table {
                margin-bottom: 0 !important;
            }
        }
        .dkg-faq-category-table {
            thead {
                tr {
                    th {
                        background: #234158;
                        border-bottom: none;
                        border-left: none;
                        border-top: none;
                        color: #fff;
                        font-size: 12px;
                        padding: 10px 7px;
                        position: -webkit-sticky;
                        position: sticky;
                        text-align: center;
                        text-transform: uppercase;
                        top: 0;
                        vertical-align: middle;
                        white-space: nowrap;
                        z-index: 9;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        background-color: #fff;
                        border-bottom: 1px solid #ddd;
                        font-size: 13px !important;
                        font-weight: 400;
                        padding: 5px 7px;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;

                        // &:nth-child(02) {
                        //     text-align: left;
                        // }
                        &:first-child {
                            border-left: 1px solid #ddd;
                        }

                        &:last-child {
                            border-right: 1px solid #ddd;
                        }

                        .dkg-status-dropdown {
                            .dropdown-toggle {
                                border: 0;
                                cursor: pointer;
                                font-weight: 600;
                                display: inline-block;
                                color: #fff !important;
                                padding: 5px 10px;
                                background-color: #3a9469 !important;
                                min-width: 95px;
                                font-size: 13px;
                                box-shadow: none;
                                text-transform: capitalize;
                                margin: 0;

                                &::after {
                                    display: none;

                                }
                            }

                            &.deActiveClass {
                                .dropdown-toggle {
                                    background-color: #625c60 !important;
                                }
                            }

                            .dropdown-menu {
                                border-radius: 0;
                                padding: 0;
                                min-width: 120px;

                                .dkg-status-dropdown-item {
                                    border-bottom: 1px solid transparent;
                                    font-size: 13px;
                                    padding: 5px 7px;

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }

                        .dkg-addnew-quest-btnCon {
                            .dkg-addnew-quest-btn {
                                background: #17a2b8;
                                border: 0;
                                cursor: pointer;
                                font-weight: 600;
                                min-width: 95px;
                                display: inline-block;
                                color: #fff !important;
                                padding: 3px 18px;
                            }
                        }

                        .dkg-sequence-input {
                            width: 50px !important;
                            text-align: center;
                            background-color: #ccc;
                            height: 25px;
                            border: 1px solid #e7b7b7;
                        }

                        .dkg-faq-actions-col {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .dkg-faq-edit-btn {
                                align-items: center;
                                background-color: #fee69c;
                                border-radius: 3px;
                                display: flex;
                                font-size: 16px;
                                height: 30px;
                                justify-content: center;
                                line-height: 30px;
                                width: 30px;
                                color: #333;

                                &:hover {
                                    outline: 0;
                                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                }
                            }

                            .dkg-faq-delete-btn {
                                align-items: center;
                                background: #ccc;
                                background-color: #dc3545;
                                border-radius: 3px;
                                color: #fff;
                                display: flex;
                                font-size: 16px;
                                height: 30px;
                                justify-content: center;
                                line-height: 30px;
                                width: 30px;

                                &:hover {
                                    outline: 0;
                                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                }
                            }

                        }
                    }
                }
            }

            &.dkg-faq-category-table-234 {
                tbody {
                    tr {
                        td {

                            // &:nth-child(04) {
                            //     text-align: left;
                            //     max-width: 260px;
                            //     min-width: 260px;
                            //     overflow: hidden;
                            //     text-overflow: ellipsis;
                            //     white-space: nowrap;
                            // }

                            &:nth-child(05) {
                                text-align: center;
                                max-width: 320px;
                                min-width: 320px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;

                                .dkg-faq-questtitle {
                                    &:hover {
                                        color: #629bd3;
                                        cursor: pointer;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}

.dkg-faq-addnew-catModal {
    z-index: 9999;

    .modal-dialog {
        .modal-content {
            border-radius: 5px;

            .dk-faq-addcategory-modal-header {
                border-radius: 5px 5px 0 0;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: start;
                align-items: flex-start;
                -ms-flex-pack: justify;
                justify-content: center;
                padding: 0.5rem 1rem;
                border-bottom: 1px solid #17a2b8;
                position: relative;
                background-color: #17a2b8;
                color: #fff;

                .modal-title {
                    margin-bottom: 0;
                    line-height: 1.5;
                }

                .close {
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    opacity: 1;
                    color: #fff;

                    &:hover {
                        opacity: 0.81;
                    }
                }
            }

            .dk-faq-addcategory-modal-body {
                .form-group {
                    input {
                        border-radius: 0;
                    }
                }
            }

            .dk-faq-addcategory-modal-footer {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .dkg-create-btn {
                    color: #fff;
                    background-color: #17a2b8;
                    border-color: #17a2b8;
                    border: 1px solid transparent;
                    padding: 0.375rem 1rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    border-radius: 5px;
                }

                .dkg-close-btn {
                    color: #fff;
                    background-color: #6c757d;
                    border-color: #6c757d;
                    border: 1px solid transparent;
                    padding: 0.375rem 1rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    border-radius: 5px;

                }

                div {
                    div {
                        button.dkg-submitbtn {
                            color: #fff;
                            background-color: #17a2b8;
                            border-color: #17a2b8;
                            border: 1px solid transparent;
                            padding: 0.375rem 1rem;
                            font-size: 1rem;
                            line-height: 1.5;
                            border-radius: 5px;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }
}

.modal-dialog.dk-adstarckDeletePop {
    min-width: 35%;

    .modal-content {
        background-color: transparent;

        .dk-adstrackDelBody {
            padding: 35px 15px 22px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background-color: #fff;

            p {
                color: #e2445b;
            }
        }

        .dk-adstrackDelFooter {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            padding: .75rem;
            border-top: 1px solid transparent;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: #fff;

        }

        button.dk-nodelBtn {
            border: 1px solid #333;
            color: #333 !important;
            background-color: transparent !important;
            display: inline-block;
            width: 100%;
            max-width: 90px;
            border-radius: 5px;
            padding: 7px 0;
        }

        div {
            div {
                button.dk-yesdelBtn {
                    background-color: #e2445b !important;
                    border: 1px solid #e2445b;
                    color: #fff;
                    display: inline-block;
                    width: 100%;
                    max-width: 90px;
                    border-radius: 5px;
                    padding: 7px 0;
                }
            }
        }
    }
}

// FOR REORDER POPUP
.dkg-script-modal-2345 {
    color: #244158;

    .modal-dialog {
        .modal-content {
            .dkg-todayads-reorderModalBody {
                ul {
                    padding-left: 0;
                    max-height: 400px;
                    overflow-y: auto;   
                }

                .dk-res-reorder-save-btnCon {
                    div {
                        div {
                            .dk-toolsPanelSaveBtn {
                                background-color: #244158;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
.dkg-paidservice-faq-mainCon{
    margin-top: 0;
    .dkg-task-paymentpage12{
        .dkg-faq-candprescreen-tableCon-12 {
            &.dkg-client-faq-tableCon{
                &.dkg-paidservice-faq-tableCon{
                    height: calc(100vh - 238px) !important;
                }
            }
        }
    }
}
.dkg-faq-addnew-questionModal {
    z-index: 9999;

    .modal-dialog {
        min-width: 800px;
        max-width: 800px;

        .modal-content {
            border-radius: 5px;

            .dk-faq-addquestion-modal-header {
                border-radius: 5px 5px 0 0;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: start;
                align-items: flex-start;
                -ms-flex-pack: justify;
                justify-content: center;
                padding: 0.5rem 1rem;
                border-bottom: 1px solid #17a2b8;
                position: relative;
                background-color: #17a2b8;
                color: #fff;

                .modal-title {
                    margin-bottom: 0;
                    line-height: 1.5;
                }

                .close {
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    opacity: 1;
                    color: #fff;

                    &:hover {
                        opacity: 0.81;
                    }
                }
            }

            .dk-faq-addquestion-modal-body {
                .form-group {
                    margin-bottom: 1.25rem !important;

                    &:last-child {
                        margin-bottom: 0 !important;
                    }

                    label {
                        margin-bottom: 0.5rem;
                    }

                    input,
                    textarea {
                        border-radius: 0;
                    }

                }
            }

            .dk-faq-addquestion-modal-footer {
                color: #333;
                border-radius: 0 0 5px 5px;
                display: flex;
                justify-content: center;
                align-items: center;

                .dkg-create-btn {
                    color: #fff;
                    background-color: #17a2b8;
                    border-color: #17a2b8;
                    border: 1px solid transparent;
                    padding: 0.375rem 1rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    border-radius: 5px;
                }

                .dkg-close-btn {
                    color: #fff;
                    background-color: #6c757d;
                    border-color: #6c757d;
                    border: 1px solid transparent;
                    padding: 0.375rem 1rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    border-radius: 5px;

                }
            }
        }
    }
}
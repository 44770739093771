.dkg-jobs-instrctions-main{
    margin-top: 140px;
    padding: 25px 30px;
    background-color: #fff;
    height: calc(100vh - 140px);
    .dkg-instructions-editorCon{
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        .wrapper-class.rdw-editor-wrapper {
            .rdw-editor-toolbar.toolbar-class {
                margin-bottom: 0;
                div.rdw-image-wrapper {
                    position: relative;

                    .rdw-image-modal {
                        position: absolute;
                        left: -340px;
                    }
                }
            }

            .editor-class.rdw-editor-main {
                background-color: #fff;
                height: calc(100vh - 285px);
                overflow-y: hidden;
                padding: 0 10px;
                border: 1px solid #ddd;
                border-top: none;
            }
        }
        .dkg-submit-btnCon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
            div{
                div{
                    .dkg-cand-submit-btn{
                        text-align: center;
                        background-color: #2D96B6;
                        color: #fff;
                        &:hover{
                            color: #fff;
                        }
                    }
                }
            }
        }
        
    }
}
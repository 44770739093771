.dkg-jobspec-compainesCon{
    .dkg-jobspec-rt-tabCon-12{
        .dkg-jobspec-qt-tabcontent {
            .dkg-jobspec-qt-rightpanel {
                position: relative;

                .dkg-subcategory-header {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    position: relative;
                    border-bottom: 1px solid #ddd;
                    min-height: 40px;

                    .headerlist {
                        margin-bottom: 0;
                        padding-inline-start: 0;
                        display: flex;
                        align-items: center;
                        // width: 100%;
                        justify-content: flex-end;
                        margin: 0.625rem 0;
                        width: 100%;
                        // position: absolute;
                        // right: 18px;
                        // top: 10px;
                        // margin-left: 3rem;

                        .headeritem {
                            list-style: none;

                            .dk-resCrcleIconCon {
                                align-items: center;
                                background-color: #fff;
                                border: 1px solid #ddd;
                                border-radius: 50%;
                                display: flex;
                                height: 24px;
                                justify-content: center;
                                width: 24px;
                                cursor: pointer;

                                svg {
                                    width: 11px;
                                    height: 11px;
                                    cursor: pointer;
                                    color: #7c7171;
                                }

                                &:hover {
                                    outline: 0;
                                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                }
                            }

                        }

                        .title {
                            font-size: 15px;
                            font-weight: 500;
                            text-align: center;
                            text-transform: uppercase;
                            width: 100%;
                        }
                    }

                    .dkg-script-overview-title {
                        color: #eaeaea;
                        margin-bottom: 23px;
                    }
                }

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    padding: 11px 0;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;

                    &.dkg-page-title {
                        padding: inherit;
                        width: 300px;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                .dk-moduleTraining-rightCols {
                    background: #fff;
                    border: 1px solid #ddd;
                    height: calc(100vh - 144px);
                    width: 100%;

                    .wrapper-class.rdw-editor-wrapper {
                        .rdw-editor-toolbar.toolbar-class {
                            margin-bottom: 0;

                            .rdw-inline-wrapper {
                                .rdw-option-wrapper {
                                    max-width: 20px;
                                    height: 20px;

                                    &:nth-child(04),
                                    &:nth-child(05),
                                    &:nth-child(06),
                                    &:nth-child(07) {
                                        display: none;
                                    }
                                }
                            }

                            .rdw-colorpicker-wrapper,
                            .rdw-link-wrapper {
                                display: none;
                            }
                        }
                    }
                }

                .dk-tabs-rightCols-12 {
                    .dk-tabs-right-detail-12 {
                        .dk-tabs-right-tabcontent-13 {
                            .wrapper-class.rdw-editor-wrapper {
                                .rdw-editor-toolbar.toolbar-class {
                                    div.rdw-image-wrapper {
                                        position: relative;

                                        .rdw-image-modal {
                                            position: absolute;
                                            left: -340px;
                                        }
                                    }
                                }

                                .editor-class.rdw-editor-main {
                                    background-color: #fff;
                                    height: calc(100vh - 345px);
                                    overflow-y: hidden;
                                    padding: 0 10px;
                                }
                            }
                        }
                    }
                }

                // .dk-tabs-rightCols-12 {
                //     .dk-tabs-right-detail-12 {
                //         .dk-tabs-right-tabpane-12.addnewClass {
                //             display: none;
                //         }
                //     }
                // }

                .dk-tabs-rightCols-12 {
                    .dk-tabs-right-detail-12 {
                        .dk-tabs-right-nav-12 {
                            position: absolute;
                            left: 0;
                            top: 10px;

                            .dk-tabs-right-navitem-12 {
                                margin-right: 18px;

                                .dk-tabs-right-navlink-12 {
                                    border-bottom: 3px solid transparent;
                                    box-shadow: none;
                                    color: #737373;
                                    font-family: Roboto, sans-serif !important;
                                    font-size: 16px;
                                    font-weight: 400;
                                    padding: 0 0 2px;
                                    text-transform: capitalize;

                                    &.active,
                                    &.activeSubTab {
                                        border-bottom: 3px solid #3c97b6;
                                        color: #3c97b6;
                                    }
                                }
                            }
                        }

                        .dk-tabs-right-tabcontent-12 {
                            .dk-tabs-right-tabpane-12 {
                                background: #fff;
                                border: 1px solid #ddd;
                                height: calc(100vh - 165px);
                                width: 100%;

                                .dk-rightDetail-panel-13 {
                                    .dk-getStartedRight-detail-13 {
                                        display: flex;

                                        .dk-tabs-right-nav-13 {
                                            width: 100%;
                                            max-width: 195px;
                                            min-width: 195px;
                                            background: #eaeaea;
                                            display: inline-block;
                                            height: calc(100vh - 168px);
                                            margin-right: 0;


                                            .dk-tabs-right-navitem-13 {
                                                .dk-tabs-right-navlink-13 {
                                                    align-items: flex-start;
                                                    border-bottom: 1px solid #ddd;
                                                    color: #757575;
                                                    display: block;
                                                    flex-shrink: inherit;
                                                    font-size: 15px;
                                                    font-weight: 400;
                                                    max-width: 100%;
                                                    min-height: auto;
                                                    min-width: inherit !important;
                                                    opacity: 1;
                                                    outline: none;
                                                    overflow: visible;
                                                    padding: 11px 15px;
                                                    position: relative;
                                                    text-align: left;
                                                    text-transform: capitalize;
                                                    width: 100%;

                                                    &.active {
                                                        background: #fff;
                                                        color: #606060;
                                                    }
                                                }
                                            }

                                            .dkg-script-subcategory {
                                                align-items: flex-start;
                                                border-bottom: 1px solid #ddd;
                                                color: #757575;
                                                display: block;
                                                flex-shrink: inherit;
                                                font-size: 15px;
                                                font-weight: 400;
                                                max-width: 100%;
                                                min-height: auto;
                                                min-width: inherit !important;
                                                opacity: 1;
                                                outline: none;
                                                overflow: visible;
                                                padding: 11px 15px;
                                                position: relative;
                                                text-align: left;
                                                text-transform: capitalize;
                                                width: 100%;

                                                &.subcategory-active {
                                                    background: #fff;
                                                    color: #606060;
                                                }
                                            }
                                        }

                                        .dk-tabs-right-tabcontent-13 {
                                            .dk-tabs-right-tabpane-13 {
                                                padding: 24px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .dk-tabs-right-tabpane-12 {
                            &.addnewClass {

                                &.activesSubTab {
                                    .dk-rightDetail-panel-13 {
                                        .dk-getStartedRight-detail-13 {
                                            display: flex;

                                            .dk-tabs-right-nav-13 {
                                                background: #eaeaea;
                                                display: inline-block;
                                                height: calc(100vh - 221px);
                                                margin-right: 0;
                                                max-width: 195px;
                                                min-width: 195px;
                                                width: 100%;

                                                .dk-tabs-right-navitem-13.nav-item {
                                                    .dk-tabs-right-navlink-13 {
                                                        align-items: flex-start;
                                                        justify-content: flex-start;
                                                        border-bottom: 1px solid #ddd;
                                                        border-left: 1px solid #ddd;
                                                        color: #757575;
                                                        display: block;
                                                        flex-shrink: inherit;
                                                        font-size: 15px;
                                                        font-weight: 400;
                                                        max-width: 100%;
                                                        min-height: auto;
                                                        min-width: inherit !important;
                                                        opacity: 1;
                                                        outline: none;
                                                        overflow: visible;
                                                        padding: 12px 9px;
                                                        position: relative;
                                                        text-align: left;
                                                        text-transform: inherit !important;
                                                        width: 100%;

                                                        &.addActiveClass {
                                                            background: #fff;
                                                            color: #606060;
                                                        }
                                                    }

                                                    // &:first-child {
                                                    //     .dk-tabs-right-navlink-13 {
                                                    //         border-top: 1px solid #ddd;
                                                    //     }
                                                    // }
                                                }
                                            }

                                            .dk-tabs-right-tabcontent-13 {
                                                width: 100%;

                                                .wrapper-class.rdw-editor-wrapper {
                                                    .rdw-editor-toolbar.toolbar-class {
                                                        margin-bottom: 0;

                                                        .rdw-inline-wrapper {
                                                            .rdw-option-wrapper {
                                                                max-width: 20px;
                                                                height: 20px;

                                                                &:nth-child(04),
                                                                &:nth-child(05),
                                                                &:nth-child(06),
                                                                &:nth-child(07) {
                                                                    display: none;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    .editor-class.rdw-editor-main {
                                                        background-color: #fff;
                                                        height: calc(100vh - 335px);
                                                        overflow-y: auto;
                                                        padding: 5px 15px;
                                                        padding-top: 0;
                                                        position: relative;
                                                        top: -5px;
                                                        // .DraftEditor-root{
                                                        //     .DraftEditor-editorContainer{
                                                        //         .public-DraftEditor-content{
                                                        //             div{
                                                        //                 div{
                                                        //                     .public-DraftStyleDefault-block{

                                                        //                     }
                                                        //                 }
                                                        //             }
                                                        //         }
                                                        //     }
                                                        // }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .dk-company-new-panel-13{
                    .dk-company-new-detail-13{
                        display: flex;
                        width: 100%;
                        .dkg-company-new-navpills{
                            background-color: #244158;
                            display: inline-block;
                            height: calc(100vh - 178px);
                            margin-right: 0;
                            width: 100%;
                            max-width: 195px;
                            min-width: 195px;
                            .dkg-company-new-navitem{
                                .dkg-company-new-navlink{
                                    background: #244158;
                                    border: none;
                                    border-bottom: 1px solid #3e5b73;
                                    border-radius: 0;
                                    color: #ddd;
                                    display: block;
                                    font-family: Roboto, sans-serif !important;
                                    font-size: 15px;
                                    font-weight: 400;
                                    padding: 11px 7px;
                                    text-transform: inherit;
                                    width: 100%;
                                    &.active{
                                        background-color: #eaeaea;
                                        border: none !important;
                                        color: #333;
                                    }
                                }
                            }
                        }
                        .dk-company-new-tabcontent-13{
                            width: 100%;
                            .dkg-company-new-tabpane{
                                background-color: #eaeaea;
                                // height: calc(100vh - 210px);
                                // border: 1px solid #ddd;
                                border: none;
                                padding: 0 30px;
                                padding-bottom: 42px;
                                // overflow-y: auto;
                                .dkg-companynew-info-mainCon{
                                    width: 100%;
                                    .dkg-jopspec-pageheader{
                                        width: 100%;
                                        .dkg-jopspec-page-title{
                                            color: #636363;
                                            font-size: 20px;
                                            font-weight: 700;
                                            text-align: center;
                                            padding: 10px;
                                            text-transform: uppercase;
                                        }
                                    }
                                    .dkg-companynew-info-con{
                                        width: 100%;
                                        height: calc(100vh - 250px);
                                        overflow-y: auto;
                                        .dkg-comany-banner-mainCon{
                                            background-color: transparent;
                                            border-radius: 5px;
                                            padding: 0;
                                            width: 100%;
                                            max-width: 100%;
                                            // margin-right: auto;
                                            .dkg-comany-banner-cols{
                                                margin: 0 ;
                                                .dkg-comany-banner-box{
                                                    padding: 0 0 0px;
                                                    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
                                                    transition: box-shadow 83ms;
                                                    background: #fff;
                                                    position: relative;
                                                    .dkg-comany-banner{
                                                        width: 100%;
                                                        overflow: hidden;
                                                        height: 360px;
                                                        background-position: center;
                                                        background-repeat: no-repeat;
                                                        background-size: cover;
                                                    }
                                                    .dkg-companynew-banner-edit{
                                                        position: absolute;
                                                        right: 10px;
                                                        top: 0;
                                                        z-index: 9;
                                                        cursor: pointer;
                                                        .dkg-banner-input{
                                                            cursor: pointer;
                                                            width: 24px;
                                                            height: 24px;
                                                            position: relative;
                                                            left: 24px;
                                                            z-index: 99;
                                                            opacity: 0;
                                                        }
                                                        svg{
                                                            width: 30px;
                                                            height: 30px;
                                                            cursor: pointer;
                                                        }
                                                    }
                                                }
                                                .dkg-cp-info-bottomcon{
                                                    margin: 0;
                                                    padding: 15px;
                                                    position: relative;
                                                    z-index: 5;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    width: 100%;
                                                    border-left: 1px solid #ddd;
                                                    border-right: 1px solid #ddd;
                                                    background-color: #eaeaea;
                                                    .dkg-cp-info-leftCon{
                                                        display: flex;
                                                        align-items: center;
                                                        .dkg-cp-info-box{
                                                            margin: -90px 0 0;
                                                            height: 130px;
                                                            width: 170px;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            background: #fff;
                                                            box-shadow: 0 0 3px #000;
                                                            border-radius: 5px;
                                                            position: relative;
                                                            .dkg-cp-info-logo{
                                                                width: 100%;
                                                                height: 100%;
                                                                background-size: cover;
                                                                overflow: hidden;
                                                                border-radius: 5px; 
                                                            }
                                                            .dkg-cp-info-edit-icon{
                                                                position: absolute;
                                                                right: 10px;
                                                                top: 5px;
                                                                cursor: pointer;
                                                                .dkg-cp-info-logo-input{
                                                                    cursor: pointer;
                                                                    width: 24px;
                                                                    height: 24px;
                                                                    position: relative;
                                                                    left: 24px;
                                                                    z-index: 99;
                                                                    opacity: 0;
                                                                }
                                                                svg{
                                                                    width: 22px;
                                                                    height: 22px;
                                                                    cursor: pointer;
                                                                }
                                                            }
                                                        }
                                                        .dkg-cp-info-nameCon{
                                                            position: relative;
                                                            .dkg-cp-info-name{
                                                                margin-left: 20px;
                                                                border-radius: 0;
                                                                text-align: left;
                                                                color: #000000;
                                                                font-weight: 600;
                                                                padding: 9px 10px;
                                                                font-size: 1.35rem;
                                                                position: relative;
                                                                top: -10px;
                                                                border: 1px solid #ddd;
                                                                background-color: #eaeaea;
                                                            }
                                                            .dkg-cp-info-title-edit{
                                                                position: absolute;
                                                                right: -25px;
                                                                top: -17px;
                                                                z-index: 9;
                                                                svg{
                                                                    width: 18px;
                                                                    height: 18px;
                                                                    cursor: pointer;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dkg-cp-info-rightCon{
                                                        .dkg-socailmedia-box{
                                                            .dkg-socailmedia-tab{
                                                                .dkg-socailmedia-img{
                                                                    width: 34px;
                                                                    height: 34px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .dkg-companynew-banner-tableCon{
                                            background-color: transparent;
                                            border-radius: 5px;
                                            padding: 0;
                                            width: 100%;
                                            max-width: 100%;
                                            .dkg-companynew-info-tablecon{
                                                background-color: #fff;
                                                border-radius: 5px;
                                                padding: 0;
                                                // padding-top: 0;
                                                // padding-left: 0;
                                                width: 100%;
                                                max-width: 100%;
                                                .dkg-companynew-info-table{
                                                    margin-bottom: 0;
                                                    // max-width: 65%;
                                                    tbody{
                                                        tr{
                                                            td{
                                                                color: #333;
                                                                background-color: #fff;
                                                                text-align: left;
                                                                padding: 5px 10px;
                                                                font-size: 1rem;
                                                                vertical-align: middle;
                                                                &:nth-child(01){
                                                                    width: 230px;
                                                                    background-color: #244158;
                                                                    color: #fff;
                                                                    border-bottom: 1px solid #a7a2a2;
                                                                }
                                                                // &:nth-child(02){
                                                                //     width: 340px;
                                                                // }
                                                                .dkg-sm-input{
                                                                    border: none;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .dkg-companynew-aboutus-Con{
                                    width: 100%;
                                    .dkg-jopspec-pageheader{
                                        width: 100%;
                                        .dkg-jopspec-page-title{
                                            color: #636363;
                                            font-size: 20px;
                                            font-weight: 700;
                                            text-align: center;
                                            padding: 10px;
                                            text-transform: uppercase;
                                        }
                                    }
                                    .dkg-instructions-editorCon{
                                        width: 100%;
                                        max-width: 1600px;
                                        margin: 0 auto;
                                        .dkg-mailbox-editor-mainCon .jodit-react-container {
                                            margin-top: 0;
                                        }
                                        .wrapper-class.rdw-editor-wrapper {
                                            .rdw-editor-toolbar.toolbar-class {
                                                margin-bottom: 0;
                                                div.rdw-image-wrapper {
                                                    position: relative;
                                
                                                    .rdw-image-modal {
                                                        position: absolute;
                                                        left: -340px;
                                                    }
                                                }
                                            }
                                
                                            .editor-class.rdw-editor-main {
                                                background-color: #fff;
                                                height: calc(100vh - 285px);
                                                overflow-y: hidden;
                                                padding: 0 10px;
                                                border: 1px solid #ddd;
                                                border-top: none;
                                            }
                                        }
                                        .dkg-mailbox-editor-mainCon{
                                            .jodit-react-container{
                                                .jodit-container{
                                                    height: calc(100vh - 330px) !important;
                                                    .jodit-toolbar__box{
                                                        .jodit-toolbar-editor-collection{
                                                            .jodit-ui-group.jodit-ui-group_size_middle{
                                                                height: 35px;
                                                            }
                                                        }
                                                    }
                                                    .jodit-workplace{
                                                        height: calc(100vh - 330px) !important;
                                                        // .jodit-wysiwyg

                                                    }
                                                }
                                            }
                                        }
                                        .dkg-submit-btnCon{
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            width: 100%;
                                            margin-top: 20px;
                                            div{
                                                div{
                                                    .dkg-cand-submit-btn{
                                                        text-align: center;
                                                        background-color: #2D96B6;
                                                        color: #fff;
                                                        &:hover{
                                                            color: #fff;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        
                                    }
                                }
                                &.dkg-company-abouts-tabpane{
                                    overflow-y: hidden;
                                }
                                &.dkg-location-tabpane{
                                    padding: 0;
                                    width: 100%;
                                    .dkg-jobspec-location-tabCon{
                                        .dk-location-panel-13{
                                            .dk-location-detail-13{
                                                .dkg-location-navpills{
                                                    width: 100%;
                                                    .dkg-location-navitem{
                                                        width: 100%;
                                                        max-width: 20%;
                                                        .dkg-location-navlink{
                                                            background-color: #fff;
                                                            color: #333;
                                                            border-radius: 0;
                                                            font-family: Roboto, sans-serif !important;
                                                            font-size: 15px;
                                                            font-weight: 400;
                                                            padding: 11px 7px;
                                                            text-transform: inherit;
                                                            width: 100%;
                                                            text-align: center;
                                                            border: 1px solid #ddd;
                                                            border-right: none;
                                                            &.active{
                                                                background-color: #eaeaea;
                                                                color: #333;
                                                                border-bottom: none;
                                                            }
                                                        }
                                                        &:last-child{
                                                            .dkg-location-navlink{
                                                                border-right: 1px solid #ddd;
                                                            }
                                                        }
                                                    }
                                                }
                                                .dk-location-tabcontent-13{
                                                    .dkg-location-tabpane{
                                                        color: inherit;
                                                        padding: 15px;
                                                        padding-right: 0;
                                                        .dkg-location-tab-block-con{
                                                            border: 1px solid #ddd;
                                                            height: calc(100vh - 250px);
                                                            padding: 15px;
                                                            background-color: #fff;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
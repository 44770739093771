.dkg-projects-summModal{
    .modal-dialog{
        min-width: 800px;
        max-width: 800px;
        .modal-content{
            border-radius: 5px ;
            background-color: transparent;
            .dkg-projects-summModalHeader{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background-color: #3c97b6;
                border-radius: 5px 5px 0 0;
                position: relative;
                padding: 7px 10px;
                .modal-title{
                    color: #fff;
                    font-size: 18px;
                }
                .close{
                    color: #fff;
                    text-shadow: none;
                    margin: 0;
                    padding: 0;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    opacity: 1;
                    &:hover{
                        color: #fff;
                        opacity: 0.8;
                    }
                }
            }
            .dkg-projects-summModalBody{
                border-radius: 0 0 5px 5px;
                background-color: #fff;
                .dkg-projects-frmgrp{
                    margin-bottom: 1rem;
                    .dkg-projects-summtextArea{
                        width: 100%;
                        padding: 7px 10px;
                        height: 220px;
                        border: 1px solid #ddd;
                        text-align: left;
                        font-size: 14px;
                        color: #555;
                        &:focus{
                            outline: 0;
                            border: 1px solid #3c97b6;
                        }

                    }
                    .dkg-projects-input{
                        border-radius: 0;
                        background-color: #fff;
                        border: 1px solid #ddd;
                        &:focus{
                            outline: 0;
                            border: 1px solid #3c97b6;
                        }
                    }
                }
                .dkg-cand-submit-btnCon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    div{
                        div{
                            .dkg-cand-submit-btn{
                                background-color: #3c97b6;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
.ddl-main {
    width: 100%;

    .ddl-btn {
        width: 100%;
        margin: 0;
        padding: 7px 0;
        background: #fff;
        box-shadow: none;
        border: 1px solid #ced4da;
        min-height: 33px;

        &::after {
            display: none;
        }
    }

    .dkg-rec-srch-drop-menu {
        width: 100%;
        padding: 0;
        max-height: 300px;
        overflow-y: auto;

        .list-unstyled {
            margin: 0;
            padding: 0;

            .dropdown-item {
                border-bottom: 1px solid #ddd;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}
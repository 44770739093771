.dk-master-moduleCon {
    position: relative;
    // height: 100vh;
    top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 110px);

    @media(max-width:992px) {
        top: 130px;
    }

    // top: 60px;


    h4.title {
        text-align: center;
        display: block;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        color: #333;
        margin-bottom: 10px;
    }


    .dk-module-blocksContainer.dk-crmContainer {
        width: 100%;
        max-width: 900px;
        margin: auto;
        margin-top: 60px;

        @media(max-width:992px) {
            padding: 0 15px;
        }

        h3.title {
            // display: done;
            // margin-bottom: 0;
            // margin-top: 0;
            // text-align: center;
            // font-size: 22px;
            // font-weight: 600;
            // color: #333;
            // position: absolute;
            // left: 38%;
            // transform: translate(-50%);
            // top: 10px;
            margin-bottom: 0;
            margin-top: 12px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #333;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            top: 10px;
            width: 100%;
        }

        h4.sbtitle {
            text-align: right;
            position: absolute;
            right: 30px;
            top: 15px;
            font-size: 22px;
            font-weight: 600;
            color: #333;
        }

        .dk-topHeadTitle {
            // position: relative;
            // padding: 10px 0 40px;
            // z-index: 9;
            padding: 16px 0 25px;
            width: 100%;
            text-align: center;
        }

        .dk-portalCols {
            width: 100%;
            display: flex;
            justify-content: center;

            .dk_portalBox_1 {
                background: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_2 {
                background: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_3 {
                background: #4c5f59;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_marketing {
                background: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_itteam {
                background: #258fb4;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_onboarding {
                background: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_4 {
                background: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_5 {
                background-color: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_6 {
                background: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_7 {
                background: #D58B5D;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_8 {
                background: #007D7F;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_9 {
                background: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_10 {
                background: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_11 {
                background: #635185;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_12 {
                background: #357655;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_13 {
                background: #564848;

                a {
                    color: #fff;
                }
            }

            .dk_portalBox_support {
                background: #D58B5D;

                a {
                    color: #fff;
                }
            }

            .dkg_clientinfo_bg {
                background-color: #5E766E;

                a {
                    color: #fff;
                }
            }

            .dk_frelancerec {
                background: #57496D;

                a {
                    color: #fff;
                }
            }

            .dk_frlancerresource {
                background-color: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_logins_bg {
                background-color: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_visainfo_bg {
                background-color: #26596d;

                a {
                    color: #fff;
                }
            }

            .dk_salestips_bg {
                background-color: #3c97b6;

                a {
                    color: #fff;
                }
            }

            .dk_recruitmentrules_bg {
                background-color: #26596d;

                a {
                    color: #fff;
                }
            }
        }

        .dk-portalBox {
            width: 100%;
            max-width: 25%;
            min-height: 125px;
            background: #eee;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px;

            div {
                display: block;
                width: 100%;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #333;
                width: 100%;
                height: 100%;

                &:hover .dk-Portalicons img {
                    transition: all 0.5s;
                    transform: scale(0.88);
                }
            }

            .dk-Portalicons {
                text-align: center;
                margin-bottom: 10px;

                img {
                    width: 100%;
                    max-width: 43px;
                    transition: all 0.5s;
                    transform: scale(1);
                }
            }

            .dk-Portalicons.PortaliconsBtn {
                button {
                    background: #f2f2f2;
                    border: none;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    border-radius: 3px;
                    margin: 0 2px;
                    outline: none;
                    color: #067185;
                }
            }

            .dk-PortalTitle {
                font-size: 17px;
                text-transform: capitalize;
                font-weight: 400;
                display: block;
                text-align: center;
            }
        }
    }
}


.dk-portalMain {

    // padding: 60px 0;
    // padding-bottom: 204px;
    // background-color: #d8d8d8;
    .enrolment_process_img_sec {
        width: 100%;
        min-height: 622px;
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    .enrolment_process_content {
        position: absolute;
        top: -115px;
        left: 0;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                width: 410px;
                position: absolute;
                list-style-type: none;

                .enrolment_icon_sec {
                    position: relative;
                    float: right;
                    margin-left: 15px;

                    img {
                        max-width: 100%;
                    }
                }

                .enrolment_icon {
                    position: absolute;
                    content: "";
                    top: 31%;
                    left: -12px;
                    right: 0;
                    margin: 0 auto;
                    display: inline-block;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    text-align: center;
                }

                &:nth-child(01) {
                    left: 605px;
                    top: 120px;

                    .enrolment_icon_sec {
                        float: left;
                        margin-right: 15px;
                        -webkit-transform: rotate(124deg);
                        transform: rotate(124deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-124deg);
                        transform: rotate(-124deg);
                    }
                }

                &:nth-child(02) {
                    left: 762px;
                    top: 310px;

                    .enrolment_icon_sec {
                        float: left;
                        margin-left: 0;
                        margin-right: 15px;
                        -webkit-transform: rotate(176deg);
                        transform: rotate(176deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-176deg);
                        transform: rotate(-176deg);
                    }
                }

                &:nth-child(03) {
                    top: 542px;
                    left: 714px;

                    .enrolment_icon_sec {
                        float: left;
                        margin-left: 0;
                        margin-right: 15px;
                        -webkit-transform: rotate(224deg);
                        transform: rotate(224deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-224deg);
                        transform: rotate(-224deg);
                    }
                }

                &:nth-child(04) {
                    text-align: center;
                    top: 634px;
                    left: 440px;
                    width: 257px;
                    text-align: center;

                    .enrolment_icon_sec {
                        position: relative;
                        float: none;
                        margin-left: 0;
                        margin-right: 15px;
                        -webkit-transform: rotate(270deg);
                        transform: rotate(270deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-270deg);
                        transform: rotate(-270deg);
                    }
                }

                &:nth-child(05) {
                    left: 5px;
                    top: 522px;
                    text-align: right;
                    text-align: right;

                    .enrolment_icon_sec {
                        -webkit-transform: rotate(-39deg);
                        transform: rotate(-39deg);
                        float: right;
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(39deg);
                        transform: rotate(39deg);
                    }
                }

                &:nth-child(06) {
                    text-align: right;
                    top: 300px;
                    left: -34px;

                    .enrolment_icon_sec {
                        -webkit-transform: rotate(7deg);
                        transform: rotate(7deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-7deg);
                        transform: rotate(-7deg);
                    }
                }

                &:nth-child(07) {
                    text-align: right;
                    top: 120px;
                    left: 105px;

                    .enrolment_icon_sec {
                        -webkit-transform: rotate(58deg);
                        transform: rotate(58deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-58deg);
                        transform: rotate(-58deg);
                        top: 36%;
                    }
                }

                .enrolment_text {
                    h4 {
                        font-size: 16px;
                        font-weight: 800;
                        text-transform: uppercase;
                        color: #003f51;
                        margin: 0 0 5px;
                        padding-top: 15px;
                    }

                    p {
                        line-height: 20px;
                        color: #333 !important;
                        margin: 0;
                        font-weight: 400;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 992px) {
    .dk-master-moduleCon {
        .dk-module-blocksContainer {
            .dk-portalCols {
                display: flex;

                @media(max-width:767px) {
                    flex-wrap: wrap;
                }

                .dk-portalBox {
                    width: 20%;
                    max-width: 20%;
                    min-width: 20%;
                    margin: 12px 15px !important;
                    min-height: 125px;

                    @media(max-width:767px) {
                        width: 100%;
                        max-width: 100%;
                        min-width: 100%;
                    }
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large  (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {}
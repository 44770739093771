.dkg-contact-mainCon{
    margin-top: 140px;
    height: calc(100vh - 140px);
    background-color: #eaeaea;
    width: 100%;
    padding: 0 15px;
    .dkg-contact-main{
        width: 100%;
        display: block;
        position: relative;
        max-width: 1600px;
        margin: 0 auto;
        .dkg-contact-mainRow{
            padding-top: 20px;
            .dkg-contact-main-sideCol{
                @media (min-width: 768px){
                    &.col-md-2 {
                        flex: 0 0 13.666667%;
                        max-width: 13.666667%;
                    }
                }

                .dkg-aboutus-navpills{
                    background-color: #e9ecef;
                    display: block;
                    width: 100%;
                    max-width: 165px;
                    .dkg-aboutus-navitem{
                        .dkg-aboutus-navlink{
                            border-radius: 0;
                            background-color: #e9ecef;
                            color: #333;
                            border: 1px solid #ddd;
                            border-bottom: none;
                            padding: 7px 10px;
                            &.active{
                                background-color: #fff;
                                color: #333;
                                position: relative;
                                &:before {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-bottom: 10px solid transparent;
                                    border-top: 10px solid transparent;
                                    border-left: 12px solid #fff;
                                    position: absolute;
                                    top: 12px;
                                    right: -11px;
                                }
                            }
                        }
                        &:last-child{
                            .dkg-aboutus-navlink{
                                &.active{
                                    border-bottom: none;
                                }
                                border-bottom: 1px solid #ddd;
                            }
                        }
                    }
                    &.dkg-paid-services-navpills{
                        .dkg-aboutus-navitem{
                            .dkg-aboutus-navlink{
                                padding: 12px 9px;
                            }
                        }
                    }
                }
            }
            .dkg-contact-mainCol{
                @media (min-width: 768px){
                    &.col-md-10 {
                        flex: 0 0 86.333333%;
                        max-width: 86.333333%;
                    }
                }
                .dkg-aboutus-tabcontent{
                    .dkg-aboutus-tabpane{
                        .dkg-aboutus-header{
                            border: 1px solid #ddd;
                            border-bottom: none;
                            padding: 9px 10px;
                            background-color: #D6DCE5;
                            display: none;
                            .dkg-contact-title{
                                width: 100%;
                                text-align: center;
                                font-weight: 600;
                                font-size: 22px;
                                text-transform: uppercase;
                                margin-bottom: 0;
                            }
                        }
                        .dkg-aboutus-mainCon{
                            background-color: #fff;
                            border: 1px solid #ddd;
                            height: calc(100vh - 190px);
                            .dkg-contact-card{
                                background-color: #243b4c;
                                background-clip: border-box;
                                border-radius: 1px;
                                border: 1px solid #243b4c;
                                width: 100%;
                                max-width: 75%;
                                margin: 0 auto;
                                margin-top: 40px;
                                .dkg-contact-cardRow{
                                    .dkg-contact-card-leftCol{
                                        .dkg-contact-card-detail{
                                            background: #fff;
                                            margin: 0 15% 0 -30%;
                                            border: 1px solid #ccc;
                                            padding: 60px 30px 70px;
                                            .dkg-card-detail-title{
                                                margin: 0 0 25px;
                                                font-size: 18px;
                                                color: #25455a;
                                                font-weight: 600;
                                            }
                                            .dkg-contact-card-ul{
                                                margin: 0;
                                                padding: 0 0 0 0px;
                                                list-style: none;
                                                .dkg-contact-card-li{
                                                    margin: 0;
                                                    padding: 0 0 20px 20px;
                                                    color: #25455a;
                                                    position: relative;
                                                    i{
                                                        position: absolute;
                                                        left: 0;
                                                        top: 5px
                                                    }
                                                    a{
                                                        color: #333;
                                                    }
                                                }
                                            }
                                            .dkg-contact_social{
                                                padding-left: 22px;
                                                a{
                                                    padding-right: 8px;
                                                    img{
                                                        width: 35px;
                                                        height: 35px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .dkg-contact-card-rightCol{
                                        color: #25455a;
                                        form{
                                            padding: 30px;
                                            .row{
                                                div{
                                                    h2{
                                                        margin: 0 0 15px;
                                                        font-size: 18px;
                                                        color: #fff;
                                                    }
                                                }
                                                .form-group{
                                                    margin-bottom: 1rem;
                                                    .form-control{
                                                        height: 34px;
                                                        padding: 0.1rem 0.5rem;
                                                        font-size: 14px;
                                                        background-color: #fff;
                                                        line-height: 1.3;
                                                        border: #fff;
                                                        text-align: left;
                                                    }
                                                    textarea.form-control {
                                                        height: 80px;
                                                        text-align: left;
                                                    }
                                                }

                                            }
                                            p{
                                                margin-bottom: 1rem;
                                                color: #ddd;
                                                font-size: 14px;
                                                input[type="checkbox"] {
                                                    cursor: pointer;
                                                    appearance: none;
                                                    background: #fff;
                                                    border-radius: 0.25rem;
                                                    box-sizing: border-box;
                                                    position: relative;
                                                    box-sizing: content-box;
                                                    width: 12px;
                                                    height: 12px;
                                                    border-width: 0;
                                                    transition: all 0.3s linear;
                                                    border: solid #ccc 1px;
                                                    margin-right: 0.5rem;
                                                }
                                                a{
                                                    color: rgb(93, 157, 226);
                                                    margin: 0 0.5rem;
                                                }
                                            }
                                            .btn-light {
                                                color: #212529;
                                                background-color: #f8f9fa;
                                                border-color: #f8f9fa;
                                                border: 1px solid transparent;
                                                padding: 0.375rem 0.75rem;
                                                font-size: 1rem;
                                                line-height: 1.5;
                                                border-radius: 0.25rem;
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-cand-services-Side{
                                .dkg-payment-qt-tab-mainCon{
                                    .dkg-payment-qt-tabCon{
                                        &.dkg-profile-task-sideMenu{
                                            .dkg-payment-qt-tab-navpills{
                                                background: #eaeaea;
                                                display: inline-block;
                                                height: calc(100vh - 192px);
                                                margin-right: 0;
                                                // max-width: 192px;
                                                // min-width: 192px;
                                                width: 100%;
                                                .dkg-payment-qt-tab-nav-item{
                                                    .dkg-payment-qt-tab-navlink{
                                                        align-items: flex-start;
                                                        border-bottom: 1px solid #ddd;
                                                        color: #757575;
                                                        display: block;
                                                        flex-shrink: inherit;
                                                        font-size: 15px;
                                                        font-weight: 400;
                                                        justify-content: flex-start;
                                                        max-width: 100%;
                                                        min-height: auto;
                                                        min-width: inherit!important;
                                                        opacity: 1;
                                                        outline: none;
                                                        overflow: visible;
                                                        padding: 12px 9px;
                                                        position: relative;
                                                        text-align: left;
                                                        text-transform: inherit!important;
                                                        width: 100%;
                                                        &.active{
                                                            background: #fff;
                                                            color: #606060;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .dkg-paidservices-rightCon{
                                        .dkg-paidservices-tabcontent{
                                            .dkg-paidservices-tabpane{
                                                padding: 0;
                                                .dkg-paidservices-header{
                                                    border: 1px solid #ddd;
                                                    border-bottom: none;
                                                    padding: 12px 10px;
                                                    background-color: #D6DCE5;
                                                    display: none;

                                                    .dkg-paidservices-title{
                                                        width: 100%;
                                                        text-align: center;
                                                        font-weight: 600;
                                                        font-size: 18px;
                                                        text-transform: uppercase;
                                                        margin-bottom: 0;
                                                    }
                                                }
                                                .dkg-paidservices-body{
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    height: calc(100vh - 205px);
                                                    flex-direction: column;
                                                    width: 100%;
                                                    border: none;
                                                    &.dkg-desiredjob-modalbody{
                                                        justify-content: flex-start;
                                                    }
                                                    .dkg-cand-dash-input{
                                                        display: block;
                                                        background-color: #fff;
                                                        border: 1px solid #ddd;
                                                        width: 100%;
                                                        max-width: 96%;
                                                        margin: 0 auto;
                                                        padding: 7px 10px;
                                                        min-height: 65px;                                                  
                                                    }
                                                    .dkg-cand-submit-btnCon{
                                                        margin-top: 40px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        width: 100%;
                                                        div{
                                                            div{
                                                                .dkg-cand-submit-btn{
                                                                    background: #2d96b6;
                                                                    border: none;
                                                                    border-radius: 5px;
                                                                    color: #fff;
                                                                    display: inline-block;
                                                                    font-weight: 500;
                                                                    height: auto;
                                                                    min-height: auto;
                                                                    outline: none;
                                                                    padding: 9px 18px;
                                                                    text-decoration: none;
                                                                    text-transform: uppercase;
                                                                    font-size: 1.25rem;
                                                                    min-width: 90px;
                                                                    &:hover{
                                                                        background: #325f7d;
                                                                        transition: all .5s;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dkg-paidservice-faq-mainCon{
                                                        width: 100%;
                                                        margin-top: 4rem;
                                                    }
                                                    &.dkg-admin-setting-body{
                                                        display: block;
                                                        height: calc(100vh - 220px);
                                                        overflow-y: auto;
                                                        .dkg-candidate-Row{
                                                            width: 100%;
                                                            .dkg-candidate-leftCon,
                                                            .dkg-candidate-rightCon{
                                                                .dkg-cand-form-group{
                                                                    .dkg-candidate-inputgrp{
                                                                        .dkg-inputgrp-prepared{
                                                                            .dkg-inputgrp-text{
                                                                                min-width: 140px;
                                                                                max-width: 140px;
                                                                                font-size: 13px;
                                                                                text-transform: capitalize;
                                                                                border-right: none;
                                                                            }
                                                                        }
                                                                        .dkg-cand-frmcontrol{
                                                                            font-size: 13px;
                                                                            text-align: left;
                                                                            border: 1px solid #ddd;
                                                                            height: 34px;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .dkg-instructions-editorCon{
                                                            width: 100%;
                                                            .wrapper-class.rdw-editor-wrapper {
                                                                .rdw-editor-toolbar.toolbar-class {
                                                                    margin-bottom: 0;
                                                                    div.rdw-image-wrapper {
                                                                        position: relative;

                                                                        .rdw-image-modal {
                                                                            position: absolute;
                                                                            left: -340px;
                                                                        }
                                                                    }
                                                                }

                                                                .editor-class.rdw-editor-main {
                                                                    background-color: #fff;
                                                                    height: calc(85vh - 255px);
                                                                    overflow-y: hidden;
                                                                    padding: 0 10px;
                                                                    border: 1px solid #ddd;
                                                                    border-top: none;
                                                                }
                                                            }
                                                            .dkg-submit-btnCon{
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                width: 100%;
                                                                margin-top: 20px;
                                                                div{
                                                                    div{
                                                                        .dkg-cand-submit-btn{
                                                                            text-align: center;
                                                                            background-color: #2D96B6;
                                                                            color: #fff;
                                                                            &:hover{
                                                                                color: #fff;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                        }
                                                    }
                                                    &.dkg-admin-setting-prjbody{
                                                        display: block;
                                                        height: calc(100vh - 225px);
                                                        overflow-y: auto;
                                                        .dkg-visitwebaite-cardCon {
                                                            width: 100%;
                                                            .dk-otherproject-cols {
                                                                width: 100%;
                                                                // max-width: 1100px;
                                                                margin: auto;
                                                                display: flex;
                                                                justify-content: center;
                                                                margin: 20px auto;

                                                                &.dkg-top-level-boxCon {
                                                                    max-width: 366px;
                                                                    margin-bottom: 30px;
                                                                }

                                                                .dk-otherproject-box {
                                                                    display: inline-block;
                                                                    margin: 0 10px;
                                                                    width: 100%;
                                                                    .dkg-otherprj-boxcols{
                                                                        padding: 20px;
                                                                        background: #eaeaea;
                                                                        border-radius: 8px 8px 0 0;
                                                                        min-height: 140px;
                                                                        display: flex;
                                                                        align-items: center;
                                                                        justify-content: center;
                                                                        .dk-FileUploadCon {
                                                                            display: flex;
                                                                            flex-direction: column;
                                                                            justify-content: center;
                                                                            align-items: center;

                                                                            .dk-fileUldimgCon {
                                                                                width: 250px;
                                                                                max-width: 250px;
                                                                                height: 140px;
                                                                                margin: 0 auto;
                                                                                border: 3px dashed lightslategrey;
                                                                                display: flex;
                                                                                justify-content: center;
                                                                                align-items: center;

                                                                                h3 {
                                                                                    text-transform: uppercase;
                                                                                    color: #324148;
                                                                                    padding: 40px 0;
                                                                                    font-size: 1.4rem;
                                                                                    font-weight: 400;
                                                                                }
                                                                                figure#edit_image{
                                                                                    margin: 1rem 1.25rem;
                                                                                }
                                                                            }
                                                                        }
                                                                        .dk-browsedFile {
                                                                            position: relative;

                                                                            input {
                                                                                position: absolute;
                                                                                bottom: 0;
                                                                                z-index: 9;
                                                                                cursor: pointer;
                                                                                width: 100%;
                                                                                height: 41px;
                                                                                opacity: 0;
                                                                            }

                                                                            button {
                                                                                cursor: pointer;
                                                                                position: relative;
                                                                                z-index: 1;
                                                                            }
                                                                        }

                                                                        .dk-uploadView-img {
                                                                            display: flex;
                                                                            justify-content: center;
                                                                            width: 100%;
                                                                            margin: 15px 0 0;
                                                                            background: #fff;
                                                                            border-radius: 5px;
                                                                            padding: 10px;
                                                                            position: relative;

                                                                            img {
                                                                                width: 100%;
                                                                                max-width: 160px;
                                                                                margin: auto;
                                                                            }

                                                                            span.close-btn {
                                                                                display: inline-block;
                                                                                width: 15px;
                                                                                height: 15px;
                                                                                border-radius: 50%;
                                                                                background: #000;
                                                                                color: #fff;
                                                                                line-height: 15px;
                                                                                text-align: center;
                                                                                font-size: 10px;
                                                                                position: absolute;
                                                                                top: -3px;
                                                                                right: -3px;
                                                                                cursor: pointer;
                                                                                svg{
                                                                                    position: relative;
                                                                                    top: -2px;
                                                                                }
                                                                            }
                                                                        }

                                                                        & img {
                                                                            width: 100%;
                                                                            height: auto;
                                                                        }

                                                                        & button {
                                                                            margin-top: 20px;
                                                                            width: 250px;
                                                                            background-color: #ddd;
                                                                            color: #fff;
                                                                            background: #139BD6;
                                                                            border: none;
                                                                            padding: 10px;
                                                                            border-radius: 4px;
                                                                            transition: all .2s ease;
                                                                            outline: none;
                                                                            text-transform: uppercase;
                                                                            font-weight: 700;
                                                                            position: relative;

                                                                            input {
                                                                                opacity: 0;
                                                                                position: absolute;
                                                                                left: 0;
                                                                                top: 0;
                                                                                width: 100%;
                                                                                height: 35px;
                                                                                cursor: pointer;
                                                                            }
                                                                        }

                                                                        & button:hover {
                                                                            background: #165a77;
                                                                            color: #ffffff;
                                                                            transition: all .2s ease;
                                                                            cursor: pointer;
                                                                        }
                                                                        .title {
                                                                            font-size: 14px;
                                                                            text-transform: uppercase;
                                                                            color: #26596d;
                                                                            font-weight: 600;
                                                                            text-align: center;
                                                                            display: block;
                                                                            margin-top: 10px;
                                                                        }
                                                                    }
                                                                    .dkg-visit-prjbtn{
                                                                        width: 100%;
                                                                        border-top: 1px solid #ddd;
                                                                        padding: 7px 10px;
                                                                        display: block;
                                                                        background-color: #f2f2f2;
                                                                        color: #333;
                                                                        text-transform: capitalize;
                                                                        border-radius: 0 0 8px 8px;
                                                                        margin: 0;
                                                                        box-shadow: none;
                                                                        font-size: 14px;
                                                                        .dkg-resume-icon {
                                                                            margin-left: 0.5rem;
                                                                        }
                                                                        &:hover{
                                                                            background-color: #eaeaea;
                                                                            transition: all 0.5s;
                                                                        }
                                                                    }
                                                                    .dkg-porject-nameCon{
                                                                        width: 100%;
                                                                        border-top: 1px solid #ddd;
                                                                        border-bottom: 1px solid #ddd;
                                                                        padding: 0;
                                                                        display: block;
                                                                        background-color: #f2f2f2;
                                                                        input.dkg-project-name{
                                                                            border: none;
                                                                            outline: 0;
                                                                            border-radius: 0;
                                                                            width: 100%;
                                                                            padding: 7px 10px;
                                                                            text-align: left;
                                                                            background-color: transparent;
                                                                            text-transform: capitalize;
                                                                            font-size: 1.1rem;
                                                                            font-weight: 500;
                                                                            &:focus{
                                                                                outline: 0;
                                                                                box-shadow: none;
                                                                            }
                                                                        }
                                                                    }
                                                                    .dkg-porject-info-progressbarCon{
                                                                        display: flex;
                                                                        align-items: center;
                                                                        justify-content: space-between;
                                                                        width: 100%;
                                                                        border-top: 1px solid #ddd;
                                                                        padding: 0;
                                                                        background-color: #f2f2f2;
                                                                        color: #333;
                                                                        .dkg-info-icon-leftCon{
                                                                            width: 50%;
                                                                            padding: 7px 10px;
                                                                            text-align: center;
                                                                            cursor: pointer;
                                                                            color: #555;
                                                                            &:hover{
                                                                                box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
                                                                                background-color: #eaeaea;
                                                                                color: #3c97b6;
                                                                            }
                                                                        }
                                                                        .dkg-progress-barCon{
                                                                            width: 100%;
                                                                        }
                                                                        .dkg-project-rightCon{
                                                                            width: 50%;
                                                                            padding: 7px 10px;
                                                                            text-align: center;
                                                                            cursor: pointer;
                                                                            color: #555;
                                                                            &:hover{
                                                                                box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
                                                                                background-color: #eaeaea;
                                                                                color: #3c97b6;
                                                                            }
                                                                        }
                                                                    }
                                                                    .dkg-project-btnCon{
                                                                        display: flex;
                                                                        align-items: center;
                                                                        justify-content: space-between;
                                                                        width: 100%;
                                                                        background-color: #f2f2f2;
                                                                        border: none;
                                                                        .dkg-upload-prjbtn-Con{
                                                                            width: 33.333%;
                                                                            border-right: 1px solid #808080;
                                                                            .dkg-upload-prjbtn{
                                                                                width: 100%;
                                                                                background-color: #139BD6;
                                                                                color: #fff;
                                                                                border: none;
                                                                                padding: 10px 2px;
                                                                                transition: all .2s ease;
                                                                                outline: none;
                                                                                text-transform: uppercase;
                                                                                font-weight: 700;
                                                                                position: relative;
                                                                                border-bottom-left-radius: 8px;
                                                                                input {
                                                                                    opacity: 0;
                                                                                    position: absolute;
                                                                                    left: 0;
                                                                                    top: 0;
                                                                                    width: 100%;
                                                                                    height: 35px;
                                                                                    cursor: pointer;
                                                                                }
                                                                                &:hover {
                                                                                    background: #165a77;
                                                                                    color: #ffffff;
                                                                                    transition: all .2s ease;
                                                                                    cursor: pointer;
                                                                                }
                                                                            }
                                                                        }
                                                                        .dkg-url-prjbtn-Con{
                                                                            width: 33.333%;
                                                                            .dkg-url-prjbtn{
                                                                                width: 100%;
                                                                                background-color: #FECA3E;
                                                                                color: #333;
                                                                                border: none;
                                                                                padding: 10px 2px;
                                                                                transition: all .2s ease;
                                                                                outline: none;
                                                                                text-transform: uppercase;
                                                                                font-weight: 700;
                                                                                position: relative;                                                                            }
                                                                        }
                                                                        .dkg-cancel-prjbtn-Con{
                                                                            width: 33.333%;
                                                                            .dkg-cancel-prjbtn{
                                                                                width: 100%;
                                                                                background-color: #E2445B;
                                                                                color: #fff;
                                                                                padding: 10px 2px;
                                                                                transition: all .2s ease;
                                                                                outline: none;
                                                                                text-transform: uppercase;
                                                                                font-weight: 700;
                                                                                position: relative;
                                                                                border: none;
                                                                               border-bottom-right-radius: 8px;
                                                                                &:hover {
                                                                                    background: #165a77;
                                                                                    color: #ffffff;
                                                                                    transition: all .2s ease;
                                                                                    cursor: pointer;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    &.dkg-setting-sendus-body{
                                                        display: block;
                                                        height: calc(100vh - 220px);
                                                        overflow-y: auto;
                                                        .dkg-candidate-Row{
                                                            width: 100%;
                                                            max-width: 50%;
                                                            margin: 100px auto;
                                                            .dkg-candidate-leftCon,
                                                            .dkg-candidate-rightCon{
                                                                .dkg-cand-form-group{
                                                                    .dkg-candidate-inputgrp{
                                                                        .dkg-inputgrp-prepared{
                                                                            .dkg-inputgrp-text{
                                                                                min-width: 40px;
                                                                                max-width: 40px;
                                                                                font-size: 13px;
                                                                                text-transform: capitalize;
                                                                                border-right: none;
                                                                            }
                                                                        }
                                                                        .dkg-cand-frmcontrol{
                                                                            font-size: 13px;
                                                                            text-align: left;
                                                                            border: 1px solid #ddd;
                                                                            height: 34px;
                                                                        }
                                                                    }
                                                                    .dkg-sendus-btnCon{
                                                                        display: flex;
                                                                        align-items: center;
                                                                        justify-content: center;
                                                                        width: 100%;
                                                                        margin-top: 20px;
                                                                        div{
                                                                            div{
                                                                                .dkg-sendus-btn{
                                                                                    text-align: center;
                                                                                    background-color: #2D96B6;
                                                                                    color: #fff;
                                                                                    &:hover{
                                                                                        color: #fff;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                    }
                                                    &.dkg-cn-notification-body{
                                                        display: block;
                                                        .dkg-cn-noti-tableCon{
                                                            width: 100%;
                                                            .dkg-cn-noti-table{
                                                                margin-bottom: 0;
                                                                thead{
                                                                    tr{
                                                                        th{
                                                                            background-color: #f2f2f2;
                                                                            font-weight: 600;
                                                                            font-size: 1.25rem;
                                                                            text-transform: uppercase;
                                                                        }
                                                                    }
                                                                }
                                                                tbody{
                                                                    tr{
                                                                        td{
                                                                            vertical-align: middle;
                                                                            padding: 0;
                                                                            height: 130px;
                                                                            padding: 0;
                                                                            .dkg-noti-title-frmcontrol{
                                                                                border: 1px solid #ddd;
                                                                                border-left: none;
                                                                                border-right: none;
                                                                                border-radius: 0;
                                                                                height: 43px;
                                                                                font-weight: 600;
                                                                                text-align: left;
                                                                            }
                                                                            .dkg-noti-subtitle-cols{
                                                                                .dkg-noti-subtitle-frmcontrol{
                                                                                    border:none;
                                                                                    border-bottom: 1px solid #ddd;
                                                                                    height: 43px;
                                                                                    text-align: left;
                                                                                    // &:nth-child(02){
                                                                                    //     border-top: 1px solid #ddd;
                                                                                    //     border-bottom: none;
                                                                                    // }
                                                                                    &:last-child{
                                                                                        border-bottom: none;
                                                                                    }
                                                                                }
                                                                            }
                                                                            &:nth-child(01){
                                                                                width: 30%;
                                                                            }
                                                                            &:nth-child(02){
                                                                                width: 70%;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dkg-desired-job-centersec{
                                                        width: 100%;
                                                        display: flex;
                                                        .dkg-desired-job-leftCon{
                                                            width: 50%;
                                                            border-right: 1px solid #ddd;
                                                            height: calc(100vh - 329px);
                                                            .dkg-desired-job-titleCon{
                                                                padding: 65px 30px 10px 30px;
                                                                .dkg-desired-job-title{
                                                                    font-weight: 600;
                                                                    font-size: 19px;
                                                                    text-transform: uppercase;
                                                                    margin-bottom: 0;
                                                                    color: #333;
                                                                    text-transform: capitalize;
                                                                }
                                                            }
                                                            .dkg-selected-tagCon{
                                                                .dkg-selected-title{
                                                                    color: #333;
                                                                    display: block;
                                                                    font-size: 1.15rem;
                                                                    padding: 7px 10px;
                                                                    position: relative;
                                                                    width: 100%;
                                                                    font-weight: 600;
                                                                    text-align: center;
                                                                    border-bottom: 1px solid #ddd;
                                                                }
                                                                .dkg-st-tag-job-btn-tagCon{
                                                                    align-items: flex-start;
                                                                    display: flex;
                                                                    justify-content: flex-start;
                                                                    width: 100%;
                                                                    flex-wrap: wrap;
                                                                    padding: 5px 7px;
                                                                    .dkg-st-tag-btntext{
                                                                        display: inline-block;
                                                                        border-radius: 12px;
                                                                        color: #445;
                                                                        font-size: 13px!important;
                                                                        padding: 6px 22px;
                                                                        text-transform: capitalize;
                                                                        background-color: #f2f2f2 !important;
                                                                        border: 1px solid #ddd;
                                                                        box-shadow: none;
                                                                        position: relative;
                                                                        margin-top: 8px;
                                                                            .dkg-tag-icon {
                                                                                color: #445;
                                                                                cursor: pointer;
                                                                                display: none;
                                                                                margin-left: 5px;
                                                                                padding: 0;
                                                                                position: absolute;
                                                                                right: 5px;
                                                                                top: 6px;
                                                                            }
                                                                            &:hover{
                                                                                .dkg-tag-icon{
                                                                                    display: block;
                                                                                    i{
                                                                                        color: #445;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .dkg-desired-job-rightCon{
                                                            width: 100%;
                                                            height: calc(100vh - 328px);
                                                            .dkg-dj-rightfirst-cols{
                                                                border-bottom: 1px solid #ddd;
                                                                height: calc(100vh - 348px);
                                                                .dkg-saved-cv-tag-title{
                                                                    color: #333;
                                                                    display: block;
                                                                    font-size: 1.15rem;
                                                                    padding: 12px 10px;
                                                                    position: relative;
                                                                    width: 100%;
                                                                    font-weight: 600;
                                                                    text-align: center;
                                                                    border-bottom: 1px solid #ddd;
                                                                }
                                                                .dkg-desired-job-btn-tagCon{
                                                                    display: flex;
                                                                    justify-content: flex-start;
                                                                    align-items: flex-start;
                                                                    width: 100%;
                                                                    flex-wrap: wrap;
                                                                    padding: 5px 7px;
                                                                    .dkg-desired-btntext{ 
                                                                    display: inline-block;
                                                                    border-radius: 12px;
                                                                    color: #fff;
                                                                    font-size: 13px;
                                                                    padding: 6px 22px;
                                                                    text-transform: capitalize;
                                                                    background-color: #366995 !important;
                                                                    box-shadow: none;
                                                                    position: relative;
                                                                    margin-top: 8px;
                                                                    .dkg-tag-icon {
                                                                        color: #fff;
                                                                        cursor: pointer;
                                                                        display: none;
                                                                        margin-left: 5px;
                                                                        padding: 0;
                                                                        position: absolute;
                                                                        right: 5px;
                                                                        top: 6px;
                                                                    }
                                                                    &:hover{
                                                                        .dkg-tag-icon{
                                                                            display: block;
                                                                            i{
                                                                                color: #fff;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            
                                                            
                                                                 }
                                                            }
                                                            .dkg-dj-rightsecond-cols{
                                                                width: 100%;
                                                                background-color: #f2f2f2;
                                                                display: flex;
                                                                align-items: center;
                                                                flex-direction: column;
                                                                justify-content: center;
                                                                padding: 22px;
                                                                .dkg-desired-job-inputCon{
                                                                    width: 100%;
                                                                    .dkg-createnew-tag-title{
                                                                        color: #333;
                                                                        display: block;
                                                                        font-size: 1.15rem;
                                                                        padding: 7px 10px;
                                                                        position: relative;
                                                                        width: 100%;
                                                                        font-weight: 600;
                                                                        text-transform: uppercase;
                                                                        text-align: center;
                                                                    }                                    
                                                                    
                                                                    .dkg-desired-job-inputCon{
                                                                        display: flex;
                                                                        align-items: center;
                                                                        justify-content: center;
                                                                        flex-direction: column;
                                                                        // height: 90%;
                                                                        .dkg-desired-job-input{
                                                                            border: 1px solid #ddd;
                                                                            background-color: #fff;
                                                                            width: 100%;
                                                                            max-width: 90%;
                                                                            text-align: left;
                                                                            margin-left: 10px;
                            
                                                                        }
                                                                        .dkg-desired-job-save-btnCon{
                                                                            margin-top: 10px;
                                                                            display: flex;
                                                                            align-items: center;
                                                                            justify-content: center;
                                                                            div{
                                                                                div{
                                                                                    .dkg-desired-job-save-btn{
                                                                                        background-color: #26597d;
                                                                                        // text-transform: capitalize;
                                                                                        color: #fff;
                                                                                        &:hover{
                                                                                            color: #fff;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            
                                                    }
                                                }
                                                .dkg-paidservices-tablebody{
                                                    .dkg-principale-tableCon {
                                                        height: calc(100vh - 220px);
                                                        overflow-y: auto;

                                                        .dkg-principale-table {
                                                            tbody {
                                                                tr {
                                                                    td {
                                                                        font-size: 16px;
                                                                        text-transform: uppercase;
                                                                        font-weight: 700;
                                                                        text-align: center;
                                                                        padding: 8px 10px;
                                                                        height: 85px;
                                                                        line-height: 75px;
                                                                        background-color: #f2f2f2;
                                                                        border: 1px solid #ddd;
                                                                        vertical-align: middle;

                                                                        input.form-control {
                                                                            border: none;
                                                                            background-color: #f2f2f2;

                                                                            &::placeholder {
                                                                                color: #343a40;
                                                                            }

                                                                            &:focus {
                                                                                box-shadow: none;
                                                                                outline: 0;
                                                                            }
                                                                        }
                                                                        .txta {
                                                                            width: 100%;
                                                                            font-family: Arial, sans-serif;
                                                                            font-size: 14px;
                                                                            overflow: hidden;
                                                                            line-height: 1.4;
                                                                            background-color: transparent;
                                                                            border: none;
                                                                            vertical-align: middle;
                                                                            padding-top: 27px;
                                                                          }
                                                                          .dkg-cand-submit-btnCon{
                                                                            display: flex;
                                                                            align-items: center;
                                                                            justify-content: center;
                                                                            margin-top: 0;
                                                                            div{
                                                                                div{
                                                                                    .dkg-cand-submit-btn{
                                                                                        background: #2d96b6;
                                                                                        border: none;
                                                                                        border-radius: 5px;
                                                                                        color: #fff;
                                                                                        display: inline-block;
                                                                                        font-weight: 500;
                                                                                        height: auto;
                                                                                        min-height: auto;
                                                                                        outline: none;
                                                                                        padding: 5px 7px;
                                                                                        text-decoration: none;
                                                                                        text-transform: uppercase;
                                                                                        font-size: 1rem;
                                                                                        min-width: 75px;
                                                                                        &:hover{
                                                                                            background: #325f7d;
                                                                                            transition: all .5s;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &:nth-child(01),
                                                                        &:nth-child(05) {
                                                                            width: 12%;
                                                                        }

                                                                        &:nth-child(02) {
                                                                            text-align: left;
                                                                        }

                                                                        &:nth-child(03) {

                                                                            width: 45%;
                                                                            vertical-align: middle;

                                                                            input {
                                                                                display: flex;
                                                                                flex-wrap: wrap;
                                                                                align-items: flex-start;
                                                                                font-size: 13px;
                                                                                margin-bottom: 0;
                                                                                font-weight: normal;
                                                                                text-transform: math-auto;
                                                                                font-family: 'Roboto';
                                                                                font-weight: normal;
                                                                                color: #333;

                                                                            }

                                                                        }

                                                                        .dkg-uplaod-imageCon {
                                                                            align-items: center;
                                                                            display: flex;
                                                                            margin-bottom: 0;

                                                                            .d-flex {
                                                                                .dk-edit-userImgCon {
                                                                                    height: 95px;
                                                                                    position: relative;

                                                                                    input {
                                                                                        cursor: pointer;
                                                                                        height: 95px;
                                                                                        opacity: 0;
                                                                                        position: relative;
                                                                                        width: 100%;
                                                                                        z-index: 9;
                                                                                    }

                                                                                    .dk-myprofile-userImgCon {
                                                                                        border-radius: 50%;
                                                                                        cursor: pointer;
                                                                                        left: 3px;
                                                                                        max-width: 85px;
                                                                                        position: absolute;
                                                                                        top: 7px;
                                                                                        width: 100%;
                                                                                        object-fit: scale-down;
                                                                                        figure {
                                                                                            margin: 0;

                                                                                            .dkg-changIcon {
                                                                                                width: 55px;
                                                                                                height: 55px;
                                                                                                // object-fit: cover;
                                                                                                // object-fit: contain;
                                                                                                object-fit: scale-down;

                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    .dk-myprofile-editicon {
                                                                                        background-color: #234158;
                                                                                        border-radius: 50%;
                                                                                        bottom: 25px;
                                                                                        width: 27px;
                                                                                        height: 27px;
                                                                                        display: flex;
                                                                                        justify-content: center;
                                                                                        align-items: center;
                                                                                        position: absolute;
                                                                                        right: 15px;
                                                                                        top: 37px;

                                                                                        svg {
                                                                                            width: 14px;
                                                                                            height: 14px;
                                                                                            color: #fff;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .dkg-edit-cand-services-Con{
                                                    height: calc(100vh - 193px);
                                                    overflow-y: auto;
                                                    .dkg-cn-service-Row{
                                                        margin: 0;
                                                        .dkg-cn-service-leftCon{
                                                            padding: 0;
                                                            .dkg-cand-form-group{
                                                                margin-bottom: 0;
                                                                border-bottom: 1px solid #ddd;
                                                                .dkg-cn-service-inputgrp{
                                                                    .dkg-service-inputgrp-prepared{
                                                                        .dkg-service-inputgrp-text{
                                                                            min-width: 50px;
                                                                            max-width: 50px;
                                                                            font-size: 13px;
                                                                            border: none;
                                                                            padding: 0;
                                                                            background-color: #f2f2f2;
                                                                            .dkg-service-inputgrp-frmcontrol{
                                                                                font-size: 13px;
                                                                                text-align: center;
                                                                                // height: 44px;
                                                                                border: none;
                                                                                background-color: transparent;
                                                                                width: 100%;
                                                                                height: 44px;
                                                                                padding: 0 15px;
                                                                            }
                                                                        }
                                                                    }
                                                                    .dkg-cand-frmcontrol{
                                                                        font-size: 13px;
                                                                        text-align: left;
                                                                        // border: 1px solid #ddd;
                                                                        height: 44px;
                                                                       border:none
                                                                    }
                                                                    .dkg-cn-service-btnCon{
                                                                        background-color: #E9ECEF;
                                                                       div{
                                                                        div{
                                                                            .dkg-cand-submit-btn{
                                                                                box-shadow: none;
                                                                                background-color: transparent;
                                                                                line-height: 32px;
                                                                            }
                                                                        }
                                                                       }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .dkg-banner-body{
                                                    width: 100%;
                                                    .dkaddNewRightCon {
                                                        // height: calc(100vh - 100px);
                                                        // background: #D9DFE7;
                                                        border-radius: 5px;
                                                        padding: 15px 0;
                                                        margin-top: 0;
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        width: 100%;
                                                        .addNewBox {
                                                            width: 100%;
                                                            .dk-FileUploadCon {
                                                                display: flex;
                                                                flex-direction: column;
                                                                justify-content: center;
                                                                align-items: center;
                                                                 width: 100%;
                                                                .dk-fileUldimgCon {
                                                                    width: 100%;
                                                                    max-width: 100%;
                                                                    height: 250px;
                                                                    margin: 0 auto;
                                                                    border: 1px solid  #ddd;
                                                                    display: flex;
                                                                    justify-content: center;
                                                                    align-items: center;
                                    
                                                                    h3 {
                                                                        text-transform: uppercase;
                                                                        color: #324148;
                                                                        padding: 40px 0;
                                                                        font-size: 1.4rem;
                                                                        font-weight: 400;
                                                                    }
                                                                }
                                                            }
                                                            .dk-browsedFile {
                                                                position: relative;
                                                                width: 100%;
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                border: 1px solid #ddd;
                                                                border-top: none;
                                                                padding: 0.65rem 0.75rem;
                                                                input {
                                                                    position: absolute;
                                                                    bottom: 0;
                                                                    z-index: 9;
                                                                    cursor: pointer;
                                                                    width: 100%;
                                                                    height: 41px;
                                                                    opacity: 0;
                                                                }
                                                        
                                                                button {
                                                                    cursor: pointer;
                                                                    position: relative;
                                                                    z-index: 1;
                                                                    margin-top: 0;
                                                                }
                                                            }
                                                        
                                                            .dk-uploadView-img {
                                                                display: flex;
                                                                justify-content: center;
                                                                width: 100%;
                                                                margin: 15px 0 0;
                                                                background: #fff;
                                                                border-radius: 5px;
                                                                padding: 10px;
                                                                position: relative;
                                                        
                                                                img {
                                                                    width: 100%;
                                                                    max-width: 160px;
                                                                    margin: auto;
                                                                }
                                                        
                                                                span.close-btn {
                                                                    display: inline-block;
                                                                    width: 15px;
                                                                    height: 15px;
                                                                    border-radius: 50%;
                                                                    background: #000;
                                                                    color: #fff;
                                                                    line-height: 15px;
                                                                    text-align: center;
                                                                    font-size: 10px;
                                                                    position: absolute;
                                                                    top: -3px;
                                                                    right: -3px;
                                                                    cursor: pointer;
                                                                    svg{
                                                                        position: relative;
                                                                        top: -2px;
                                                                    }
                                                                }
                                                            }
                                                        
                                                            & img {
                                                                width: 100%;
                                                                height: auto;
                                                            }
                                                        
                                                            & button {
                                                                margin-top: 20px;
                                                                width: 250px;
                                                                background-color: #ddd;
                                                                color: #fff;
                                                                background: #139BD6;
                                                                border: none;
                                                                padding: 10px;
                                                                border-radius: 4px;
                                                                transition: all .2s ease;
                                                                outline: none;
                                                                text-transform: uppercase;
                                                                font-weight: 700;
                                                                position: relative;
                                                        
                                                                input {
                                                                    opacity: 0;
                                                                    position: absolute;
                                                                    left: 0;
                                                                    top: 0;
                                                                    width: 100%;
                                                                    height: 35px;
                                                                    cursor: pointer;
                                                                }
                                                            }
                                                        
                                                            & button:hover {
                                                                background: #165a77;
                                                                color: #ffffff;
                                                                transition: all .2s ease;
                                                                cursor: pointer;
                                                            }
                                                        }
                                                    }
                                                    .dkg-livejob-titleCon{
                                                        .dkg-livejob-inputCon{
                                                            display: flex;
                                                            align-items: center;
                                                            .dkg-jobtitle-label{
                                                                border: 1px solid #ccc;
                                                                border-right: none;
                                                                background-color: #ddd;
                                                                height: 37px;
                                                                line-height: 37px;
                                                                width: 90px;
                                                                margin-bottom: 0;
                                                                text-align: center;
                                                                font-size: 1rem;
                                                            }
                                                            .dkg-livejobs-input{
                                                                background-color: #fff;
                                                                border-radius: 0;
                                                                height: calc(1.5em + 0.8rem + 5px);
                                                            }
                                                        }
                                                    }
                                                    .dkg-livejobs-subtitleCon{
                                                        margin-top: 1rem;
                                                        .dkg-livejobs-subtitle-header{
                                                            background-color: #eaeaea;
                                                            border: 1px solid #dadada;
                                                            border-top-left-radius: 5px;
                                                            border-top-right-radius: 5px;
                                                            .dkg-livejobs-subtitle {
                                                                color: #6c757d;
                                                                font-size: 0.9435rem;
                                                                font-weight: 700;
                                                                display: flex;
                                                                justify-content: center;
                                                                align-items: center;
                                                                padding: 7px 10px;
                                                                text-transform: uppercase;
                                                                width: 100%;
                                                            }
                                                        }
                                                        .dkg-livejobs-subtitle-body{
                                                            border: 1px solid #ddd;
                                                            border-radius: 0 0 5px 5px;
                                                            border-top: none;
                                                            .dkg-subtitle-textarea{
                                                                width: 100%;
                                                                height: 120px;
                                                                border: none;
                                                                border-bottom: 1px solid #ddd;
                                                                background-color: #f2f2f2;
                                                                border-radius: 0 ;
                                                                &:focus{
                                                                    outline: 0;
                                                                    box-shadow: none;
                                                                }
                                                            }
                                                            .dkg-livjobs-btnCon{
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                width: 100%;
                                                                border-radius: 0 0 5px 5px;
                                                                .dkg-livjobs-btn{
                                                                    width: 90px;
                                                                    background-color: #ddd;
                                                                    color: #fff;
                                                                    background: #139BD6;
                                                                    border: none;
                                                                    padding: 10px;
                                                                    border-radius: 4px;
                                                                    transition: all 0.2s ease;
                                                                    outline: none;
                                                                    text-transform: uppercase;
                                                                    font-weight: 700;
                                                                    font-size: 0.9435rem;
                                                                    &:hover{
                                                                        background-color: #165a77;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .dkg-choose-us-tab-mainCon{
                                                    .dkg-choose-us-qt-tabCon{
                                                        .dkg-choose-us-tab-navpills{
                                                            width: 100%;
                                                            .dkg-choose-us-tab-nav-item{
                                                                width: 100%;
                                                                max-width: 25%;
                                                                .dkg-choose-us-tab-navlink{
                                                                    background-color: #fff;
                                                                    color: #333;
                                                                    padding: 12px 10px;
                                                                    border-radius: 0;
                                                                    border-bottom: 1px solid #ddd;
                                                                    border-right: 1px solid #ddd;
                                                                    width: 100%;
                                                                    text-align: center;
                                                                &.active{
                                                                    background-color: #f2f2f2;
                                                                    color: #333;
                                                                    position: relative;
                                                                    border-bottom: none;
                                                                }  
                                                                &:first-child{
                                                                    .dkg-choose-us-tab-navlink{
                                                                        border-left: 1px solid #ddd;
                                                                    }
                                                                }                                                              }
                                                            }
                                                        }
                                                        .dkg-paidservices-tabcontent{
                                                            .dkg-why-choose-us-tabpane{
                                                                background-color: #f2f2f2;
                                                                height: calc(100vh - 265px);
                                                                border: 1px solid #ddd;
                                                                border-top: none;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .dkg-paidservices-tabpane{
                                    padding: 15px 0;
                                    .dkg-paidservices-header{
                                        border: 1px solid #ddd;
                                        border-bottom: none;
                                        padding: 7px 10px;
                                        background-color: #D6DCE5;
                                        display: none;

                                        .dkg-paidservices-title{
                                            width: 100%;
                                            text-align: center;
                                            font-weight: 600;
                                            font-size: 18px;
                                            text-transform: uppercase;
                                            margin-bottom: 0;
                                        }
                                    }
                                    .dkg-paidservices-body{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: calc(100vh - 326px);
                                        flex-direction: column;
                                        width: 100%;
                                        border: none;
                                        .dkg-cand-dash-input{
                                            display: block;
                                            background-color: #fff;
                                            border: 1px solid #ddd;
                                            width: 100%;
                                            max-width: 96%;
                                            margin: 0 auto;
                                            padding: 7px 10px;
                                            min-height: 65px;                                                  
                                        }
                                        .dkg-cand-submit-btnCon{
                                            margin-top: 40px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            width: 100%;
                                            div{
                                                div{
                                                    .dkg-cand-submit-btn{
                                                        background: #2d96b6;
                                                        border: none;
                                                        border-radius: 5px;
                                                        color: #fff;
                                                        display: inline-block;
                                                        font-weight: 500;
                                                        height: auto;
                                                        min-height: auto;
                                                        outline: none;
                                                        padding: 9px 18px;
                                                        text-decoration: none;
                                                        text-transform: uppercase;
                                                        font-size: 1.25rem;
                                                        min-width: 90px;
                                                        &:hover{
                                                            background: #325f7d;
                                                            transition: all .5s;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.dkg-admin-setting-body{
                                            display: block;
                                            height: calc(100vh - 220px);
                                            overflow-y: auto;
                                            .dkg-candidate-Row{
                                                width: 100%;
                                                .dkg-candidate-leftCon,
                                                .dkg-candidate-rightCon{
                                                    .dkg-cand-form-group{
                                                        .dkg-candidate-inputgrp{
                                                            .dkg-inputgrp-prepared{
                                                                .dkg-inputgrp-text{
                                                                    min-width: 140px;
                                                                    max-width: 140px;
                                                                    font-size: 13px;
                                                                    text-transform: capitalize;
                                                                    border-right: none;
                                                                }
                                                            }
                                                            .dkg-cand-frmcontrol{
                                                                font-size: 13px;
                                                                text-align: left;
                                                                border: 1px solid #ddd;
                                                                height: 34px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .dkg-instructions-editorCon{
                                                width: 100%;
                                                .wrapper-class.rdw-editor-wrapper {
                                                    .rdw-editor-toolbar.toolbar-class {
                                                        margin-bottom: 0;
                                                        div.rdw-image-wrapper {
                                                            position: relative;

                                                            .rdw-image-modal {
                                                                position: absolute;
                                                                left: -340px;
                                                            }
                                                        }
                                                    }

                                                    .editor-class.rdw-editor-main {
                                                        background-color: #fff;
                                                        height: calc(85vh - 255px);
                                                        overflow-y: hidden;
                                                        padding: 0 10px;
                                                        border: 1px solid #ddd;
                                                        border-top: none;
                                                    }
                                                }
                                                .dkg-submit-btnCon{
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    width: 100%;
                                                    margin-top: 20px;
                                                    div{
                                                        div{
                                                            .dkg-cand-submit-btn{
                                                                text-align: center;
                                                                background-color: #2D96B6;
                                                                color: #fff;
                                                                &:hover{
                                                                    color: #fff;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                        &.dkg-admin-setting-prjbody{
                                            display: block;
                                            height: calc(100vh - 225px);
                                            overflow-y: auto;
                                            .dkg-visitwebaite-cardCon {
                                                width: 100%;
                                                .dk-otherproject-cols {
                                                    width: 100%;
                                                    // max-width: 1100px;
                                                    margin: auto;
                                                    display: flex;
                                                    justify-content: center;
                                                    margin: 20px auto;

                                                    &.dkg-top-level-boxCon {
                                                        max-width: 366px;
                                                        margin-bottom: 30px;
                                                    }

                                                    .dk-otherproject-box {
                                                        display: inline-block;
                                                        margin: 0 10px;
                                                        width: 100%;
                                                        .dkg-otherprj-boxcols{
                                                            padding: 20px;
                                                            background: #eaeaea;
                                                            border-radius: 8px 8px 0 0;
                                                            min-height: 140px;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            .dk-FileUploadCon {
                                                                display: flex;
                                                                flex-direction: column;
                                                                justify-content: center;
                                                                align-items: center;

                                                                .dk-fileUldimgCon {
                                                                    width: 250px;
                                                                    max-width: 250px;
                                                                    height: 140px;
                                                                    margin: 0 auto;
                                                                    border: 3px dashed lightslategrey;
                                                                    display: flex;
                                                                    justify-content: center;
                                                                    align-items: center;

                                                                    h3 {
                                                                        text-transform: uppercase;
                                                                        color: #324148;
                                                                        padding: 40px 0;
                                                                        font-size: 1.4rem;
                                                                        font-weight: 400;
                                                                    }
                                                                    figure#edit_image{
                                                                        margin: 1rem 1.25rem;
                                                                    }
                                                                }
                                                            }
                                                            .dk-browsedFile {
                                                                position: relative;

                                                                input {
                                                                    position: absolute;
                                                                    bottom: 0;
                                                                    z-index: 9;
                                                                    cursor: pointer;
                                                                    width: 100%;
                                                                    height: 41px;
                                                                    opacity: 0;
                                                                }

                                                                button {
                                                                    cursor: pointer;
                                                                    position: relative;
                                                                    z-index: 1;
                                                                }
                                                            }

                                                            .dk-uploadView-img {
                                                                display: flex;
                                                                justify-content: center;
                                                                width: 100%;
                                                                margin: 15px 0 0;
                                                                background: #fff;
                                                                border-radius: 5px;
                                                                padding: 10px;
                                                                position: relative;

                                                                img {
                                                                    width: 100%;
                                                                    max-width: 160px;
                                                                    margin: auto;
                                                                }

                                                                span.close-btn {
                                                                    display: inline-block;
                                                                    width: 15px;
                                                                    height: 15px;
                                                                    border-radius: 50%;
                                                                    background: #000;
                                                                    color: #fff;
                                                                    line-height: 15px;
                                                                    text-align: center;
                                                                    font-size: 10px;
                                                                    position: absolute;
                                                                    top: -3px;
                                                                    right: -3px;
                                                                    cursor: pointer;
                                                                    svg{
                                                                        position: relative;
                                                                        top: -2px;
                                                                    }
                                                                }
                                                            }

                                                            & img {
                                                                width: 100%;
                                                                height: auto;
                                                            }

                                                            & button {
                                                                margin-top: 20px;
                                                                width: 250px;
                                                                background-color: #ddd;
                                                                color: #fff;
                                                                background: #139BD6;
                                                                border: none;
                                                                padding: 10px;
                                                                border-radius: 4px;
                                                                transition: all .2s ease;
                                                                outline: none;
                                                                text-transform: uppercase;
                                                                font-weight: 700;
                                                                position: relative;

                                                                input {
                                                                    opacity: 0;
                                                                    position: absolute;
                                                                    left: 0;
                                                                    top: 0;
                                                                    width: 100%;
                                                                    height: 35px;
                                                                    cursor: pointer;
                                                                }
                                                            }

                                                            & button:hover {
                                                                background: #165a77;
                                                                color: #ffffff;
                                                                transition: all .2s ease;
                                                                cursor: pointer;
                                                            }
                                                            .title {
                                                                font-size: 14px;
                                                                text-transform: uppercase;
                                                                color: #26596d;
                                                                font-weight: 600;
                                                                text-align: center;
                                                                display: block;
                                                                margin-top: 10px;
                                                            }
                                                        }
                                                        .dkg-visit-prjbtn{
                                                            width: 100%;
                                                            border-top: 1px solid #ddd;
                                                            padding: 7px 10px;
                                                            display: block;
                                                            background-color: #f2f2f2;
                                                            color: #333;
                                                            text-transform: capitalize;
                                                            border-radius: 0 0 8px 8px;
                                                            margin: 0;
                                                            box-shadow: none;
                                                            font-size: 14px;
                                                            .dkg-resume-icon {
                                                                margin-left: 0.5rem;
                                                            }
                                                            &:hover{
                                                                background-color: #eaeaea;
                                                                transition: all 0.5s;
                                                            }
                                                        }
                                                        .dkg-porject-nameCon{
                                                            width: 100%;
                                                            border-top: 1px solid #ddd;
                                                            border-bottom: 1px solid #ddd;
                                                            padding: 0;
                                                            display: block;
                                                            background-color: #f2f2f2;
                                                            input.dkg-project-name{
                                                                border: none;
                                                                outline: 0;
                                                                border-radius: 0;
                                                                width: 100%;
                                                                padding: 7px 10px;
                                                                text-align: left;
                                                                background-color: transparent;
                                                                text-transform: capitalize;
                                                                font-size: 1.1rem;
                                                                font-weight: 500;
                                                                &:focus{
                                                                    outline: 0;
                                                                    box-shadow: none;
                                                                }
                                                            }
                                                        }
                                                        .dkg-porject-info-progressbarCon{
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: space-between;
                                                            width: 100%;
                                                            border-top: 1px solid #ddd;
                                                            padding: 0;
                                                            background-color: #f2f2f2;
                                                            color: #333;
                                                            .dkg-info-icon-leftCon{
                                                                width: 50%;
                                                                padding: 7px 10px;
                                                                text-align: center;
                                                                cursor: pointer;
                                                                color: #555;
                                                                &:hover{
                                                                    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
                                                                    background-color: #eaeaea;
                                                                    color: #3c97b6;
                                                                }
                                                            }
                                                            .dkg-progress-barCon{
                                                                width: 100%;
                                                            }
                                                            .dkg-project-rightCon{
                                                                width: 50%;
                                                                padding: 7px 10px;
                                                                text-align: center;
                                                                cursor: pointer;
                                                                color: #555;
                                                                &:hover{
                                                                    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
                                                                    background-color: #eaeaea;
                                                                    color: #3c97b6;
                                                                }
                                                            }
                                                        }
                                                        .dkg-project-btnCon{
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: space-between;
                                                            width: 100%;
                                                            background-color: #f2f2f2;
                                                            border: none;
                                                            .dkg-upload-prjbtn-Con{
                                                                width: 33.333%;
                                                                border-right: 1px solid #808080;
                                                                .dkg-upload-prjbtn{
                                                                    width: 100%;
                                                                    background-color: #139BD6;
                                                                    color: #fff;
                                                                    border: none;
                                                                    padding: 10px 2px;
                                                                    transition: all .2s ease;
                                                                    outline: none;
                                                                    text-transform: uppercase;
                                                                    font-weight: 700;
                                                                    position: relative;
                                                                    border-bottom-left-radius: 8px;
                                                                    input {
                                                                        opacity: 0;
                                                                        position: absolute;
                                                                        left: 0;
                                                                        top: 0;
                                                                        width: 100%;
                                                                        height: 35px;
                                                                        cursor: pointer;
                                                                    }
                                                                    &:hover {
                                                                        background: #165a77;
                                                                        color: #ffffff;
                                                                        transition: all .2s ease;
                                                                        cursor: pointer;
                                                                    }
                                                                }
                                                            }
                                                            .dkg-url-prjbtn-Con{
                                                                width: 33.333%;
                                                                .dkg-url-prjbtn{
                                                                    width: 100%;
                                                                    background-color: #FECA3E;
                                                                    color: #333;
                                                                    border: none;
                                                                    padding: 10px 2px;
                                                                    transition: all .2s ease;
                                                                    outline: none;
                                                                    text-transform: uppercase;
                                                                    font-weight: 700;
                                                                    position: relative;                                                                            }
                                                            }
                                                            .dkg-cancel-prjbtn-Con{
                                                                width: 33.333%;
                                                                .dkg-cancel-prjbtn{
                                                                    width: 100%;
                                                                    background-color: #E2445B;
                                                                    color: #fff;
                                                                    padding: 10px 2px;
                                                                    transition: all .2s ease;
                                                                    outline: none;
                                                                    text-transform: uppercase;
                                                                    font-weight: 700;
                                                                    position: relative;
                                                                    border: none;
                                                                   border-bottom-right-radius: 8px;
                                                                    &:hover {
                                                                        background: #165a77;
                                                                        color: #ffffff;
                                                                        transition: all .2s ease;
                                                                        cursor: pointer;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &.dkg-setting-sendus-body{
                                            display: block;
                                            height: calc(100vh - 220px);
                                            overflow-y: auto;
                                            .dkg-candidate-Row{
                                                width: 100%;
                                                max-width: 50%;
                                                margin: 100px auto;
                                                .dkg-candidate-leftCon,
                                                .dkg-candidate-rightCon{
                                                    .dkg-cand-form-group{
                                                        .dkg-candidate-inputgrp{
                                                            .dkg-inputgrp-prepared{
                                                                .dkg-inputgrp-text{
                                                                    min-width: 40px;
                                                                    max-width: 40px;
                                                                    font-size: 13px;
                                                                    text-transform: capitalize;
                                                                    border-right: none;
                                                                }
                                                            }
                                                            .dkg-cand-frmcontrol{
                                                                font-size: 13px;
                                                                text-align: left;
                                                                border: 1px solid #ddd;
                                                                height: 34px;
                                                            }
                                                        }
                                                        .dkg-sendus-btnCon{
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            width: 100%;
                                                            margin-top: 20px;
                                                            div{
                                                                div{
                                                                    .dkg-sendus-btn{
                                                                        text-align: center;
                                                                        background-color: #2D96B6;
                                                                        color: #fff;
                                                                        &:hover{
                                                                            color: #fff;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                        &.dkg-cn-notification-body{
                                            display: block;
                                            .dkg-cn-noti-tableCon{
                                                width: 100%;
                                                .dkg-cn-noti-table{
                                                    margin-bottom: 0;
                                                    thead{
                                                        tr{
                                                            th{
                                                                background-color: #f2f2f2;
                                                                font-weight: 600;
                                                                font-size: 1.25rem;
                                                                text-transform: uppercase;
                                                            }
                                                        }
                                                    }
                                                    tbody{
                                                        tr{
                                                            td{
                                                                vertical-align: middle;
                                                                padding: 0;
                                                                height: 130px;
                                                                padding: 0;
                                                                .dkg-noti-title-frmcontrol{
                                                                    border: 1px solid #ddd;
                                                                    border-left: none;
                                                                    border-right: none;
                                                                    border-radius: 0;
                                                                    height: 43px;
                                                                    font-weight: 600;
                                                                    text-align: left;
                                                                }
                                                                .dkg-noti-subtitle-cols{
                                                                    .dkg-noti-subtitle-frmcontrol{
                                                                        border:none;
                                                                        border-bottom: 1px solid #ddd;
                                                                        height: 43px;
                                                                        text-align: left;
                                                                        // &:nth-child(02){
                                                                        //     border-top: 1px solid #ddd;
                                                                        //     border-bottom: none;
                                                                        // }
                                                                        &:last-child{
                                                                            border-bottom: none;
                                                                        }
                                                                    }
                                                                }
                                                                &:nth-child(01){
                                                                    width: 30%;
                                                                }
                                                                &:nth-child(02){
                                                                    width: 70%;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .dkg-paidservices-tablebody{
                                        .dkg-principale-tableCon {
                                            height: calc(100vh - 220px);
                                            overflow-y: auto;

                                            .dkg-principale-table {
                                                tbody {
                                                    tr {
                                                        td {
                                                            font-size: 16px;
                                                            text-transform: uppercase;
                                                            font-weight: 700;
                                                            text-align: center;
                                                            padding: 8px 10px;
                                                            height: 85px;
                                                            line-height: 75px;
                                                            background-color: #f2f2f2;
                                                            border: 1px solid #ddd;
                                                            vertical-align: middle;

                                                            input.form-control {
                                                                border: none;
                                                                background-color: #f2f2f2;

                                                                &::placeholder {
                                                                    color: #343a40;
                                                                }

                                                                &:focus {
                                                                    box-shadow: none;
                                                                    outline: 0;
                                                                }
                                                            }
                                                            .txta {
                                                                width: 100%;
                                                                font-family: Arial, sans-serif;
                                                                font-size: 14px;
                                                                overflow: hidden;
                                                                line-height: 1.4;
                                                                background-color: transparent;
                                                                border: none;
                                                                vertical-align: middle;
                                                                padding-top: 27px;
                                                              }
                                                              .dkg-cand-submit-btnCon{
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                margin-top: 0;
                                                                div{
                                                                    div{
                                                                        .dkg-cand-submit-btn{
                                                                            background: #2d96b6;
                                                                            border: none;
                                                                            border-radius: 5px;
                                                                            color: #fff;
                                                                            display: inline-block;
                                                                            font-weight: 500;
                                                                            height: auto;
                                                                            min-height: auto;
                                                                            outline: none;
                                                                            padding: 5px 7px;
                                                                            text-decoration: none;
                                                                            text-transform: uppercase;
                                                                            font-size: 1rem;
                                                                            min-width: 75px;
                                                                            &:hover{
                                                                                background: #325f7d;
                                                                                transition: all .5s;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            &:nth-child(01),
                                                            &:nth-child(05) {
                                                                width: 12%;
                                                            }

                                                            &:nth-child(02) {
                                                                text-align: left;
                                                            }

                                                            &:nth-child(03) {

                                                                width: 45%;
                                                                vertical-align: middle;

                                                                input {
                                                                    display: flex;
                                                                    flex-wrap: wrap;
                                                                    align-items: flex-start;
                                                                    font-size: 13px;
                                                                    margin-bottom: 0;
                                                                    font-weight: normal;
                                                                    text-transform: math-auto;
                                                                    font-family: 'Roboto';
                                                                    font-weight: normal;
                                                                    color: #333;

                                                                }

                                                            }

                                                            .dkg-uplaod-imageCon {
                                                                align-items: center;
                                                                display: flex;
                                                                margin-bottom: 0;

                                                                .d-flex {
                                                                    .dk-edit-userImgCon {
                                                                        height: 95px;
                                                                        position: relative;

                                                                        input {
                                                                            cursor: pointer;
                                                                            height: 95px;
                                                                            opacity: 0;
                                                                            position: relative;
                                                                            width: 100%;
                                                                            z-index: 9;
                                                                        }

                                                                        .dk-myprofile-userImgCon {
                                                                            border-radius: 50%;
                                                                            cursor: pointer;
                                                                            left: 3px;
                                                                            max-width: 85px;
                                                                            position: absolute;
                                                                            top: 7px;
                                                                            width: 100%;
                                                                            object-fit: scale-down;
                                                                            figure {
                                                                                margin: 0;

                                                                                .dkg-changIcon {
                                                                                    width: 55px;
                                                                                    height: 55px;
                                                                                    // object-fit: cover;
                                                                                    // object-fit: contain;
                                                                                    object-fit: scale-down;

                                                                                }
                                                                            }
                                                                        }

                                                                        .dk-myprofile-editicon {
                                                                            background-color: #234158;
                                                                            border-radius: 50%;
                                                                            bottom: 25px;
                                                                            width: 27px;
                                                                            height: 27px;
                                                                            display: flex;
                                                                            justify-content: center;
                                                                            align-items: center;
                                                                            position: absolute;
                                                                            right: 15px;
                                                                            top: 37px;

                                                                            svg {
                                                                                width: 14px;
                                                                                height: 14px;
                                                                                color: #fff;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.dkg-setting-mainCon-234{
                               background-color: #eaeaea;
                                .dkg-instructions-editorCon{
                                    width: 100%;
                                    max-width: 1600px;
                                    margin: 0 auto;
                                    .wrapper-class.rdw-editor-wrapper {
                                        .rdw-editor-toolbar.toolbar-class {
                                            margin-bottom: 0;
                                            div.rdw-image-wrapper {
                                                position: relative;

                                                .rdw-image-modal {
                                                    position: absolute;
                                                    left: -340px;
                                                }
                                            }
                                        }

                                        .editor-class.rdw-editor-main {
                                            background-color: #fff;
                                            height: calc(100vh - 310px);
                                            overflow-y: auto;
                                            padding: 0 10px;
                                        }
                                    }
                                    .dkg-submit-btnCon{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 100%;
                                        margin-top: 20px;
                                        div{
                                            div{
                                                .dkg-cand-submit-btn{
                                                    text-align: center;
                                                    background-color: #2D96B6;
                                                    color: #fff;
                                                    &:hover{
                                                        color: #fff;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                        .dkg-candidate-header{
                            border: 1px solid #ddd;
                            border-bottom: none;
                            padding: 9px 10px;
                            background-color: #D6DCE5;
                            display: none;
                            .dkg-candidate-title{
                                width: 100%;
                                text-align: center;
                                font-weight: 600;
                                font-size: 18px;
                                text-transform: uppercase;
                                margin-bottom: 0;

                            }
                        }
                        .dkg-candidate-mainCon{
                            background-color: #fff;
                            border: 1px solid #ddd;
                            height: calc(100vh - 230px);
                            overflow-y: auto;
                            padding: 15px;
                            &.dkg-socailmedia-mainCon{
                                height: calc(100vh - 200px) !important;
                                &::-webkit-scrollbar {
                                    display: none;
                                }
                            }
                            &.dkg-socailmedia-mainCon{
                                -ms-overflow-style: none;  /* IE and Edge */
                                scrollbar-width: none;  /* Firefox */
                            }
                            .dkg-candidate-Row{
                                .dkg-candidate-leftCon,
                                .dkg-candidate-rightCon{
                                    .dkg-cand-form-group{
                                        .dkg-candidate-inputgrp{
                                            .dkg-inputgrp-prepared{
                                                .dkg-inputgrp-text{
                                                    min-width: 130px;
                                                    max-width: 130px;
                                                    font-size: 13px;
                                                }
                                            }
                                            .dkg-cand-frmcontrol{
                                                font-size: 13px;
                                                text-align: left;
                                                border: 1px solid #ddd;
                                                height: 34px;
                                            }
                                        }
                                    }
                                }
                            }
                            &.dkg-edit-cand-benfitsCon{
                                padding: 0;
                                .dkg-cn-benefits-Row{
                                    margin: 0;
                                    .dkg-cn-benefits-leftCon{
                                        padding: 0;
                                        .dkg-cand-form-group{
                                            margin-bottom: 0;
                                            border-bottom: 1px solid #ddd;
                                            .dkg-cn-benefits-inputgrp{
                                                .dkg-inputgrp-prepared{
                                                    .dkg-inputgrp-text{
                                                        min-width: 250px;
                                                        max-width: 250px;
                                                        font-size: 13px;
                                                        border: none;
                                                        padding: 0;
                                                        .dkg-benefit-iconCon{
                                                            .dkg-benefit-icon{
                                                                width: 18px;
                                                                height: 18px;
                                                            }

                                                        }
                                                        .dkg-benefit-iconCon {
                                                            width: 70px;
                                                            border: 1px solid #ccc;
                                                            padding: 0;
                                                            margin: 0 5px;
                                                            height: 40px;
                                                            display: flex;
                                                            align-items: center;
                                                            background: #fff;
                                                            justify-content: center;
                                                            overflow: hidden;
                                                            border-radius: 50%;                               
                                                            input {
                                                                width: 100%;
                                                                height: 60px;
                                                                // max-width: 60px;
                                                                position: relative;
                                                                z-index: 9;
                                                                opacity: 0;
                                                                cursor: pointer;
                                                            
                                                            }
                                                            span.dk-myprofile-userImgCon {
                                                                position: absolute;
                                                                top: 11px;
                                                                left: 12px;
                                                                cursor: pointer;
                                                                width: 100%;
                                                                max-width: 25px;
                                                                border-radius: 50%;
                                                                img.dk-myprofile-userImg {
                                                                    width: 100%;
                                                                    height: auto;
                                                                    border-radius: 50%;
                                                                }
                                
                                                                figure {
                                                                    margin: 0 0 1rem;
                                                                    .dkg-update-profile-234 {
                                                                        max-width: 35px;
                                                                        border: 1px solid #ccc;
                                                                        padding: 0;
                                                                        height: 30px;
                                                                        display: flex;
                                                                        align-items: center;
                                                                        background: #fff;
                                                                        justify-content: center;
                                                                        overflow: hidden;
                                                                        border-radius: 50%;
                                                                        position: relative;
                                                                        right: 4px;
                                                                    }
                                                                }
                                                            }
                                
                                                            span.dk-myprofile-editicon {
                                                                position: absolute;
                                                                bottom: 27px;
                                                                left: 34px;
                                                                width: 16px;
                                                                height: 16px;
                                                                display: flex;
                                                                justify-content: center;
                                                                align-items: center;
                                                                border-radius: 50%;
                                                                svg {
                                                                    color: #333;
                                                                }
                                                            }
                                                        }
                                                        .dkg-trick-iconCon {
                                                            width: 70px;
                                                            border: 1px solid #ccc;
                                                            padding: 0;
                                                            margin: 0;
                                                            height: 40px;
                                                            display: flex;
                                                            align-items: center;
                                                            background: #fff;
                                                            justify-content: center;
                                                            // overflow: hidden;
                                                            border-radius: 50%; 
                                                            position: relative;  
                                                            left: -15px;                            
                                                            input {
                                                                width: 100%;
                                                                height: 40px;
                                                                // max-width: 60px;
                                                                position: relative;
                                                                z-index: 9;
                                                                opacity: 0;
                                                                cursor: pointer;
                                                            
                                                            }
                                                            span.dk-trick-click-userImgCon {
                                                                position: absolute;
                                                                top: 2px;
                                                                left: 0px;
                                                                cursor: pointer;
                                                                width: 100%;
                                                                max-width: 25px;
                                                                border-radius: 50%;
                                                                img.dk-trick-click-userImg {
                                                                    width: 100%;
                                                                    height: auto;
                                                                    border-radius: 50%;
                                                                }
                                
                                                                figure {
                                                                    margin: 0 0 1rem;
                                                                    .dkg-update-profile-234 {
                                                                        max-width: 35px;
                                                                        border: 1px solid #ccc;
                                                                        padding: 0;
                                                                        height: 30px;
                                                                        display: flex;
                                                                        align-items: center;
                                                                        background: #fff;
                                                                        justify-content: center;
                                                                        overflow: hidden;
                                                                        border-radius: 50%;
                                                                        position: relative;
                                                                        right: 4px;
                                                                    }
                                                                }
                                                            }
                                
                                                            span.dk-trick-click-editicon {
                                                                position: absolute;
                                                                bottom: 23px;
                                                                left: 29px;
                                                                width: 16px;
                                                                height: 16px;
                                                                display: flex;
                                                                justify-content: center;
                                                                align-items: center;
                                                                border-radius: 50%;
                                                                svg {
                                                                    color: #333;
                                                                }
                                                            }
                                                        }
                                                        .dkg-inputgrp-frmcontrol{
                                                            font-size: 13px;
                                                            text-align: left;
                                                            // height: 44px;
                                                            border: none;
                                                            background-color: transparent;
                                                            width: 100%;
                                                            height: 44px;
                                                            padding: 0 15px;
                                                        }
                                                    }
                                                }
                                                .dkg-cand-frmcontrol{
                                                    font-size: 13px;
                                                    text-align: left;
                                                    // border: 1px solid #ddd;
                                                    height: 44px;
                                                   border:none
                                                }
                                                .dkg-cn-benefits-btnCon{
                                                    background-color: #E9ECEF;
                                                   div{
                                                    div{
                                                        .dkg-cand-submit-btn{
                                                            box-shadow: none;
                                                            background-color: transparent;
                                                            line-height: 32px;
                                                        }
                                                    }
                                                   }
                                                }
                                            }
                                        }
                                        &.dkg-basic-acc-table{
                                            .dkg-cand-form-group{
                                                &:nth-child(11),
                                                &:nth-child(12){
                                                    display: none;
                                                }
                                            }
                                        }
                                        &.dkg-premium-acc-table{
                                            .dkg-cand-form-group{
                                                &:nth-child(01),
                                                &:nth-child(02),
                                                &:nth-child(03),
                                                &:nth-child(04),
                                                &:nth-child(05),
                                                &:nth-child(06),
                                                &:nth-child(07),
                                                &:nth-child(08),
                                                &:nth-child(09),
                                                &:nth-child(10){
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-socialmedia-tab{
                                height: 100%;
                                .dkg-social-box-cols{
                                    height: 90%;
                                    .dkg-social-media-box{
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        border: 1px solid #ddd;
                                        background-color: #eaeaea;
                                        margin-bottom: 12px;
                                        height: 100%;
                                        max-height: 20%;
                                        .dkg-socail-media-icon{
                                            width: 120px;
                                            height: 75px;
                                            border-right: 1px solid #ddd;
                                            .dkg-sm-imgcon{
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                height: 100%;
                                                width: 100%;
                                                .dkg-sm-img{
                                                    width: 45px;
                                                    height: 45px;
                                                }
                                            }
                                        }
                                        .dkg-send-urlbtn-con{
                                            width: 140px;
                                            height: 75px;
                                            border-left: 1px solid #ddd;
                                            .dkg-send-urlbtn-flex{
                                                width: 100%;
                                                position: relative;
                                                top: 5px;
                                                .dkg-social-media-name-btn,
                                                .dkg-social-media-url-btn{
                                                    div{
                                                        div{
                                                            .dkg-send-urlbtn{
                                                                display: block;
                                                                color: #fff;
                                                                background-color: #3C97B6;
                                                                padding: 7px 10px;
                                                                font-size: 14px;
                                                                width: 100%;
                                                                border-radius: 0;
                                                                min-width: 114px;
                                                                height: 32px;
                                                                line-height: 17px;
                                                                text-transform: capitalize !important;
                                                                &:hover{
                                                                    background-color: #485569;
                                                                }

                                                            }
                                                        }
                                                    }
                                                }
                                                .dkg-social-media-url-btn{
                                                    border-bottom: none !important;
                                                    div{
                                                        div{
                                                            .dkg-send-urlbtn{
                                                                background-color: #325f7d ;
                                                                &:hover{
                                                                    background-color: #485569;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                        .dkg-sm-nameurl-con{
                                            width: 100%;
                                            height: 75px;
                                            position: relative;
                                            top: 5px;
                                            .dkg-timeflex{
                                                width: 100%;
                                                .dkg-social-media-name{
                                                    display: block;
                                                    height: 33px;
                                                    line-height: 32px;
                                                    font-size: 13px;
                                                    border-bottom: 1px solid #ddd;
                                                    text-align: center;
                                                    width: 100%;
                                                    .dkg-social-name-iput{
                                                        position: relative;
                                                        top: 0px;
                                                        height: 32px;
                                                        border: none;
                                                        border-radius: 0;
                                                    }

                                                }
                                                .dkg-social-media-url{
                                                    display: block;
                                                    height: 32px;
                                                    line-height: 32px;
                                                    font-size: 13px;
                                                    text-align: center;
                                                    width: 100%;
                                                    .dkg-social-name-url{
                                                        position: relative;
                                                        top: 0px;
                                                        height: 32px;
                                                        border: none;
                                                        border-radius: 0;
                                                    }
                                                }
                                            }
                                        }
                                        // &:first-child{
                                        //     margin-top: 15px;
                                        // }
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                    }

                                }
                            }
                            .dkg-cand-dash-mainCon{
                                .dkg-cand-dash-main{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
                                    .dkg-cand-dash-input{
                                        display: block;
                                        height: 48px;
                                        background-color: #fff;
                                        border: 1px solid #ddd;
                                        width: 100%;
                                        text-align: center;
                                    }
                                    .dkg-cand-submit-btnCon{
                                        margin-top: 40px;
                                        div{
                                            div{
                                                .dkg-cand-submit-btn{
                                                    background: #2d96b6;
                                                    border: none;
                                                    border-radius: 5px;
                                                    color: #fff;
                                                    display: inline-block;
                                                    font-weight: 500;
                                                    height: auto;
                                                    min-height: auto;
                                                    outline: none;
                                                    padding: 9px 18px;
                                                    text-decoration: none;
                                                    text-transform: uppercase;
                                                    font-size: 1.25rem;
                                                    min-width: 90px;
                                                    &:hover{
                                                        background: #325f7d;
                                                        transition: all .5s;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                            .dkg-company-list-mainCon{
                                width: 100%;
                                padding: 0;
                                .dkg-company-list-tableCon{
                                    margin-top: 0;
                                    // overflow-y: auto;
                                    // overflow-x: auto;
                                    // height: calc(100vh - 280px);
                                    padding-bottom: 0;
                                    position: relative;
                                    top: 0;
                                    .dkg-company-list-table{
                                        thead{
                                            tr{
                                                th{
                                                    background: #485569;
                                                    color: #fff;
                                                    font-size: 13px;
                                                    text-align: center;
                                                    white-space: nowrap;
                                                    padding: 9px 7px;
                                                    border: 1px solid #808080;
                                                    border-bottom: none;
                                                    vertical-align: middle;
                                                    position: sticky;
                                                    top: 0;
                                                    z-index: 9;

                                                }
                                            }
                                        }
                                        tbody{
                                            tr{
                                                td{
                                                    background: #fff;
                                                    color: #4d4a4a;
                                                    font-size: 13px;
                                                    font-weight: 400;
                                                    text-align: center;
                                                    white-space: nowrap;
                                                    padding: 5px 7px;
                                                    border: 1px solid #ddd;
                                                    vertical-align: middle;
                                                    text-transform: capitalize;
                                                    &:nth-child(01){
                                                        text-align: center;
                                                    }
                                                    .form-control{
                                                        background-color: transparent;
                                                        outline: 0;
                                                        border: none;
                                                    }
                                                    .dkg-benefit-iconCon {
                                                        width: 50px;
                                                        border: 1px solid #ccc;
                                                        padding: 0;
                                                        height: 50px;
                                                        display: flex;
                                                        align-items: center;
                                                        background: #fff;
                                                        justify-content: center;
                                                        // overflow: hidden;
                                                        border-radius: 50%;  
                                                        position: relative;                             
                                                        input {
                                                            width: 100%;
                                                            height: 60px;
                                                            // max-width: 60px;
                                                            position: relative;
                                                            z-index: 9;
                                                            opacity: 0;
                                                            cursor: pointer;
                                                        
                                                        }
                                                        span.dk-myprofile-userImgCon {
                                                            position: absolute;
                                                            top: 11px;
                                                            left: 12px;
                                                            cursor: pointer;
                                                            width: 100%;
                                                            max-width: 25px;
                                                            border-radius: 50%;
                                                            img.dk-myprofile-userImg {
                                                                width: 100%;
                                                                height: auto;
                                                                border-radius: 50%;
                                                            }
                            
                                                            figure {
                                                                margin: 0 0 1rem;
                                                                .dkg-update-profile-234 {
                                                                    max-width: 35px;
                                                                    border: 1px solid #ccc;
                                                                    padding: 0;
                                                                    height: 30px;
                                                                    display: flex;
                                                                    align-items: center;
                                                                    background: #fff;
                                                                    justify-content: center;
                                                                    overflow: hidden;
                                                                    border-radius: 50%;
                                                                    position: relative;
                                                                    right: 4px;
                                                                }
                                                            }
                                                        }
                            
                                                        span.dk-myprofile-editicon {
                                                            position: absolute;
                                                            bottom: 38px;
                                                            left: 32px;
                                                            width: 16px;
                                                            height: 16px;
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            border-radius: 50%;
                                                            svg {
                                                                color: #333;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.dkg-paidservice-mainCon{
                                border: none;
                                height: inherit;
                                padding: 0;
                                .dkg-company-list-mainCon.dkg-paidservice-list-mainCon{
                                    .dkg-paidservice-table-Con{
                                        height: calc(100vh - 230px);
                                        // overflow-x: auto;
                                        overflow-y: auto;
                                        position: relative;
                                        .dkg-company-list-table{
                                            thhead{
                                                tr{
                                                    th{
                                                        position: sticky;
                                                        position: -webkit-sticky;
                                                        top: 0;
                                                        z-index: 9;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.dkg-cn-anus-tabpane{
                            padding: 7px 0;
                            .dkg-paidservices-body{
                                justify-content: inherit !important;
                                height: calc(100vh - 210px);
                                .wrapper-class.rdw-editor-wrapper{
                                    .editor-class.rdw-editor-main{
                                        height: calc(100vh - 370px);
                                        border: 1px solid #ddd;
                                        padding: 7px 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.dkg-adminpanelMain.dkg-adminpanel-modulesCon {
    padding: 15px;
    // background: #26596d;
    background: #fff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    h3.title {
        text-align: center;
        display: block;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        color: #333;
        margin-bottom: 30px;
    }

    .dkg-adminpanel-cols {
        width: 100%;
        max-width: 800px;
        margin: auto;
        display: flex;
        justify-content: center;

        .dkg-adminpanel-box {
            display: inline-block;
            margin: 0 15px 25px;
            width: 100%;

            a {
                padding: 30px 20px;
                background: #fefefe;
                border-radius: 8px;
                min-height: 125px;
                display: flex;
                align-items: center;
                justify-content: center;

                .dk-visitLogo {
                    text-align: center;
                    display: block;
                    text-transform: uppercase;
                    font-size: 16px;
                    text-align: center;
                    font-weight: 500;

                    img {
                        width: 100%;
                        max-width: 150px;
                        margin: auto;
                        transform: scale(1);
                        transition: all 0.5s;
                    }
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: all 0.5s;
                    }
                }

                .title {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #26596d;
                    font-weight: 600;
                    text-align: center;
                    display: block;
                    margin-top: 10px;
                }
            }
        }

        .dkg-adminpanel-box.itteamtabs {
            a {
                background: #B55355;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dkg-adminpanel-box.trainingtabs {
            a {
                background: #396a95;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dkg-adminpanel-box.dkglobaltabs {
            a {
                background: #258fb4;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dkg-adminpanel-box.dkgcrm-dashboardbox {
            a {
                background: #144773;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dkg-adminpanel-box.dkgcrm-myaccountsbox {
            a {
                // background: #2e75b6;
                color: #fff;
                background: #884C5E;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dkg-adminpanel-box.dkgcrm-lastbox {
            a {
                background: #cf7c49;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dkg-adminpanel-box.dkgcrm-recquirementdbox {
            a {
                background: #3c4c62;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dkg-adminpanel-box.dkgcrm-marketingdbox {
            a {
                background: #5c737d;
                // background: #396A95;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dkg-adminpanel-box.dkgcrm-acc-finanancebox {
            a {
                background-color: #5a4c72;
                // background: #884C5E;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dkg-adminpanel-box.dkgcrm-supplierbox {
            a {
                background-color: #01796f;
                // background: #884C5E;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }
    }
}
.dk-settingsUserMain {
    padding: 0 20px;
    margin-top: 80px;
    font-family: "Roboto", sans-serif !important;
    font-weight: 400;

    .dk-settingUserTabs {
        .nav-tabs {
            border: none;

            a {
                background: #fff;
                color: #333;
                font-size: 14px;
                border: 1px solid #dbdbdb;
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem;
                margin-right: 5px;

                &:nth-child(01) {
                    width: 69px;
                    padding: 8px 8px;
                    margin-left: 1px;
                    text-align: center;
                }

                &:nth-child(05) {
                    margin-left: auto;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            a:hover {
                background-color: #3c97b5;
                color: #fff;
            }

            a.active {
                background: #2e97b5;
                border-color: #2e97b5;
                color: #fff;
            }
        }

        .tab-content {
            height: calc(100vh - 248px);
            overflow-y: auto;
        }

        .tab-content::-webkit-scrollbar {
            width: 0;
        }

        .tab-content::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
        }

        .tab-content::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0px solid slategrey;
        }

        .dk-userTableMain {
            margin-top: 0;

            table {
                width: 100%;
                margin-bottom: 0;
                border-top: none;

                tr {
                    th {
                        border-bottom: none;
                        background: #2e97b5;
                        color: #fff;
                        text-align: center;
                        font-size: 15px;
                        vertical-align: middle;
                        white-space: nowrap;
                        padding: 12px 10px;
                        font-weight: 500;
                        position: sticky;
                        top: 0;
                        z-index: 99;

                        &:nth-child(01) {
                            width: 70px;
                            min-width: 70px;
                        }

                        &:nth-child(04) {
                            width: 180px;
                        }

                        &:nth-child(05) {
                            width: 250px;
                        }
                    }

                    td {
                        vertical-align: middle;
                        background-color: #f5f5f5;
                        white-space: nowrap;
                        color: #333;
                        padding: 0.35em 1em;
                        font-size: 14px;
                        text-align: center;
                        font-weight: 400;

                        &:nth-child(04),
                        &:nth-child(05) {
                            text-align: left;
                        }

                        .dk-userImg {
                            img {
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }

                        .dk-userStatusBtn {
                            span {
                                color: #fff;
                                padding: 7px 0;
                                font-size: 14px;
                                border: none;
                                border-radius: 3px;
                                font-weight: 400;
                                background-color: #03c09a;
                                width: 95px;
                                display: inline-block;
                            }
                        }

                        .dk-userStatusBtn.dk-inactiveBtn {
                            span {
                                background-color: #e2445b;
                            }
                        }

                        .dk-userDeleteBtn {
                            button {
                                background-color: #e2445b;
                                color: #fff;
                                padding: 8px 18px;
                                border: none;
                                border-radius: 3px;
                                outline: none;
                                font-size: 14px;
                            }
                        }

                        .dk-userEditBtn {
                            button {
                                background-color: #007bff;
                                color: #fff !important;
                                padding: 6px 18px;
                                border: none;
                                border-radius: 3px !important;
                                outline: none;
                                font-size: 14px;
                            }
                        }
                        .dkg-user-type-btnCon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .dkg-user-type-btn{
                                margin: 0;
                                width: 125px;
                                background: #eaeaea;
                                border: 1px solid #ddd;
                                color: #333;
                                box-shadow: none !important;
                                padding: 5px 10px;
                                text-transform: capitalize;
                                font-size: 14px;
                                border-radius: 3px;
                            }
                        }
                        .dk-roleDropdown {
                            .dropdown {
                                .dropdown-toggle {
                                    margin: 0;
                                    width: 125px;
                                    background: #eaeaea;
                                    border: 1px solid #ddd;
                                    color: #333;
                                    box-shadow: none !important;
                                    padding: 5px 10px;
                                    text-transform: capitalize;
                                    font-size: 14px;
                                    border-radius: 3px;

                                    &::after {
                                        display: none;
                                    }
                                }

                                .dropdown-menu {
                                    padding: 0;
                                    min-width: 125px;
                                    border-radius: 0;

                                    a {
                                        padding: 6px 12px;
                                        border-bottom: 1px solid #ddd;
                                    }

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }

                        .dk-userStatusBtn.inActiveBtn {
                            .dropdown {
                                .dropdown-toggle {
                                    background: #E2445B;
                                    border: 1px solid #E2445B;

                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .dk-userStatusBtn {
                            .dropdown {
                                .dropdown-toggle {
                                    margin: 0;
                                    width: 95px;
                                    background: #03c09a;
                                    border: 1px solid #03c09a;
                                    color: #fff;
                                    box-shadow: none !important;
                                    padding: 5px 10px;
                                    text-transform: capitalize;
                                    font-size: 14px;
                                    border-radius: 3px;

                                    &::after {
                                        margin-top: 0.12rem;
                                        display: none;
                                    }
                                }

                                .dropdown-menu {
                                    padding: 0;
                                    min-width: 95px;
                                    border-radius: 0;

                                    a {
                                        padding: 6px 12px;
                                        border-bottom: 1px solid #ddd;
                                    }

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }

                        .actionBtn {
                            display: flex;
                            justify-content: center;

                            .dk-userEditBtn button {
                                margin-right: 0.5rem;
                                background-color: #007bff;
                                color: #fff;
                                padding: 8px 18px;
                                border: none;
                                border-radius: 3px;
                                outline: none;
                                font-size: 14px;

                            }
                        }
                    }
                }
            }
        }

        .dk-userEditMain {
            .admin-edit-profile {
                box-shadow: none;
                padding: 25px;
                background-color: #d9d9d9;
                width: 100%;
                margin: 15px auto auto;

                .profile-photo {
                    figure {
                        margin-right: 10px;
                        width: 130px;
                        height: 130px;
                        border: 1px solid #999;
                        overflow: hidden;

                        img {
                            width: 100%;
                        }

                        canvas {
                            width: 100%;
                        }
                    }
                }

                .dk-userChoosFile {
                    position: relative;
                    width: 100%;
                    max-width: 120px;

                    input {
                        width: 120px;
                        height: 35px;
                        position: relative;
                        z-index: 9;
                        opacity: 0;
                        cursor: pointer;
                    }

                    span {
                        width: 100%;
                        max-width: 120px;
                        height: 35px;
                        cursor: pointer;
                        z-index: 1;
                        background: #26597d;
                        color: #fff;
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        text-align: center;
                        line-height: 35px;
                    }
                }

                .dk-userEditForm {
                    form {
                        label {
                            width: 100%;

                            input {
                                border-radius: 0;
                                background: #f5f2f2;
                                border: 1px solid #ddd !important;
                                box-shadow: none !important;
                                height: 35px;
                            }
                        }

                        label.dk-userDate {
                            position: relative;

                            span {
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: 35px;
                                height: 35px;
                                background: #dedede;
                                text-align: center;
                                line-height: 35px;
                            }
                        }

                        label.dk-userPassword {
                            position: relative;

                            span {
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: 35px;
                                height: 35px;
                                background: #dedede;
                                text-align: center;
                                line-height: 35px;
                            }
                        }

                        .dk-userSbtitle {
                            display: block;
                            width: 100%;
                            font-size: 16px;
                            margin: 10px 16px 30px;
                            background: #f2f2f2;
                            padding: 12px 0;
                            text-align: center;
                        }
                    }
                }
            }

            .dk-userAddBtn {
                background-color: #64686f;
                color: #fff;
                border: none;
                padding: 7px 20px;
                margin: 30px 0 25px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                outline: none;
                border-radius: 3px;
                font-family: "Roboto", sans-serif !important;
                box-shadow: none;

                &:hover {
                    background-color: #2d96b6;
                    border-color: #2d96b6;
                    transition: all .5s;
                }
            }
        }

        .dk-userSettingAddnew-container {
            background-color: #eaeaea;
            padding: 70px 25px 50px;
            border-radius: 0 0 5px 5px;

            .dk-userSetting-addNew {
                width: 100%;
                max-width: 600px;
                margin: 1rem auto auto;

                .title {
                    width: 100%;
                    text-align: center;
                    font-weight: 700;
                    font-size: 18px;
                    border-radius: 5px 5px 0 0;
                    background-color: #3c97b5;
                    color: #fff;
                    padding: 8px 10px;
                    margin-top: .5rem;
                }

                form {
                    border: 1px solid #ddd;
                    padding: 20px 25px;
                    border-radius: 0 0 5px 5px;
                    background: #fff;

                    label {
                        width: 100%;

                        input {
                            border-radius: 0;
                            height: 35px;
                            background: #f2f2f2;
                            border: 1px solid #ddd !important;
                            box-shadow: none !important;
                        }

                        select {
                            border-radius: 0;
                            height: 35px;
                            background: #f2f2f2;
                            border: 1px solid #ddd !important;
                            box-shadow: none !important;
                        }
                    }

                    .dk-createBtn {
                        .dk-userAddBtn {
                            background: #3c97b5;
                            display: inline-block;
                            padding: 11px 20px;
                            font-weight: 600;
                            border: none;
                            color: #fff;
                            font-size: 14px;
                            border-radius: 5px;

                            &:hover {
                                background: #2e536f;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-userDeleteModal {
    .modal-dialog {
        min-width: 30%;
        max-width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0 auto;

        .modal-content {
            border-radius: 5px;

            .modal-body {
                .dk-deleteModal-body {
                    p {
                        font-size: 15px;
                        color: #e2445b;
                        font-weight: 500;
                        padding: 15px 0;
                        display: block;
                    }

                    button {
                        border: 1px solid #333;
                        color: #333;
                        background: transparent;
                        display: inline-block;
                        width: 100%;
                        max-width: 90px;
                        border-radius: 5px;
                        padding: 7px 0;
                        transition: all .5s;
                        outline: none;
                        font-weight: 500;
                    }

                    button.dk-yesBtn {
                        background: #e2445b;
                        border: 1px solid #e2445b;
                        color: #fff;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

.dk-uploadImgModal {
    .modal-dialog {
        min-width: 500px;
        max-width: 500px;

        .modal-content {
            border-radius: 5px;
        }

        .modal-body {
            .ReactCrop {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }

        .modal-footer {
            button {
                display: inline-block;
                background: #2e536f !important;
                border: none;
                outline: none;
                border-radius: 3px;
                width: 100%;
                max-width: 110px;
                min-width: 110px;
                color: #fff;
                padding: 8px 0;
                text-align: center;
                margin: 0 5px;
                transition: all 0.5s;
                box-shadow: none !important;
                font-size: 14px;
            }

            button.dk-cancelBtn {
                background: transparent !important;
                color: #e2445b;

                &:hover {
                    background: #e2445b !important;
                    color: #fff;
                    transition: all 0.5s;
                }
            }
        }
    }
}
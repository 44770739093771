.dkg-pagetype-video-mainCon{
    margin-top: 140px;
    height: calc(100vh - 140px);
    background-color: #eaeaea;
    width: 100%;
    padding: 15px;
    overflow-y: auto;
    .dkg-pagetype-videoCon{
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        .dkg-pagetype-mainRow{
          .dkg-pagetype-video-cols{
            padding: 15px;
            .dkg-pagetype-video-box{
                text-align: center;
                background: #f5f5f5;
                margin: 0;
                border-radius: 5px;
                padding-bottom: 1rem;
                .dkg-pagetype-video-figure{
                    margin: 0 0 1rem ;
                    .dkg-pagetype-video-imgCon{
                        width: 100%;
                        height: 210px;
                        border-radius: 5px 5px 0 0;                 
                        .dkg-pagetype-video-img{
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 5px 5px 0 0;                 
                        }
                    }
                }
                .dkg-pagetype-bottomCon{
                    .dkg-upload-mediabtn{
                        box-shadow: none;
                        color: #fff;
                        background-color: #6c757d;
                        border-color: #6c757d;
                        margin: 0;
                        padding: 9px 15px;
                        border-radius: 5px;
                        margin-right: 0.5rem;
                        width: 80px;
                    }
                    .dkg-edit-mediabtn{
                        box-shadow: none;
                        color: #212529;
                        background-color: #d39e00;
                        border-color: #c69500;
                        margin: 0;
                        padding: 9px 15px;
                        border-radius: 5px;
                        width: 80px;

                    }
                }
            }
          }  
        }
    }
}
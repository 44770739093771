.dk-tabs-right-tabpane-12.addnewClass.dkg-hidewithoutselct {
    display: none;
}

.dk-tabs-right-tabpane-12.addnewClass.dkg-hidewithoutselct.activesSubTab {
    display: inherit;
}

.dkg-payment-qt-pageCon {
    padding-top: 8px;
    height: calc(100vh - 194px);

    &.dkg-what-is-mainCon {
        padding-right: 15px;

        &.dkg-roletype-pageCon {
            margin-top: 140px;
            padding-right: 0;
            background-color: #d9dfe7;

            .dkg-profies-linksCon {
                list-style: none;
                width: 100%;
                max-width: 1600px;
                margin: 0 auto;
                text-align: center;
                padding: 0;
                background: #fff;
                border-radius: 0 0 5px 5px;
                display: flex;

                .dkg-profile-link {
                    width: 100%;
                    text-align: center;
                    max-width: 9.12% !important;
                    color: #333;
                    padding: 11px 5px;
                    width: 100%;
                    text-decoration: none;
                    font-size: 14px;
                    height: 44px;
                    border-right: 1px solid #ddd;

                    &.active,
                    &:hover {
                        background-color: #d9dfe7;
                    }
                }
            }

            .dkg-maincategory-header {
                margin-top: 10px;
            }

            .dkg-payment-qt-tab-mainCon {

                height: calc(100vh - 225px) !important;
                background-color: #d9dfe7;

                .col-md-2 {
                    .dkg-payment-qt-tab-navpills {
                        height: calc(100vh - 238px) !important;

                        .dkg-payment-qt-tab-nav-item {
                            .dkg-payment-qt-tab-navlink {

                                &.active,
                                &:hover {
                                    background-color: #d9dfe7;
                                    color: #333;
                                }
                            }
                        }
                    }
                }

                .dkg-payment-qt-tabcontent {
                    .dkg-payment-qt-tabpane {
                        .dkg-payment-qt-rightpanel .dk-tabs-rightCols-12 .dk-tabs-right-detail-12 .dk-tabs-right-tabpane-12.addnewClass.activesSubTab .dk-rightDetail-panel-13 {
                            .dk-getStartedRight-detail-13 {
                                .dk-tabs-right-nav-13 {
                                    background-color: #d9dfe7;
                                }

                                .dk-tabs-right-tabcontent-13 {
                                    .wrapper-class.rdw-editor-wrapper {
                                        .editor-class.rdw-editor-main {
                                            height: calc(100vh - 325px) !important;
                                        }

                                    }
                                }
                            }
                        }
                    }

                    .dkg-payment-qt-tabpane .dkg-payment-qt-rightpanel .dk-tabs-rightCols-12 .dk-tabs-right-detail-12 .dk-tabs-right-tabcontent-13 .wrapper-class.rdw-editor-wrapper .editor-class.rdw-editor-main {
                        height: calc(100vh - 330px) !important;
                    }
                }

                @media only screen and (min-width: 768px) {
                    .col-md-10.dkg-payment-rt-tabCon-10 {
                        height: calc(100vh - 225px);
                    }
                }

            }
        }
    }

    .dkg-maincategory-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        width: 100%;
        .headerlist {
            padding-inline-start: 0;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: left;
            margin-left: 8px;
            margin-bottom: 0;
            max-width: 240px;
            .headeritem {
                list-style: none;

                .dk-resCrcleIconCon {
                    align-items: center;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    display: flex;
                    height: 24px;
                    justify-content: center;
                    width: 24px;
                    cursor: pointer;

                    svg {
                        width: 11px;
                        height: 11px;
                        cursor: pointer;
                        color: #7c7171;
                    }

                    &:hover {
                        outline: 0;
                        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                    }
                }

            }
        }
        .dkg-jobspec-header-centerCon{
            position: absolute;
            left: 42%;
            .dkg-jobpsec-DropdownCon{
                .dkg-jobpsec-Dropdown{
                    margin: 0;
                    .dkg-jobpsec-Dropdown-toggle{
                        background-color: transparent;
                        border: none;
                        box-shadow: none;
                        font-size: 1rem;
                        margin: 0;
                        min-width: 110px;
                        text-transform: capitalize;
                        min-width: 240px;
                        position: relative;
                        color: #2e97b6;
                        border: none !important;
                        padding: 0;
                        margin: 0;
                        &:after{
                            position: absolute;
                            right: 40px;
                            top: 11px;
                            color: #2e97b6;
                            margin-left: .355em;
                            vertical-align: .355em;
                            content: "";
                            border-top: .3em solid;
                            border-right: .3em solid transparent;
                            border-bottom: 0;
                            border-left: .3em solid transparent;
                        }
                    }
                    .dkg-jobpsec-Dropdown-Menu{
                        border-radius: 0;
                        width: 100%;
                        min-width: 180px;
                        padding: 0;
                        height: calc(100vh - 440px);
                        overflow-y: auto;
                        .dkg-jobpsec-Dropdown-Item{
                            border-bottom: 1px solid #ddd;
                            font-size: 13px;
                            padding: 7px 10px;
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .dkg-payment-qt-tab-mainCon {
        background-color: #eaeaea;
        // margin-top: 15px !important;
        border-top: none;
        height: calc(100vh - 175px);
        border-top: 1px solid #ddd;

        .col-md-2 {
            @media only screen and (max-width: 600px) {
                &.dkg-payment-qt-tabCon {
                    // flex: 0 0 19.666667%;
                    // max-width: 19.666667%;

                    &.dkg-prescreen-question-sidemenu-234 {
                        flex: 0 0 16.666667%;
                        max-width: 16.666667%;
                    }
                }
            }

            @media only screen and (min-width: 600px) {
                &.dkg-payment-qt-tabCon {
                    // flex: 0 0 19.666667%;
                    // max-width: 19.666667%;

                    &.dkg-prescreen-question-sidemenu-234 {
                        flex: 0 0 16.666667%;
                        max-width: 16.666667%;
                    }
                }
            }

            @media only screen and (min-width: 768px) {
                &.dkg-payment-qt-tabCon {
                    // flex: 0 0 19.666667%;
                    // max-width: 19.666667%;

                    &.dkg-prescreen-question-sidemenu-234 {
                        flex: 0 0 16.666667%;
                        max-width: 16.666667%;
                    }

                    &.dkg-profile-task-sideMenu {
                        flex: 0 0 15.666667%;
                        max-width: 15.666667%;
                    }
                }
            }

            .dkg-payment-qt-tab-navpills {
                margin-bottom: 0;
                background-color: #244158;
                display: flex;
                flex-direction: column;
                width: 100%;
                flex-wrap: wrap;
                max-height: calc(100vh - 179px);
                overflow-y: auto;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                flex-flow: column;
                height: 100%;
                .dkg-payment-qt-tab-nav-item {
                    width: 100%;
                    .dkg-payment-qt-tab-navlink {
                        // background-color: #eaeaea;
                        // border-radius: 0;
                        // color: #333;
                        // font-size: .95rem;
                        // padding: 0.65rem 1rem;
                        // text-align: center;
                        background: #244158;
                        border-bottom: 1px solid #3e5b73;
                        color: #ddd;
                        display: block;
                        font-family: Roboto, sans-serif !important;
                        font-size: 15px;
                        font-weight: 400;
                        padding: 11px 15px;
                        text-transform: inherit;
                        width: 100%;
                        border-radius: 0;

                        &.active,
                        &.show>.nav-link {
                            background-color: #eaeaea;
                            color: #333;
                        }
                    }
                }
            }
        }

        .col-md-10 {
            @media only screen and (max-width: 600px) {
                &.dkg-payment-rt-tabCon-10 {
                    flex: 0 0 84.333333%;
                    max-width: 84.333333%;

                    &.dkg-question-rightsidecon-234 {
                        flex: 0 0 83.333333%;
                        max-width: 83.333333%;
                    }

                    &.dkg-profile-task-rightCon {
                        flex: 0 0 84.333333%;
                        max-width: 84.333333%;
                    }
                }
            }

            @media only screen and (min-width: 600px) {
                &.dkg-payment-rt-tabCon-10 {
                    flex: 0 0 84.333333%;
                    max-width: 84.333333%;

                    &.dkg-question-rightsidecon-234 {
                        flex: 0 0 83.333333%;
                        max-width: 83.333333%;
                    }
                }
            }

            @media only screen and (min-width: 768px) {
                &.dkg-payment-rt-tabCon-10 {
                    flex: 0 0 86.333333%;
                    max-width: 86.333333%;
                    height: calc(100vh - 178px);

                    &.dkg-question-rightsidecon-234 {
                        flex: 0 0 83.333333%;
                        max-width: 83.333333%;
                    }

                    &.dkg-profile-task-rightCon {
                        flex: 0 0 83.333333% !important;
                        max-width: 83.333333% !important;
                    }
                }
            }
        }

        .dkg-payment-qt-tabcontent {
            .dkg-payment-qt-tabpane {
                .dkg-payment-qt-rightpanel {
                    position: relative;

                    .dkg-subcategory-header {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        position: relative;
                        border-bottom: 1px solid #ddd;
                        min-height: 40px;

                        .headerlist {
                            margin-bottom: 0;
                            padding-inline-start: 0;
                            display: flex;
                            align-items: center;
                            // width: 100%;
                            justify-content: flex-end;
                            margin: 0.625rem 0;
                            width: 100%;
                            // position: absolute;
                            // right: 18px;
                            // top: 10px;
                            // margin-left: 3rem;

                            .headeritem {
                                list-style: none;

                                .dk-resCrcleIconCon {
                                    align-items: center;
                                    background-color: #fff;
                                    border: 1px solid #ddd;
                                    border-radius: 50%;
                                    display: flex;
                                    height: 24px;
                                    justify-content: center;
                                    width: 24px;
                                    cursor: pointer;

                                    svg {
                                        width: 11px;
                                        height: 11px;
                                        cursor: pointer;
                                        color: #7c7171;
                                    }

                                    &:hover {
                                        outline: 0;
                                        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                    }
                                }

                            }

                            .title {
                                font-size: 15px;
                                font-weight: 500;
                                text-align: center;
                                text-transform: uppercase;
                                width: 100%;
                            }
                        }

                        .dkg-script-overview-title {
                            color: #eaeaea;
                            margin-bottom: 23px;
                        }
                        &.dkg-jobspec-subcat-cols{
                            width: inherit;
                            position: absolute;
                            top: -40px;
                            right: 0;
                            border: none
                        }
                    }

                    .title {
                        font-size: 15px;
                        font-weight: 500;
                        padding: 11px 0;
                        text-align: center;
                        text-transform: uppercase;
                        width: 100%;

                        &.dkg-page-title {
                            padding: inherit;
                            width: 300px;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }

                    .dk-moduleTraining-rightCols {
                        background: #fff;
                        border: 1px solid #ddd;
                        height: calc(100vh - 144px);
                        width: 100%;

                        .wrapper-class.rdw-editor-wrapper {
                            .rdw-editor-toolbar.toolbar-class {
                                margin-bottom: 0;

                                .rdw-inline-wrapper {
                                    .rdw-option-wrapper {
                                        max-width: 20px;
                                        height: 20px;

                                        &:nth-child(04),
                                        &:nth-child(05),
                                        &:nth-child(06),
                                        &:nth-child(07) {
                                            display: none;
                                        }
                                    }
                                }

                                .rdw-colorpicker-wrapper,
                                .rdw-link-wrapper {
                                    display: none;
                                }
                            }
                        }
                    }

                    .dk-tabs-rightCols-12 {
                        .dk-tabs-right-detail-12 {
                            .dk-tabs-right-tabcontent-13 {
                                .wrapper-class.rdw-editor-wrapper {
                                    .rdw-editor-toolbar.toolbar-class {
                                        div.rdw-image-wrapper {
                                            position: relative;

                                            .rdw-image-modal {
                                                position: absolute;
                                                left: -340px;
                                            }
                                        }
                                    }

                                    .editor-class.rdw-editor-main {
                                        background-color: #fff;
                                        height: calc(100vh - 345px);
                                        overflow-y: hidden;
                                        padding: 0 10px;
                                    }
                                }
                            }
                        }
                    }

                    // .dk-tabs-rightCols-12 {
                    //     .dk-tabs-right-detail-12 {
                    //         .dk-tabs-right-tabpane-12.addnewClass {
                    //             display: none;
                    //         }
                    //     }
                    // }

                    .dk-tabs-rightCols-12 {
                        .dk-tabs-right-detail-12 {
                            .dk-tabs-right-nav-12 {
                                position: absolute;
                                left: 0;
                                top: 10px;

                                .dk-tabs-right-navitem-12 {
                                    margin-right: 18px;

                                    .dk-tabs-right-navlink-12 {
                                        border-bottom: 3px solid transparent;
                                        box-shadow: none;
                                        color: #737373;
                                        font-family: Roboto, sans-serif !important;
                                        font-size: 16px;
                                        font-weight: 400;
                                        padding: 0 0 2px;
                                        text-transform: capitalize;

                                        &.active,
                                        &.activeSubTab {
                                            border-bottom: 3px solid #3c97b6;
                                            color: #3c97b6;
                                        }
                                    }
                                }
                            }

                            .dk-tabs-right-tabcontent-12 {
                                .dk-tabs-right-tabpane-12 {
                                    background: #fff;
                                    border: 1px solid #ddd;
                                    height: calc(100vh - 165px);
                                    width: 100%;

                                    .dk-rightDetail-panel-13 {
                                        .dk-getStartedRight-detail-13 {
                                            display: flex;

                                            .dk-tabs-right-nav-13 {
                                                width: 100%;
                                                max-width: 195px;
                                                min-width: 195px;
                                                background: #eaeaea;
                                                display: inline-block;
                                                height: calc(100vh - 168px);
                                                margin-right: 0;


                                                .dk-tabs-right-navitem-13 {
                                                    .dk-tabs-right-navlink-13 {
                                                        align-items: flex-start;
                                                        border-bottom: 1px solid #ddd;
                                                        color: #757575;
                                                        display: block;
                                                        flex-shrink: inherit;
                                                        font-size: 15px;
                                                        font-weight: 400;
                                                        max-width: 100%;
                                                        min-height: auto;
                                                        min-width: inherit !important;
                                                        opacity: 1;
                                                        outline: none;
                                                        overflow: visible;
                                                        padding: 11px 15px;
                                                        position: relative;
                                                        text-align: left;
                                                        text-transform: capitalize;
                                                        width: 100%;

                                                        &.active {
                                                            background: #fff;
                                                            color: #606060;
                                                        }
                                                    }
                                                }

                                                .dkg-script-subcategory {
                                                    align-items: flex-start;
                                                    border-bottom: 1px solid #ddd;
                                                    color: #757575;
                                                    display: block;
                                                    flex-shrink: inherit;
                                                    font-size: 15px;
                                                    font-weight: 400;
                                                    max-width: 100%;
                                                    min-height: auto;
                                                    min-width: inherit !important;
                                                    opacity: 1;
                                                    outline: none;
                                                    overflow: visible;
                                                    padding: 11px 15px;
                                                    position: relative;
                                                    text-align: left;
                                                    text-transform: capitalize;
                                                    width: 100%;

                                                    &.subcategory-active {
                                                        background: #fff;
                                                        color: #606060;
                                                    }
                                                }
                                            }

                                            .dk-tabs-right-tabcontent-13 {
                                                .dk-tabs-right-tabpane-13 {
                                                    padding: 24px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .dk-tabs-right-tabpane-12 {
                                &.addnewClass {

                                    &.activesSubTab {
                                        .dk-rightDetail-panel-13 {
                                            .dk-getStartedRight-detail-13 {
                                                display: flex;

                                                .dk-tabs-right-nav-13 {
                                                   background-color: #244158;
                                                    display: inline-block;
                                                    height: calc(100vh - 179px);
                                                    margin-right: 0;
                                                    max-width: 195px;
                                                    min-width: 195px;
                                                    width: 100%;
                                                    .dk-tabs-right-navitem-13.nav-item
                                                     {
                                                        .dk-tabs-right-navlink-13 {
                                                            background-color: #244158;
                                                            color: #ddd;
                                                            display: block;
                                                            font-size: 15px;
                                                            font-weight: 400;
                                                            max-width: 100%;
                                                            min-height: auto;
                                                            opacity: 1;
                                                            outline: none;
                                                            padding: 12px 9px;
                                                            position: relative;
                                                            text-align: left;
                                                            text-transform: inherit !important;
                                                            width: 100%;
                                                            border: none;
                                                            border-bottom: 1px solid #2e5070;
                                                            &.addActiveClass {
                                                                background: #eaeaea;
                                                                color: #757575;
                                                            }
                                                        }

                                                        // &:first-child {
                                                        //     .dk-tabs-right-navlink-13 {
                                                        //         border-top: 1px solid #ddd;
                                                        //     }
                                                        // }
                                                    }
                                                }

                                                .dk-tabs-right-tabcontent-13 {
                                                    font-family: Verdana,sans-serif;
                                                    width: 100%;
                                                    .dkg-jopspec-pageheader{
                                                        .dkg-jopspec-page-title{
                                                            font-size: 15px;
                                                            font-weight: 700;
                                                            padding: 12px 9px;
                                                            text-align: center;
                                                            text-transform: uppercase !important;
                                                            width: 100%;
                                                            color: #757575;
                                                            background-color: #eaeaea;

                                                        }
                                                    }
                                                    .wrapper-class.rdw-editor-wrapper {
                                                        .rdw-editor-toolbar.toolbar-class {
                                                            margin-bottom: 0;

                                                            .rdw-inline-wrapper {
                                                                .rdw-option-wrapper {
                                                                    max-width: 20px;
                                                                    height: 20px;

                                                                    &:nth-child(04),
                                                                    &:nth-child(05),
                                                                    &:nth-child(06),
                                                                    &:nth-child(07) {
                                                                        display: none;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .editor-class.rdw-editor-main {
                                                            background-color: #fff;
                                                            height: calc(100vh - 345px);
                                                            overflow-y: auto;
                                                            padding: 5px 15px;
                                                            padding-top: 0;
                                                            position: relative;
                                                            top: -5px;
                                                            // .DraftEditor-root{
                                                            //     .DraftEditor-editorContainer{
                                                            //         .public-DraftEditor-content{
                                                            //             div{
                                                            //                 div{
                                                            //                     .public-DraftStyleDefault-block{

                                                            //                     }
                                                            //                 }
                                                            //             }
                                                            //         }
                                                            //     }
                                                            // }
                                                        }
                                                    }
                                                    .dkg-mailbox-editor-mainCon {
                                                        width: 100%;
                                                        max-width: inherit !important;
                                                        margin: inherit !important;
                                                        padding: 0 15px;
                                                        padding-left: 30px;
                                                        .jodit-react-container {
                                                            margin-top: 0;

                                                            .jodit-container {
                                                                height: calc(100vh - 330px) !important;
                                                                width: 100% !important;
                                                                max-width: inherit !important;
                                                                overflow-y: hidden !important;
                                                                .jodit-workplace {
                                                                    height: inherit !important;
                                                                    .jodit-wysiwyg{
                                                                        p{
                                                                            strong{
                                                                                font-weight: 600 !important;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .jodit-status-bar {
                                                                    display: none;
                                                                }
                                                            }
                                                            // .jodit-container:not(.jodit_inline) .jodit-wysiwyg{

                                                            // }
                                                        }

                                                        .dkg-mail-sendbtn-Con {
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            width: 100%;
                                                            position: relative;
                                                            top: 54px;

                                                            div {
                                                                div {
                                                                    button {
                                                                        padding: 5px 17px;
                                                                        background: #cd5360;
                                                                        color: #fff;
                                                                        border-radius: 5px;

                                                                        &:hover {
                                                                            background: #27597e !important;
                                                                            transition: all 0.5s;

                                                                        }

                                                                        .MuiButton-label {
                                                                            color: #fff;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dkg-submit-btnCon{
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        width: 100%;
                                                        margin-top: 40px;
                                                        div{
                                                            div{
                                                                .dkg-cand-submit-btn {
                                                                    text-align: center;
                                                                    background-color: #2D96B6 !important;
                                                                    color: #fff !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }
        .dkg-payment-rt-tabCon-10.dkg-profile-task-rightCon{
            padding-left: 0;
            width: 100%;
        }
    }
}
.dk-adminMainHeader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

    .dk-adminNavbar {
        padding: 0 10px;

        .dk-nav-title {
            font-size: 17px;
            font-weight: 600;
            left: 50%;
            margin-top: 9px;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            font-family: "Open Sans", sans-serif;

            a {
                font-weight: 500;
            }
        }

        .dk-adminLogo {
            width: 100%;
            max-width: 165px;

            a {
                display: inline-block;

                img {
                    height: 36px;
                }
            }
        }

        .dk-mdHeader-rightPanel {
            display: flex;
            align-items: center;

            .dk-btnsClient {
                margin-right: 17px;

                a {
                    border-right: 1px solid #ddd;
                    padding: 15px 12px;
                    text-align: center;
                    color: #067185;
                    width: 100%;
                    font-size: 18px;
                }
            }

            .dk-nav-profile {
                .dropdown {
                    margin: 0;

                    .dropdown-toggle {
                        margin: 0 !important;
                        padding: 0;
                        box-shadow: none;
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        border: 1px solid #ccc;
                        font-size: 14px;
                        border-radius: 50%;
                    }

                    .dk-userIcon {
                        display: inline-block;
                        margin: 0 8px;
                    }

                    .dropdown-img {
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        display: inline-block;
                        background: #fff;
                        border: 1px solid #ccc;
                        padding: 3px;

                        img {
                            border-radius: 50%;
                        }
                    }

                    .dropdown-menu {
                        padding: 0;
                        border-radius: 0;
                        border: none;
                        margin-top: 7px !important;
                        z-index: 999;
                        box-shadow: -1px 3px 5px rgba(0, 0, 0, 0.3);
                        border: 1px solid #ddd;

                        a {
                            display: block;
                            padding: 8px 10px;
                            border-radius: 0;
                            border-bottom: 1px solid #ddd;
                            font-family: 'Roboto';

                            &:last-child {
                                border-bottom: 0;
                            }

                            &:hover {
                                background: #f2f2f2;
                            }
                        }
                    }
                }
            }
        }
    }
}
.dkg-edittitle-modal {
    .modal-dialog {
        min-width: 600px;
        height: 600px;

        .modal-content {
            border-radius: 5px;
            background-color: transparent;

            .dkg-edittitle-modalHeader {
                border-radius: 5px 5px 0 0;
                background-color: #234158;
                color: #fff;
                padding: 8px 10px;

                .modal-title {
                    color: #fff;
                    font-weight: 700;
                    font-size: 18px;
                    width: 100%;
                    text-align: center;


                }

                .close {
                    margin: 0;
                    padding: 8px 10px;
                    text-shadow: none;
                    color: #fff;
                    position: absolute;
                    right: 0;
                    top: 4px;
                    opacity: 1;

                    &:hover {
                        opacity: 0.85;
                    }
                }
            }

            .dkg-edittitle-modalBody {
                border-radius: 0 0 5px 5px;
                background-color: #fff;

                .form-group {
                    label {
                        width: 100%;

                        input.form-control {
                            border: 1px solid #ddd;
                            text-align: left;
                            width: 100%;
                        }
                    }
                }

                .dkg-edittitle-updatebtnCn {
                    div {
                        div {
                            .dk-userAddBtn {
                                background-color: #234158;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
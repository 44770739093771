$toggle-width: 5rem;
$slider-size: 2rem;
$padding: 0.25rem;
.dkg-candidate-acc-mainCon{
    margin-top: 140px;
    padding:  15px;
    width: 100%;
    height: calc(100vh - 140px);
    background-color: #eaeaea;
    .dkg-candidate-acc-header{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .dkg-cand-acc-leftCon{
            display: flex;
            align-items: center;
            .dk-SrchInputBox{
                position: relative;
               .input-group{
                width: 100%;
                max-width: 180px;
                min-width: 180px;
                input{
                    background-color: #fff !important;
                    margin: 0;
                    box-shadow: none;
                    border: 1px solid #ddd;
                    line-height: 1.3rem;
                    height: 35px;
                    border-radius: 0;
                    text-align: left;
                }
                .input-group-append{
                    margin-left: -1px;
                    button{
                        padding: 0.5rem 0.55rem;
                        background-color: #fff !important;
                        margin: 0;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        line-height: 1.3rem;
                        height: 35px;
                        border-radius: 0;
                        i{
                            color: #999;
                        }
                        &:hover{
                            background-color: #f2f2f2 !important;
                            box-shadow: 0 5px 11px 0 #0000002e, 0 4px 15px 0 #00000026;
                            outline: 0;
                        }
                    }
                }
               }
               .dkg-crossicon-Con{
                i{
                    position: absolute;
                    top: -7px;
                    right: -9px;
                    z-index: 9;
                }
               }
            }
            .dkg-plusicon-Con{
               .dkg-plusicon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #fff !important;
                color: #999;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;
                &:hover{
                    box-shadow: 0 5px 11px 0 #0000002e, 0 4px 15px 0 #00000026;
                    outline: 0;
                }
               }
            }
        }
        .dkg-cand-acc-centreCon{
            width: 100%;
            text-align: center;
            .title{
                margin-top: 7px;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                text-align: center;
            }
        }
        .dkg-cand-acc-rightCon{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dkg-plusicon-Con{
                .dkg-plusicon{
                 display: flex;
                 justify-content: center;
                 align-items: center;
                 width: 35px;
                 height: 35px;
                 line-height: 35px;
                 background: #fff !important;
                 color: #999;
                 border: 1px solid #ddd;
                 border-radius: 50%;
                 outline: none;
                 box-shadow: none;
                 &:hover{
                    box-shadow: 0 5px 11px 0 #0000002e, 0 4px 15px 0 #00000026;
                    outline: 0;
                 }
                }
             }
            button.dk-reoderBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #fff !important;
                color: #999;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;
                svg{
                    width: 16px;
                    height: 16px;
                }
            }
            .dkg-casestus-filter{
                position: relative;
                .dropdownFilter.dropdown{
                    .dropdown-toggle {
                        margin: 0;
                        padding: 0.5rem 0.45rem;
                        box-shadow: none;
                        background-color: #fff !important;
                        text-transform: capitalize;
                        min-width: 145px;
                        font-size: 13px;
                        color: #999;
                        border: 1px solid #ddd;
                        &::after{
                            position: absolute;
                            right: 10px;
                            top: 17px;
                        }
                    }
                    .dropdown-menu{
                        padding: 0;
                        border-radius: 0;
                        width: 100%;
                        .dropdown-item{
                            padding: 9px 10px;
                            font-size: 13px;
                            width: 100%;
                            text-align: left;
                            position: relative;
                            border-bottom: 1px solid #ddd;
                            .dropdown-filter-count{
                                position: absolute;
                                right: 10px;
                                top: 9px;
                                background-color: #3c4c62;
                                border-radius: 2px;
                                color: #fff;
                                padding: 0px 5px;
                                font-size: 12px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                            }
                            .dkg-year-counter{
                                position: absolute;
                                right: 10px;
                                top: 9px;
                                color: #3c4c62;
                                font-size: 13px;
                                font-weight: bold;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
                .crossUserFilter {
                    color: #000;
                    cursor: pointer;
                    font-size: 15px!important;
                    position: absolute;
                    right: -8px;
                    top: -5px;
                }
            }
        }
    }
    .dkg-candidate-acc-tableCon{
        margin-top: 6px;
        height: calc(100vh - 220px);
        overflow-x: auto !important;
        overflow-y: hidden !important;
        .dkg-candidate-acc-table{
            // min-width: 1800px;
            width: 100%;
            .thead,
            thead{
                .tr,
                .tr{
                    display: flex;
                    align-items: center;
                    .th,
                    th{
                        padding: 10px 0.75rem !important;
                        font-size: 13px !important;
                        vertical-align: middle;
                        border: 1px solid #8a8a8a !important;
                        text-align: center;
                        font-weight: normal;
                        white-space: nowrap;
                        color: #fff !important;
                        background-color: #26597d !important;
                        border-right: none !important;
                        border-bottom: none !important;
                        &:nth-child(01){
                            min-width: 150px !important;
                            max-width: 150px !important;
                        }
                        &:nth-child(02),
                        &:nth-child(03){
                            min-width: 140px !important;
                            max-width: 140px !important;
                        }
                        &:nth-child(04) {
                            min-width: 130px !important;
                            max-width: 130px !important;
                            display: none;
                        }
                        &:nth-child(05) {
                            min-width: 160px !important;
                            max-width: 160px !important;
                        }

                        &:nth-child(06) {
                            min-width: 100px !important;
                            max-width: 100px !important;
                        }
                        &:nth-child(07) {
                            min-width: 280px !important;
                            max-width: 280px !important;
                        }

                        &:nth-child(08) {
                            min-width: 420px !important;
                            max-width: 420px !important;
                        }
                        &:nth-child(09) {
                            display: none;
                            min-width: 140px !important;
                            max-width: 140px !important;
                        }

                        &:nth-child(10) {
                            min-width: 160px !important;
                            max-width: 160px !important;
                        }

                        &:nth-child(11) {
                            min-width: 160px !important;
                            max-width: 160px !important;
                        }

                        &:nth-child(12) {
                            min-width: 240px !important;
                            max-width: 240px !important;
                        }


                        &:nth-child(13),
                        &:nth-child(14),
                        &:nth-child(15),
                        &:nth-child(16),
                        &:nth-child(17),
                        &:nth-child(18),
                        &:nth-child(19)
                        {
                            display: none;
                        }

                    }
                }
            }
            .tbody,
            tbody{
                div{
                    // position: inherit !important;
                    // height: inherit !important;
                    // width: 93% !important;
                    // overflow: inherit !important;
                    height: calc(100vh - 265px) !important;
                    overflow-y: auto !important;
                    overflow-x: hidden !important;
                    div{
                        // position: inherit !important;
                        // height: inherit !important;
                        // width: inherit !important;

                        .tr{
                            display: flex;
                            align-items: center;
                            // position: inherit !important;
                            //  top: inherit !important;
                            // left: inherit !important;
                            // height: 36px !important;
                            // width: inherit !important;
                            height: 36px !important;
                            .td{
                                padding: 5px 0.75rem !important;
                                text-align: center;
                                height: 50px;
                                line-height: 40px;
                                font-size: 13px !important;
                                vertical-align: middle;
                                color: #e2e2e2 !important;
                                white-space: nowrap;
                                border: 1px solid #8a8a8a !important;
                                background-color: #262f3c;
                                border-right: none !important;
                                border-bottom: none !important;
                                .dkg-view-acc{
                                    cursor: pointer;
                                        padding: 0!important;
                                        font-size: 14px;
                                        width: 28px;
                                        height: 28px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        margin: 0 auto;
                                        border-radius: 2px!important;
                                        border: 1px solid #999!important;
                                        &:hover{
                                            background-color: #26597d;
                                            border-color: #26597d;
                                        }
                                    svg{
                                        width: 14px;
                                        height: 14px;
                                    }

                                }
                                .dkg-feature-column{
                                    position: relative;
                                    top: 1px;
                                    z-index: 9;
                                }
                                .dkg-account-acmainCon{
                                    .dkg-feature-column{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        label{
                                            margin-bottom: 0;
                                        }
                                    }
                                    .react-switch-checkbox {
                                        height: 0;
                                        width: 0;
                                        visibility: hidden;
                                        &:checked + .react-switch-label .react-switch-button {
                                          left: 100%;
                                          transform: translateX(-100%);
                                          background-color: #02a786;
                                        }
                                      }
                                      .react-switch-label {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        cursor: pointer;
                                        width: 40px;
                                        height: 20px;
                                        background-color: #ddd;
                                        border-radius: 25px;
                                        position: relative;
                                        transition: background-color 0.2s;
                                        &:active .react-switch-button {
                                          width: 40px;
                                        }
                                        & .react-switch-button {
                                          content: '';
                                          position: absolute;
                                          top: 0;
                                          left: 0;
                                          width: 50%;
                                          height: 100%;
                                          border-radius: 25px;
                                          transition: 0.2s;
                                          background-color: #da3d5e;
                                        }
                                    }
                                }
                                .dkg-user-dropdownCon{
                                    .dkg-user-dropdown{
                                        .dkg-user-dropdown-toggle{
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            box-shadow: none;
                                            padding: 0;
                                            margin: 0;
                                            .dkg-user-img{
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 50%;
                                                border: 1px solid #ddd;
                                                object-fit: cover;
                                            }
                                            &::after{
                                                display: none;
                                            }
                                        }
                                        .dkg-user-dropdownmenu{
                                            background-color: #26597d;
                                            border: 1px solid #26597d;
                                            left: 22px!important;
                                            padding: 5px;
                                            top: -10px!important;
                                            transform: translate(22px)!important;
                                            -webkit-transform: translate(22px)!important;
                                            .d-flex{
                                                .dkg-user-dropdown-item{
                                                    display: inline-block;
                                                    height: 30px;
                                                    margin: 0 3px;
                                                    padding: 0;
                                                    width: 30px;
                                                    .dkg-user-img{
                                                        width: 30px;
                                                        height: 30px;
                                                        border-radius: 50%;
                                                    }
                                                }
                                            }
                                            &:after {
                                                border-color: transparent #26597d transparent transparent;
                                                _border-color: #000 #ccc #000 #000;
                                                border-style: solid;
                                                border-width: 8.5px 12px 8.5px 0;
                                                content: "";
                                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                                height: 0;
                                                left: -12px;
                                                line-height: 0;
                                                position: absolute;
                                                top: 15px;
                                                width: 0;
                                            }
                                        }
                                    }
                                }
                                .dkg-statusDrodownCon{
                                    .dkg-statusDrodown{
                                        .dkg-statusdropdwn-toggle{
                                            margin: 0;
                                            // box-shadow: none;
                                            background-color: #3a9469;
                                            padding: 4px 15px;
                                            font-size: 14px;
                                            text-transform: capitalize;
                                            &::after{
                                                display: none;
                                            }
                                            &:hover{
                                                color: #fff;
                                            }
                                        }
                                        .dkg-statusdropdwn-menu{
                                            width: 100%;
                                            border-radius: 0;
                                            padding: 0;
                                            min-width: 100px;
                                            max-width: 100px;
                                            .dkg-status-dropitem{
                                                padding: 5px 9px;
                                                text-align: left;
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                }
                                .dkg-comment-con{
                                   background-color: #17a2b8;
                                    border: 0;
                                    cursor: pointer;
                                    font-weight: 600;
                                    display: inline-block;
                                    color: #fff!important;
                                    padding: 4px 15px;
                                }
                                .dkg-active-commentCon{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 100%;
                                    width: 100%;
                                    .dkg-number-badge{
                                        width: 25px;
                                        height: 25px;
                                        background-color: #26597d;
                                        color: #fff;
                                        border-radius: 50%;
                                        line-height: 25px;
                                        cursor: pointer;
                                        &:hover{
                                            background-color: #fff;
                                            color: #333;
                                        }
                                    }
                                }
                                .dkg-candacc-action-cols{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    a{
                                        svg{
                                            width: 14px;
                                            height: 14px;
                                        }
                                        &.dkg-view-icon{
                                            cursor: pointer;
                                            width: 22px;
                                            height: 22px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            color: #333;
                                            background-color: #ffc107;
                                            border: 1px solid #ffc107;
                                        }
                                        &.dkg-delete-icon{
                                            cursor: pointer;
                                            width: 22px;
                                            height: 22px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            background-color: #dc3545;
                                            border: 1px solid #dc3545;
                                            color: #fff;
                                        }
                                    }
                                }
                                .dkg-active-acc-serNo{
                                    display: flex;
                                    align-items: center;
                                    .dk-smsTableCheckbox{
                                        margin-left: 0.8rem;
                                        margin-right: 0.35rem;
                                        position: relative;
                                        input[type=checkbox] {
                                            position: absolute;
                                            top: -6px;
                                            right: -13px;
                                            cursor: pointer;
                                            appearance: none;
                                            background: #eaeaea;
                                            border-radius: 1px;
                                            box-sizing: border-box;
                                            width: 14px;
                                            height: 14px;
                                            border-width: 0;
                                            transition: all 0.3s linear;
                                            border: solid #ddd 1px;
                                            z-index: 0;
                                        }
                                    }
                                }
                                .dk-smsTableCheckbox{
                                    margin-left: 0.35rem;
                                    margin-right: 0.35rem;
                                    position: relative;
                                    top: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 130px;
                                
                                    .dkg-jobseeker-acc-chkCon{
                                        input[type=checkbox]{
                                            -webkit-appearance: none;
                                            appearance: none;
                                            background: #eaeaea;
                                            border: 1px solid #ddd;
                                            border-radius: 1px;
                                            box-sizing: border-box;
                                            cursor: pointer;
                                            height: 14px;
                                            position: relative;
                                            left: 0;
                                            top: 2px;                                        
                                            transition: all .3s linear;
                                            width: 14px;
                                            z-index: 0;
                                            &:checked {
                                                background-color: #34976e!important;
                                            }
                                        }
                                    }
                                    .dkg-cand-info-icon{
                                        color: #fff;
                                        cursor: pointer;
                                    }
                                    .dkg-active-account{
                                        // width: 45px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        display: none;
                                        .dkg-active-editicon,
                                        .dkg-active-deleteicon{
                                            i{
                                                color: #fff;
                                                cursor: pointer;
                                                &:hover{
                                                    opacity: 0.7;
                                                }
                                            }
                                        }
                                    }
                                    .dkg-active-editicon{
                                        svg{
                                            width: 1.25rem;
                                            height: 1.25rem;
                                            color: #fff;
                                            &:hover{
                                                opacity: 0.7;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                                .dk-smsMessage-selectMsg{
                                    background: #fff;
                                    border-radius: 10px;
                                    box-shadow:0 22px 104px -6px #00000057;
                                    width: 100%;
                                    max-width:800px;
                                    margin: auto;
                                    position: fixed;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    display: none;
                                    height: 63px;
                                    z-index: 999;
                                    .dk-selectNo{
                                        width: 100%;
                                        max-width:63px;
                                        background:#34976e;
                                        color: #fff;
                                        font-weight: 500;
                                        font-size:30px;
                                        border-radius: 5px 0 0 5px;
                                        height: 63px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                    .dk-rowSelected{
                                        width: 100%;
                                        font-weight:300;
                                        font-size: 22px;
                                        padding-left: 15px;
                                        border-bottom: 1px solid #ddd;
                                        padding-bottom:5px;
                                        color: #333;
                                        .dk-selectedDot{
                                            line-height:8px;
                                            span.dk-circle{
                                                display: inline-block;
                                                width: 8px;
                                                height: 8px;
                                                background:#016e42;
                                                border-radius: 50%;
                                                margin-left: 1px;
                                            }
                                        }
                                    }
                                    .dk-checkAll{
                                        width: 200px;
                                        height: 63px;
                                        border-left: 1px solid #ddd;
                                        text-align: center;
                                        font-weight: 400;
                                        padding: 12px 0;
                                        cursor: pointer;
                                        color: #333;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        flex-direction: column;
                                        white-space: nowrap;
                                        i{
                                            font-size: 17px;
                                        }
                                        span{
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            &.dkg-trash-span{
                                                position: relative;
                                                top: 7px;
                                            }
                                        }
                                        &:last-child:hover{
                                            border-top-right-radius: 10px;
                                            border-bottom-right-radius: 10px;
                                            border: 1px solid #ddd;
                                        }
                                        &.delete-check-tab{
                                            background-color: red;
                                            color: #fff;
                                            &:hover{
                                                color: red;
                                            }
                                        }
                                        &:hover{
                                            background-color: #d9d9d9;
                                            color: #333;
                                        }
                                        
                                    }
                                }
                                .dk-smsMessage-selectMsg.activePopup {
                                    display: block;
                                }
                                .dkg-casestage-dropdownCon{
                                    .dkg-casestage-dropdown{
                                        height: inherit !important;
                                        overflow: inherit !important;
                                        .dkg-casestage-dropdown-toggle{
                                            background-color: transparent;
                                            box-shadow: none;
                                            color: #fff;
                                            font-size: 13px;
                                            margin: 0;
                                            padding: 0!important;
                                            text-transform: capitalize;
                                            white-space: nowrap;
                                            width: 100%;
                                            .caseDiv {
                                                cursor: pointer;
                                                height: 50px !important;
                                                line-height: 50px !important;
                                                padding: 0 7px;
                                            }
                                            &::after{
                                                display: none;
                                            }
                                        }
                                        .dkg-casestage-dropdown-menu{
                                            border-radius: 0;
                                            padding: 0;
                                            -webkit-transform: translate(-1px,35px)!important;
                                            transform: translate(-1px,35px)!important;
                                            height: 120px !important;
                                            border: none;
                                            .dkg-stage-dropdown-item{
                                                border-bottom: 1px solid #aaa;
                                                font-size: 13px;
                                                padding: 6px 10px;
                                                text-align: left;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                                height: 40px;
                                                line-height: 26px;
                                                &:last-child{
                                                    border-bottom: none !important;
                                                }
                                            }
                                        }
                                    }
                                }
                                .dkg-cand-account-status{
                                    display: inline-block;
                                    width: 90px;
                                    height: 35px;
                                    line-height: 35px;
                                    box-shadow: none;
                                    text-transform: capitalize;
                                    font-size: 13px;
                                    border: 1px solid transparent;

                                }
                                &:nth-child(01){
                                        min-width: 150px !important;
                                        max-width: 150px !important;
                                        display: flex;
                                        align-items: center;
                                }
                                &:nth-child(02),
                                &:nth-child(03){
                                    min-width: 140px !important;
                                    max-width: 140px !important;
                                }
                                &:nth-child(04) {
                                    min-width: 130px !important;
                                    max-width: 130px !important;
                                    padding: 0 !important;
                                    display: none;
                                }
                                &:nth-child(05) {
                                    min-width: 160px !important;
                                    max-width: 160px !important;
                                }

                                &:nth-child(06) {
                                    min-width: 100px !important;
                                    max-width: 100px !important;
                                }
                                &:nth-child(07) {
                                    min-width: 280px !important;
                                    max-width: 280px !important;
                                }
                                &:nth-child(08) {
                                    min-width: 420px !important;
                                    max-width: 420px !important;

                                }

                                &:nth-child(09) {
                                    display: none;
                                    min-width: 140px !important;
                                    max-width: 140px !important;
                                }

                                &:nth-child(10) {
                                    min-width: 160px !important;
                                    max-width: 160px !important;
                                }

                                &:nth-child(11) {
                                    min-width: 160px !important;
                                    max-width: 160px !important;
                                }

                                &:nth-child(12) {
                                    min-width: 240px !important;
                                    max-width: 240px !important;
                                }


                                &:nth-child(13),
                                &:nth-child(14),
                                &:nth-child(15),
                                &:nth-child(16),
                                &:nth-child(17),
                                &:nth-child(18),
                                &:nth-child(19)
                                {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .dkg-cand-loader-Con{
            height: calc(100vh - 220px);
            width: 100%;
            display: flex;
            justify-content: center;
            .dkg-candidate-acc-table{
                // min-width: 1800px;
                width: 100%;
                thead{
                    tr{
                        display: flex;
                        align-items: center;
                        th{
                            padding: 10px 0.75rem !important;
                            font-size: 13px !important;
                            vertical-align: middle;
                            border: 1px solid #8a8a8a !important;
                            text-align: center;
                            font-weight: normal;
                            white-space: nowrap;
                            color: #fff !important;
                            background-color: #26597d !important;
                            border-right: none !important;
                            border-bottom: none !important;
                            &:nth-child(01){
                                min-width: 160px !important;
                                max-width: 160px !important;
                            }
                            &:nth-child(02){
                                min-width: 140px !important;
                                max-width: 140px !important;
                            }
                            &:nth-child(03) {
                                min-width: 130px !important;
                                max-width: 130px !important;
                            }
                            &:nth-child(04) {
                                min-width: 140px !important;
                                max-width: 140px !important;
                            }
    
                            &:nth-child(05) {
                                min-width: 80px !important;
                                max-width: 80px !important;
                            }
                            &:nth-child(06) {
                                min-width: 230px !important;
                                max-width: 230px !important;
                            }
    
                            &:nth-child(07) {
                                min-width: 320px !important;
                                max-width: 320px !important;
                            }
                            &:nth-child(08) {
                                min-width: 140px !important;
                                max-width: 140px !important;
                            }
    
                            &:nth-child(09) {
                                min-width: 160px !important;
                                max-width: 160px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .dk-resPaginationCon {
        &.pagination{
            .dk-resPagination{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .dk-resLeftPag{
                    .dk-texCounter{
                        color: #333 !important;
                        z-index: 999;
                        font-size: 13px;
                    }
                }
                .dk-resrightPag{
                    &.pagination-btns{
                        nav{
                            ul {
                                margin-top: 0 !important;
                                li {
                                    min-width: auto !important;
                                    width: auto !important;
                                    margin: 0 !important;
                                    border: 0px !important;
                                    border-radius: 0 !important;
                                    button.MuiPaginationItem-page {
                                        transition: none;
                                        border: 1px solid #ddd;
                                        border-top: none;
                                    }
                                    button.Mui-selected,
                                    button:hover{
                                        background: #2c394b;
                                        color: #fff;
                                        height: 30px;
                                        width: 30px;
                                        line-height: 26px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.dk-editCompanyList-modal {
    .modal-dialog {
        min-width: 95% !important;
        max-width: 95% !important;
        margin: 0 !important;
        float: right;

        .modal-content {
            box-shadow: none;
            border: none;
            font-family: "Segoe UI", Arial, sans-serif !important;
            border-radius: 0;

            .modal-header {
                padding: 6px 12px;
                border-bottom: none;
                background: #26597d;

                span.dk-idType {
                    white-space: nowrap;
                    color: #fff;
                    font-weight: 500;
                    position: absolute;
                    left: 15px;
                    top: 9px;
                    font-size: 14px;
                }

                .modal-title {
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 700;
                    color: #fff;
                }

                .close {
                    outline: none;
                    position: absolute;
                    right: 14px;
                    top: 6px;
                    opacity: 1;
                    margin: 0;
                    padding: 0;
                    font-size: 22px;
                    text-shadow: none;
                    color: #fff;

                    span {
                        font-size: 24px !important;
                    }

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .modal-body {
                padding: 15px;
                height: calc(100vh - 40px);

                .dk-addNClientFormCont {
                    background: #D9DFE7;
                    border-radius: 5px;
                    padding: 15px;
                    height: calc(100vh - 100px);

                    button {
                        text-transform: unset;
                    }

                    .ddl-main.dropdown {
                        .dropdown-toggle {
                            margin: 0;
                            padding: 0;
                            box-shadow: none;
                            width: 100%;
                            background: #fff;
                            width: 100%;
                            height: 32px;
                            font-size: 13px;

                            &::after {
                                display: block;
                                position: absolute;
                                right: 10px;
                                font-size: 20px;
                                top: 13px;
                                color: #919191;
                            }
                        }

                        .dropdown-menu {
                            input {
                                text-align: center;
                            }

                            border-radius: 0;
                            width: 100%;
                            margin: 0 !important;
                            top: 33px !important;
                            transform: inherit !important;
                            padding: 2px;

                            a {
                                display: block;
                                padding: 6px 12px;
                                font-size: 14px;
                                color: #333;
                                border-bottom: 1px solid #ddd;
                                background: #f2f2f2;

                                &:hover {
                                    background: #fff;
                                    transition: all 0.5s;
                                }

                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                    }

                    .form-group {
                        margin-bottom: 0.5rem;

                        .ui.selection.dropdown.dk-overdues-task-slect {
                            text-transform: inherit;
                            color: #333;

                            .default.text {
                                color: #333;
                            }
                        }

                        .dk-nativedatepicker-grid {
                            .dk-nativedatepicker {
                                margin-top: 0;
                                width: 100%;
                                margin-bottom: 0;
                                background: #fff;
                                border: 1px solid #ddd !important;
                                padding: 0;

                                .MuiInput-underline {
                                    &::before {
                                        display: none;
                                    }

                                    &::after {
                                        display: none;
                                    }
                                }

                                .MuiInputBase-root.MuiInput-root {
                                    input {
                                        text-align: center;
                                        font-size: 13px;
                                    }

                                    .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                        .MuiButtonBase-root.MuiIconButton-root {
                                            padding: 4px 2px;
                                            background-color: #139bd6;
                                            border-radius: 0;
                                            color: #fff;
                                        }
                                    }
                                }

                            }
                        }

                        label {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            margin-bottom: 0;
                            font-size: 14px;
                            font-weight: 400;
                            color: #000;
                            white-space: nowrap;
                            min-width: 100px;
                            line-height: 25px;
                            div{
                                div{
                                    .dk-taskCreateBtn.MuiButton-containedPrimary {
                                        background: #165a77 !important;
                                        box-shadow: none;
                                        color: #fff !important;
                                        font-size: 14px;
                                        outline: none;
                                        padding: 8px 20px 7px;
                                        &:hover{
                                            background: #139bd6;
                                            transition: all .5s;
                                        }
                                    }
                                }
                            }
                        }

                        label.dk-longllabel {
                            min-width: 125px;
                            position: relative;
                            bottom: 2px;
                        }

                        input.form-control {
                            width: 100%;
                            height: 32px;
                            padding: 4px 6px;
                            font-size: 13px;
                            line-height: 1.42857143;
                            color: #000 !important;
                            border: 1px solid #ced4da;
                            background: #fff;
                            border-top-color: #ced4da;
                            outline: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            border-radius: 0;
                            box-shadow: none !important;

                            // &.dkg-calenderinput-type {
                            //     // -webkit-appearance: none;
                            //     // -moz-appearance: none;
                            //     background: transparent;
                            //     background-image: url("../../../../assets//images/calendar.png");
                            //     background-repeat: no-repeat;
                            //     background-position-x: 100%;
                            //     background-position-y: 5px;
                            //     border: 1px solid #dfdfdf;
                            //     border-radius: 2px;
                            //     margin-right: 2rem;
                            //     padding: 1rem;
                            //     padding-right: 2rem;
                            // }
                        }

                        // input.form-control.dkg-calenderinput-type {
                        //     -moz-appearance: none;
                        //     -webkit-appearance: none;
                        //     appearance: none;
                        //     background: transparent;
                        //     background-image: url("../../../../assets//images/calendar.png");
                        //     background-repeat: no-repeat;
                        //     background-position-x: 100%;
                        //     background-position-y: 5px;
                        //     border: 1px solid #dfdfdf;
                        //     border-radius: 2px;
                        //     margin-right: 2rem;
                        //     padding: 1rem;
                        //     padding-right: 2rem;
                        // }

                        // input[type=date]::-webkit-calendar-picker-indicator{
                        //     -moz-appearance:none; 
                        //     -webkit-appearance:none;
                        //     appearance:none;
                        // }
                        // input[type="date"]::-webkit-calendar-picker-indicator {
                        //     display: none;
                        //     -webkit-appearance: none;
                        // }
                        input[type="date"]::-webkit-calendar-picker-indicator {
                            -webkit-appearance: none !important;
                            -moz-appearance: none;
                            appearance: none;
                        }

                        span.dk-clViewAddonIcon.arrowDown {
                            font-size: 20px;

                            svg {
                                position: relative;
                                font-size: 15px;
                                top: 1px;
                            }
                        }

                        span.dk-clViewAddonIcon {
                            border: 1px solid #139BD6;
                            padding: 0;
                            background-color: #139BD6;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 25px;
                            min-width: 25px;
                            max-width: 25px;
                            height: 32px;
                            color: #fff;

                            i {
                                color: #fff;
                            }
                        }

                        .dk-createBtn {
                            display: inline-block;
                            background: #165a77;
                            box-shadow: none;
                            outline: none;
                            color: #fff;
                            padding: 8px 25px 8px;
                            font-size: 14px;
                            border: none;
                            text-transform: uppercase;
                            font-weight: 500;
                            border-radius: 5px;
                            outline: none;
                        }
                    }
                }

                .dkaddNewRightCon {
                    height: calc(100vh - 100px);
                    background: #D9DFE7;
                    border-radius: 5px;
                    padding: 15px;
                    margin-top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .addNewBox {
                        .dk-FileUploadCon {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .dk-fileUldimgCon {
                                width: 300px;
                                max-width: 250px;
                                height: 180px;
                                margin: 0 auto;
                                border: 3px dashed lightslategrey;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                h3 {
                                    text-transform: uppercase;
                                    color: #324148;
                                    padding: 40px 0;
                                    font-size: 1.4rem;
                                    font-weight: 400;
                                }
                            }
                        }
                        .dk-browsedFile {
                            position: relative;
                    
                            input {
                                position: absolute;
                                bottom: 0;
                                z-index: 9;
                                cursor: pointer;
                                width: 100%;
                                height: 41px;
                                opacity: 0;
                            }
                    
                            button {
                                cursor: pointer;
                                position: relative;
                                z-index: 1;
                            }
                        }
                    
                        .dk-uploadView-img {
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            margin: 15px 0 0;
                            background: #fff;
                            border-radius: 5px;
                            padding: 10px;
                            position: relative;
                    
                            img {
                                width: 100%;
                                max-width: 160px;
                                margin: auto;
                            }
                    
                            span.close-btn {
                                display: inline-block;
                                width: 15px;
                                height: 15px;
                                border-radius: 50%;
                                background: #000;
                                color: #fff;
                                line-height: 15px;
                                text-align: center;
                                font-size: 10px;
                                position: absolute;
                                top: -3px;
                                right: -3px;
                                cursor: pointer;
                                svg{
                                    position: relative;
                                    top: -2px;
                                }
                            }
                        }
                    
                        & img {
                            width: 100%;
                            height: auto;
                        }
                    
                        & button {
                            margin-top: 20px;
                            width: 250px;
                            background-color: #ddd;
                            color: #fff;
                            background: #139BD6;
                            border: none;
                            padding: 10px;
                            border-radius: 4px;
                            transition: all .2s ease;
                            outline: none;
                            text-transform: uppercase;
                            font-weight: 700;
                            position: relative;
                    
                            input {
                                opacity: 0;
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 35px;
                                cursor: pointer;
                            }
                        }
                    
                        & button:hover {
                            background: #165a77;
                            color: #ffffff;
                            transition: all .2s ease;
                            cursor: pointer;
                        }
                    }
                }

                &.dkg-listview-AddNewBody {
                    overflow-y: auto;

                    .dk-cliAdNewPageCont {
                        padding: 0 !important;
                        margin-top: 0 !important;

                        .row {
                            div {
                                .dk-clntsPageTitle {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dk-editCompanyList-modal.fade .modal-dialog {
    right: -95%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dk-editCompanyList-modal.show .modal-dialog {
    right: 0;
}
.dkg-script-modal-2345 {
    z-index: 9999;

    .modal-dialog {
        min-width: 500px;
        max-width: 500px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .modal-content {
            .modal-header {
                padding: 8px 10px;
                background-color: #244158;
                color: #fff;
                border-radius: 5px 5px 0 0;

                .modal-title {
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                }

                .close {
                    padding: 0;
                    position: absolute;
                    right: 16px;
                    top: 10px;
                    margin: 0;
                    opacity: 1;
                    text-shadow: none;
                    color: #fff;
                    font-size: 25px;
                    outline: none;
                }
            }

            .modal-body {
                form {
                    .form-group {
                        margin-bottom: 1.25rem;

                        label {
                            width: 100%;
                            text-align: left;
                            font-size: 14px;
                        }

                        input {
                            border-radius: 0;
                            background: #f2f2f2;
                            border: 1px solid #ddd;
                        }
                    }
                }

                ul {
                    .Mukul {
                        .dk-valueFields {
                            border: 1px solid #ddd;
                            border-bottom: none;
                        }
                    }
                }

                .Mukul {
                    .dk-valueFields {
                        border: 1px solid #ddd;
                        border-bottom: none;

                        &:last-child {
                            border-bottom: 1px solid #ddd;
                        }
                    }
                }
            }

            .modal-footer {
                border-top: 1px solid #ccc;
                padding: 15px;
                display: flex;
                justify-content: center;
                border-radius: 0 0 5px 5px;

                .dkg-closebtn {
                    background-color: #dc3545;
                    display: inline-block;
                    padding: 12px 14px;
                    border: none;
                    color: #fff;
                    border-radius: 3px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 13px;

                }

                .dkg-submitbtn {
                    background-color: #244158;
                    display: inline-block;
                    padding: 9px 12px;
                    min-width: 85px;
                    border: none;
                    color: #fff;
                    border-radius: 3px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 13px;

                    &:hover {
                        background-color: #26597d;
                        border-color: #26597d;
                        color: #fff;
                    }
                }

                &.dkg-category-reorder-footer {
                    justify-content: center !important;

                    div {
                        div {
                            .dk-toolsPanelSaveBtn {
                                display: inline;
                                background: #26597d !important;
                                border: none;
                                color: #fff;
                                text-transform: uppercase;
                                padding: 6px 10px;
                                font-weight: 500;
                                font-size: 14px;
                                outline: none;
                                cursor: pointer;
                                border-radius: 5px;
                            }
                        }
                    }
                }

            }
        }
    }
}
.dkg-teams-mainpageCon {
    margin-top: 160px;

    .dkg-teams-mainbox-flex {
        padding: 0 7px;
        width: 100%;

        .dkg-teams-cols {
            height: calc(100vh - 165px);
            width: 100%;

            .d-flex.w-100 {
                height: calc(100vh - 210px);

                .dkg-teams-box {
                    display: block;
                    background-color: #f2f2f2;
                    border: none;
                    border-radius: 5px;
                    font-size: 12px;
                    margin: 8px 15px;
                    width: 100%;

                    .title {
                        background-color: #eaeaea;
                        border: 1px solid #dadada;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        color: #6c757d;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 700;
                        padding: 7px 10px;
                        text-transform: uppercase;
                        width: 100%;
                        font-size: 15px;
                    }

                    ul {
                        border-bottom: 0;
                        margin: 0;
                        padding-inline-start: 0;

                        .dkg-content-con {
                            .dkg-content-title {
                                color: #6c757d;
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                                font-weight: 700;
                                padding: 7px 10px;
                                text-transform: capitalize;
                                width: 100%;
                                font-size: 13px;
                                background-color: #f2f2f2;
                                border-left: 1px solid #dadada;
                                border-right: 1px solid #dadada;

                            }

                            .dkg-teams-tableCon {
                                border: 1px solid #ddd;
                                background-color: #f2f2f2;

                                .dkg-teams-table {
                                    margin-bottom: 2rem;
                                    width: 100%;
                                    max-width: 95%;
                                    margin: 12px auto;

                                    tbody {
                                        tr {
                                            td {
                                                background-color: #fff;
                                                padding: 9px 7px;
                                                font-size: 13px;
                                                border: 1px solid #ddd;
                                                cursor: pointer;

                                                &:first-child {
                                                    // border-left: none;
                                                    width: 30%;
                                                    text-align: center;
                                                }

                                                &:last-child {
                                                    // border-right: none;
                                                    width: 70%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .dkg-subcontent-con {
                            .dkg-content-subtitle {
                                color: #6c757d;
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                                font-weight: 700;
                                padding: 12px 10px;
                                padding-bottom: 6px;
                                text-transform: capitalize;
                                width: 100%;
                                font-size: 14px;
                                background-color: #f2f2f2;
                                // border: 1px solid #dadada;
                                border-bottom: none;
                            }

                            .dkg-teams-recuiterCon {
                                border: 1px solid #ddd;
                                background-color: #f2f2f2;

                                .dkg-recuiter-table {
                                    margin-bottom: 0;
                                    width: 100%;
                                    max-width: 95%;
                                    margin: 12px auto;

                                    thead {
                                        tr {
                                            th {
                                                background-color: #fff;
                                                padding: 9px 7px;
                                                font-size: 13px;
                                                border: 1px solid #ddd;
                                                cursor: pointer;
                                                // font-weight: 700;

                                                &:first-child {
                                                    // border-left: none;
                                                    width: 30%;
                                                    text-align: center;
                                                }

                                                &:last-child {
                                                    // border-right: none;
                                                    width: 70%;
                                                }
                                            }
                                        }
                                    }

                                    tbody {
                                        tr {
                                            td {
                                                background-color: #fff;
                                                padding: 9px 7px;
                                                font-size: 13px;
                                                border: 1px solid #ddd;
                                                cursor: pointer;

                                                &:first-child {
                                                    // border-left: none;
                                                    width: 30%;
                                                    text-align: center;
                                                }

                                                &:last-child {
                                                    // border-right: none;
                                                    width: 70%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


// Start CSS For Update Modal
.dk-adsCreAddNewPop {
    max-width: 480px;
    width: 100% !important;

    .modal-content {
        background-color: initial;
        box-shadow: none;

        .modal-body {
            background-color: #f2f2f2;
            border-radius: 6px;
            padding: 20px 30px;
            position: relative;

            .dkg-crosssicon-con {
                background: #102c46;
                border-radius: 50%;
                color: #fff;
                cursor: pointer;
                display: inline-block;
                height: 30px;
                line-height: 28px;
                position: absolute;
                right: -9px;
                text-align: center;
                top: -11px;
                width: 30px;

                svg {
                    height: 14px;
                    width: 14px;
                }
            }

            .dk-adsCreditInCon {
                padding-bottom: 5px;
                display: flex;
                align-items: center;

                justify-content: space-between;

                .firstInput {
                    max-width: 150px;

                    label {
                        text-align: center;
                        width: 100%;
                    }

                    input {
                        border: 1px solid #ddd !important;
                        box-shadow: none !important;
                        background-color: #e2e7f6;
                        border-radius: 0;
                        outline: 0;
                        text-align: center;

                    }
                }

                .SecondInput {
                    max-width: 150px;
                    margin-left: 10px;

                    label {
                        text-align: center;
                        width: 100%;
                    }

                    input {
                        border: 1px solid #ddd !important;
                        box-shadow: none !important;
                        border-radius: 0;
                        text-align: center
                    }
                }

                .dk-adsCreditBtnCon {
                    margin-left: 8px;
                    margin-top: 28px;
                    width: auto;

                    div {
                        div {
                            button.dk-calcsaveBtn {
                                background: #2b8faf !important;
                                font-size: 14px;
                                padding: 6px 30px !important;
                                border-radius: 35px;
                                box-shadow: none;
                                color: #fff !important;
                                letter-spacing: 1px;
                                min-width: 90px;
                            }
                        }
                    }
                }
            }
        }
    }
}